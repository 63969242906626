import { Typography, Card } from "antd";
import { SolutionOutlined, UserOutlined } from "@ant-design/icons"; // Briefcase Icon

const { Text } = Typography;

const borderColors = ["#1890ff"]; // You can expand this if needed

export const JobCard = ({ designation, qualification, count, index }) => {
  const borderColor = borderColors[index % borderColors.length];
  const formatQualification = (qualification) => {
    if (typeof qualification === "string") {
      try {
        const parsedQualification = JSON.parse(qualification);
        if (Array.isArray(parsedQualification)) {
          return parsedQualification.join(", ");
        }
      } catch (error) {
        console.error("Error parsing qualification:", error);
      }
    }
    return qualification; // Return as-is if it's not a JSON string
  };  

  return (
    <Card
      style={{
        marginBottom: 8,
        borderLeft: `4px solid ${borderColor}`,
        borderRadius: 8,
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
        // padding: "12px",
        maxHeight: 83,
      }}
      hoverable
    >
      {/* Parent Flexbox to separate left & right sections */}
      <div
        style={{
          display: "flex",
          // alignItems: "center",  
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* Left Section: Designation & Qualification */}
        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
            <SolutionOutlined style={{ fontSize: 22, color: borderColor }} />
            <Text
              style={{
                fontSize: 14,
                color: borderColor,
                fontWeight: 700,
                lineHeight: "24px",
              }}
            >
              {designation}
            </Text>
          </div>
          <Text
            style={{
              fontSize: 12,
              color: "green",
              fontWeight: 600,
              lineHeight: "14px",
            }}
          >
            🎓 {formatQualification(qualification?.[0])}
          </Text>
        </div>

        {/* Right Section: User Count */}
        <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
          <UserOutlined style={{ fontSize: 18, color: "#555" }} />
          <Text
            style={{
              fontSize: 14,
              color: "#555",
              fontWeight: 600,
              lineHeight: "16px",
            }}
          >
            {count} {count > 1 ? 'Users' : 'User'}
          </Text>
        </div>
      </div>
    </Card>
  );
};
