import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Input, Space, Spin, Select, message, Form } from "antd";
import editIcon from "../../assets/icons/icons8-edit.svg";
import deleteIcon from "../../assets/icons/icons8-delete.svg";
import NewRolePopUp from "./newRolePopUp";
import { fetchAllRole, updateRole, newRoleCreate, deleteRole } from "./api";
import InfiniteScroll from "react-infinite-scroll-component";
import { SmileOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import "./role.css";
import PermissionsPopup from "./permissionPopup";
import { updatePermissions } from "../login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createPost,
  updatePost,
  deletePost,
  fetchData,
} from "../../backendApi";
import useDeviceType from "./DeviceSize";

const { Option } = Select;
function RoleManagement() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [isRoleCreate, setIsRole] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [isScrollTable, setIsScrollTable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observerTarget = useRef(null);

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  const [option, setOption] = useState({
    filter: "",
    search: "",
    page: 1,
    size: 10,
  });

  const [isPermissionPopup, setIsPermissionPopup] = useState(false);
  const [newRoleData, setNewRoleData] = useState({});
  const [permissionData, setPermissionData] = useState({});

  const columns = [
    {
      title: "ROLE NAME",
      dataIndex: "roleName",
      key: "roleName",
      className: "roleName",
      width: "30%",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      className: "status",
      width: "20%",
    },
    {
      title: "APPLICABLE TO",
      dataIndex: "allMenu",
      key: "allMenu",
      className: "allMenu",
      width: "25%",
      render: (menus) => menus?.join(", "),
    },
  ];

  {
    permission?.["Role Management"].edit &&
      columns.push({
        title: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            ACTIONS
          </div>
        ),
        key: "actions",
        align: "right",
        width: "25%",
        render: (_, record) => {
          return (
            <Space
              style={{
                dispaly: "grid",
                marginLeft: "50%",
                gap: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <UserOutlined
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                  pointerEvents: "auto",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePermission(record.roleId);
                }}
              />

              <img
                src={editIcon}
                alt="Edit"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(record.roleId);
                }}
              />
            </Space>
          );
        },
      });
  }

  const handleCreateNewRole = () => {
    form.resetFields();
    setRoleId("");
    setIsRole(true);
  };

  const closePopup = () => {
    setIsRole(false);
  };

  const createNewRole = async (data) => {
    console.log(permissionData, data);
    setNewRoleData(data);
    setIsPermissionPopup(true);
  };

  const newRoleAndPermissionCreated = async (permissionData) => {
    try {
      if (roleId) {
        let res;
        if (newRoleData && Object.keys(newRoleData).length > 0) {
          res = await updatePost(`/role/${roleId}`, {
            newRoleData,
            permissionData,
          });
        } else {
          res = await updatePost(`/permission/rolePermissions/${roleId}`, {
            permissionData,
          });
        }
        if (res?.data?.status === 200) {
          const newData = roleList.map((ele) => {
            if (ele.roleId === res?.data?.data.rolePermission.roleId) {
              return {
                ...ele,
                roleName: res?.data?.data.role.roleName,
                status: res?.data?.data.role.status,
                allMenu: res?.data?.data.role.allMenu,
              };
            }
            return ele;
          });
          dispatch(
            updatePermissions({
              permission: res.data.data.rolePermission.permission,
              roleId,
            })
          );
          setRoleList(newData);
          setRoleList(newData);
          form.resetFields();
          message.success("Permission updated successfully");
        } else {
          message.error(res?.data.message);
        }
      } else {
        let res = await createPost("/role", { newRoleData, permissionData });
        if (res?.data?.status === 200) {
          const newData = [...roleList];
          newData.unshift(res?.data?.data.changedData);
          setRoleList(newData);
          form.resetFields();
        } else {
          message.error(res?.data.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRole = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(option).toString();
      const res = await fetchAllRole(`/role?${queryParams}`);
      if (res?.data?.status === 200) {
        setRoleList(res.data.data);
        setHasMore(res.data.newPage);
      } else {
        setRoleList([]);
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRoleList([]); // Reset role list when filter or search changes
    setOption((prev) => ({ ...prev, page: 1 })); // Reset page to 1
    fetchRole();
  }, [option.filter, option.search]);

  function handleEdit(id) {
    setRoleId(id);
    setIsRole(true);
  }

  useEffect(() => {
    if (option.page > 1) fetchMoreData();
  }, [option.page]);

  const fetchMoreData = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        filter: option.filter,
        search: option.search,
        page: option.page,
        size: option.size,
      }).toString();
      const res = await fetchAllRole(`/role?${queryParams}`);

      if (res?.data?.status === 200) {
        setRoleList((prevList) => [...prevList, ...res.data.data]);
        setHasMore(res.data.newPage);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasMore && !loading) {
          setOption((prev) => {
            return {
              ...prev,
              page: prev.page + 1,
            };
          });
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [loading, hasMore]);

  function handlePermission(id) {
    setRoleId(id);
    setIsPermissionPopup(true);
  }

  function permissionPopupClose() {
    setIsPermissionPopup(false);
  }

  async function createNewPermission(permissionData) {
    setPermissionData(permissionData);
    newRoleAndPermissionCreated(permissionData);
    // if (roleId) {
    //   const res = await createNewRolePermission(
    //     `/createPermission/${roleId}`,
    //     data
    //   );
    // }
  }
  console.log("useDeviceType()", useDeviceType());

  const deviceType = useDeviceType();
  return (
    <div
      className="post-list-container h-screen flex flex-col"
      style={{ marginTop: "24px" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
          borderBottom: "1px solid",
        }}
      >
        <h2>Role Management</h2>
      </div>
      <div className="testttt" style={{ margin: "20px 0 0 10px" }}>
        <Space size="middle" className="flex-grow mb-4">
          <Select
            style={{ width: 150 }}
            value={option?.filter}
            onChange={(value) => {
              // setOption((prev) => ({ ...prev, filter: value, page: 1 }));
              if (value === "") {
                setOption((prev) => ({
                  ...prev,
                  filter: value,
                }));
              } else {
                if (value === "All") {
                  setOption((prev) => ({
                    ...prev,
                    filter: "",
                    page: 1,
                  }));
                } else {
                  setOption((prev) => ({
                    ...prev,
                    filter: value,
                    page: 1,
                  }));
                }
              }
              setHasMore(true);
              setLoading(true);
            }}
          >
            <Option value="All">All</Option>
            <Option value="Active">Active</Option>
            <Option value="In Active">In Active</Option>
          </Select>
          <Input
            placeholder="Role Search..."
            value={option?.search}
            onChange={(e) => {
              if (e.target.value === "") {
                setOption((prev) => ({
                  ...prev,
                  search: "",
                  page: 1,
                }));
              } else {
                setOption((prev) => ({
                  ...prev,
                  search: e.target.value,
                  page: 1,
                }));
              }
              setHasMore(true);
              setLoading(true);
            }}
            style={{ width: 200 }}
          />
        </Space>
        {permission?.["Role Management"]?.write && (
          <Button
            style={{ float: "right", height: "47px", right: "50px" }}
            type="primary"
            onClick={handleCreateNewRole}
            className="mb-4"
          >
            New Role
          </Button>
        )}
      </div>

      <div
        id="respTableScroll"
        style={{
          height:
            deviceType === "Laptop"
              ? "480px"
              : deviceType === "maxLaptop"
              ? "630px"
              : deviceType === "maxDesktop"
              ? "780px"
              : "660px",
          width: "100%",
          overflowY: "auto",
          display: "block",
          maxHeight: "750px",
        }}
      >
        <div>
          <Table
            className="jobTableList"
            columns={columns}
            dataSource={roleList}
            rowKey="id"
            pagination={false}
            locale={{
              emptyText: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  No Data
                </div>
              ),
            }}
          />
        </div>
        {loading && <p>Loading more data...</p>}
        <div ref={observerTarget} style={{ height: "20px" }}></div>
        {!hasMore && null}
        {isRoleCreate && (
          <NewRolePopUp
            roleId={roleId}
            isVisible={isRoleCreate}
            closePopup={closePopup}
            onSave={createNewRole}
            form={form}
          />
        )}
        {isPermissionPopup && (
          <PermissionsPopup
            roleId={roleId}
            isVisible={isPermissionPopup}
            closePopup={permissionPopupClose}
            onSave={createNewPermission}
            form={form}
          />
        )}
      </div>
    </div>
  );
}

export default RoleManagement;
