import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { fetchData } from "../../../backendApi";
import "./employeeHistory.css";
function EmployeeHistory({ isTalentHistory, setIsTalentHistory, talntId }) {
  const columns = [
    {
      title: "Employer",
      dataIndex: "primaryEmployeerName",
      key: "primaryEmployeerName",
    },
    {
      title: "Contractor",
      dataIndex: "primaryContractorName",
      key: "primaryContractorName",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Date of Join",
      dataIndex: "dateOfJoin",
      key: "dateOfJoin",
      width: 120,
    },
    {
      title: "Date of Exit",
      dataIndex: "dateOfExit",
      key: "dateOfExit",
      width: 120,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
  ];

  const [dataSource, setDataSource] = useState({
    data: [],
    totalRecords: 0,
    pageSize: 5,
    pageNumber: 1,
  });

  async function fetchTalentHistory(page = 1) {
    try {
      const res = await fetchData(
        `/talnt/talntHistory/${talntId}?pageSize=${dataSource.pageSize}&pageNumber=${page}`
      );

      setDataSource((prev) => ({
        ...prev,
        data: res.data.data.changedTalentHistory,
        totalRecords: res.data.data.totalAssign,
        pageNumber: page,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchTalentHistory(dataSource.pageNumber);
  }, [dataSource.pageNumber]);

  const handleTableChange = (pagination) => {
    fetchTalentHistory(pagination);
  };

  return (
    <Modal
      title="Talent History"
      open={isTalentHistory}
      onCancel={() => setIsTalentHistory(false)}
      width={800}
      bodyStyle={{ padding: "16px" }}
      style={{ top: 100 }}
      footer={null}
    >
      <Table
        dataSource={dataSource.data}
        columns={columns}
        pagination={{
          current: dataSource.pageNumber,
          total: dataSource.totalRecords,
          pageSize: dataSource.pageSize,
          onChange: handleTableChange,
        }}
        bordered
      />
    </Modal>
  );
}

export default EmployeeHistory;
