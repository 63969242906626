import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Input, Space, Spin, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";

import { fetchAppliedJobs } from "../../services/jobPostService";
import { fetchDataEachRow } from "../../backendApi";
import axiosClient from "../../axiosConfig";

const { Option } = Select;

const AppliedJobsList = (jobPostId) => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [appliedJobsList, setAppliedJobsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
// console.log(appliedJobsList[0]?.talntId, "appliedJobsList12345")
// console.log(appliedJobs, "appliedJobs1111")
  const observer = useRef();
 // console.log(observer, "observer")
  const lastJobElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
//console.log(jobPostId.jobPostId, "jobPostId")
  const loadAppliedJobs = useCallback(
    async (pageNum) => {
      if (!hasMore) return;
      setLoading(true);
      try {
        const response = await fetchAppliedJobs(
          pageNum,
          10,
          jobPostId.jobPostId
        );
        const { totalCount, data } = response;
        if (totalCount <= 10) {
          setHasMore(false); // Prevent further API calls if total count is <= 10
        } else {
          setHasMore(data.length > 0); // Allow further calls if data is available
        }
       // console.log(data, "data");
        setAppliedJobsList((prevJobs) =>
          pageNum === 1 ? data : [...prevJobs, ...data]
        );
        loadTalentUserDetails(data);
        setHasMore(data.length > 0);
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading applied jobs:", error);
        message.error("Failed to load applied jobs");
      } finally {
        setLoading(false);
      }
    },
    [searchField, searchTerm]
  );

  // const loadTalentUserDetails = async (appliedJobsData) => {
  //   const updatedJobs = await Promise.all(
  //     appliedJobsData.map(async (job) => {
  //       if (!job.talntId) return job; // Skip if no talent ID
  
  //       try {
  //         const response = await axiosClient.get(`http://localhost:7000/api/web/talnt/${job.talntId}`);
  // console.log(response, "response123")
  //       //  if (!response.ok) throw new Error("Failed to fetch talent details");
  //         const talentData = response;
  // console.log(talentData?.data?.talntUser?.personal?.full_name, "talentData")
  //         return {
  //           ...job,
  //           talentusername: talentData?.data?.talntUser?.personal?.full_name || "---",
  //           talentusernationality: talentData?.data?.talntUser?.personal?.nationality || "---",
  //           talentusermobile: talentData?.data?.talntUser?.contact?.mobile_number || "---",
  //         };
  //       } catch (error) {
  //         console.error("Error fetching talent user details:", error);
  //         return job; // Return original job object in case of failure
  //       }
  //     })
  //   );
  
  //   setAppliedJobs(updatedJobs);
  // };
  

  const loadTalentUserDetails = async (appliedJobsData) => {
    try {
      const updatedJobs = await Promise.all(
        appliedJobsData.map(async (job) => {
          if (!job.talntId) return job; // Skip if no talent ID
  
          try {
            const response = await axiosClient.get(
              `https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt/${job.talntId}`
            );
            console.log(response.data.status, "response123");
            if(response?.data?.status === 200){
            const talentData = response?.data?.talntUser?.personal || {};
            const contactData = response?.data?.talntUser?.contact || {};
            const employmentData = response?.data?.talntUser?.employment || {};
  
            return {
              ...job,
              talentusername: talentData.full_name || "---",
              talentusernationality: talentData.nationality || "---",
              talentusermobile: contactData.mobile_number || "---",
              talentuseremail: contactData.email || "---",
              talentuserexperience: employmentData.experience || "---",
              talentuserindustry: employmentData.industry || "---",
            };
          }
          return null;
          } catch (error) {
            console.error("Error fetching talent user details:", error);
            return job;
          }
        })
      );
      const validJobs = updatedJobs.filter((job) => job !== null);

    setAppliedJobs((prevJobs) => [...prevJobs, ...validJobs]); 
  
      // setAppliedJobs((prevJobs) => [...prevJobs, ...updatedJobs]); // Preserve old jobs
    } catch (error) {
      console.error("Error updating applied jobs:", error);
    }
  };
  

  // const debouncedSearch = useCallback(
  //   debounce(() => {
  //    // setAppliedJobsList([]);
  //     setPage(1);
  //     setHasMore(true);
  //     loadAppliedJobs(1);
  //   }, 300),
  //   [loadAppliedJobs]
  // );

  const debouncedSearch = useCallback(
    debounce(() => {
      if (page === 1) {
        setAppliedJobsList([]); // Only reset if starting a new search
        setAppliedJobs([]); 
      }
      setPage(1);
      setHasMore(true);
      loadAppliedJobs(1);
    }, 300),
    [loadAppliedJobs]
  );
  
  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchField, searchTerm, debouncedSearch]);
//   useEffect(() => {
//   if (searchTerm || searchField !== "All") {
//     debouncedSearch();
//   }
//   return () => {
//     debouncedSearch.cancel();
//   };
// }, [searchField, searchTerm, debouncedSearch]);

  useEffect(() => {
    if (page > 1 && hasMore) {
      loadAppliedJobs(page);
    }
  }, [loadAppliedJobs, page, hasMore]);

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  const handleRowClick = (record) => {
    history.push(`applied-jobs/${record.id}`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "talentusername",
      key: "talentusername",
      render: (text) => (text ? text : "---"), // Check if data is present
    },
    {
      title: "Nationality",
      dataIndex: "talentusernationality",
      key: "talentusernationality",
      render: (text) => (text ? text : "---"), // Display "---" if no data
    },
    {
      title: "Mobile",
      dataIndex: "talentusermobile",
      key: "talentusermobile",
      ellipsis: true,
      render: (text) => (text ? text : "---"), // Handle missing mobile number
    },
    {
      title: "Email",
      dataIndex: "talentuseremail",
      key: "talentuseremail",
      ellipsis: true,
      render: (text) => (text ? text : "---"), // Handle missing mobile number
    },
    {
      title: "Experience",
      dataIndex: "talentuserexperience",
      key: "talentuserexperience",
      ellipsis: true,
      render: (text) => (text ? text : "---"), // Handle missing mobile number
    },
    {
      title: "Industry",
      dataIndex: "talentuserindustry",
      key: "talentuserindustry",
      ellipsis: true,
      render: (text) => (text ? text : "---"), // Handle missing mobile number
    },
  ];
  

  return (
    <div className="applied-jobs-list-container">
      {appliedJobs && appliedJobs.length > 0 ? (
        <>
          <h1 style={{ textAlign: "center" }}>Applied Jobs</h1>
          <Table
            className="applied-jobs-table"
            columns={columns}
            dataSource={appliedJobs}
            rowKey="id"
            loading={loading}
            // onRow={(record, index) => ({
            //   onClick: () => handleRowClick(record),
            //   ref: index === appliedJobs.length - 1 ? lastJobElementRef : null,
            // })}
            pagination={false}
          />
          {loading && hasMore && (
            <div className="loading-more">
              <Spin />
            </div>
          )}
        </>
      ) : ( ''
      )}
    </div>
  );
  
  
};

export default AppliedJobsList;
