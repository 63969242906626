import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Header from "./Header";
import Stats from "./Stats";
import WorkHours from "./WorkHours";
import WhatsOn from "./JobPosts";
import EmployeeDetails from "./EmployeeDetails";
import TodaysSchedule from "./TodaysSchedule";
import {
  getNewDashboardData,
  getNewDashboardDataForPE,
} from "../rolemanagement/api";
import { useContext } from "react";
import { dataContext } from "../../context/data";
import TalentAnalyticsDashboard from "./Analytics";
import { useSelector } from "react-redux";
import ComplianceFolder from "./ComplianceFolder";
import EscalationMessage from "./EscalationMessage";
import AssociativeDetails from "./AssociativeDetails";

const DashboardUI = ({ userPath }) => {
  const [fetchData, setFetchData] = useState({});
  const { setNavList } = useContext(dataContext);
  const userDetail = useSelector(({ user: { userInfo } }) => {
    if (userInfo && Object.keys(userInfo).length > 0) {
      return userInfo;
    }
  });

  useEffect(() => {
    setNavList((pre) => {
      return {
        ...pre,
        dashBoard: true,
        userList: false,
        jobPost: false,
        requests: false,
        whatsapp: false,
      };
    });
  }, []);

  useEffect(() => {
    if (!userDetail?.userType) return; // Improved null check using optional chaining

    const fetchData = async () => {
      try {
        const response = await (userDetail.userType.trim() === "Employeer"
          ? getNewDashboardDataForPE(userDetail.id) // Removed unnecessary optional chaining here
          : getNewDashboardData("newDashboardForBizops"));

        console.log(response, "data");
        setFetchData(response);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };
    fetchData();
  }, [userDetail]);

  return (
    <div
      style={{
        padding: 24,
        background: "#f0f2f5",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Header />
      <Stats data={fetchData} user={userDetail?.userType} />
      <TalentAnalyticsDashboard data={fetchData} user={userDetail?.userType} />
      {/* <WorkHours data={fetchData} /> */}
      {userDetail?.userType === "Bizops" && <TodaysSchedule />}
      {userDetail?.userType === "Employeer" && (
        <AssociativeDetails data={fetchData} />
      )}
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        {userDetail?.userType === "Bizops" && (
          <Col xs={24} lg={12}>
            <WhatsOn data={fetchData} />
          </Col>
        )}
        {userDetail?.userType === "Bizops" && (
          <Col xs={24} lg={12}>
            <EmployeeDetails data={fetchData} />
          </Col>
        )}
        {userDetail?.userType === "Employeer" && (
          <Col xs={24} lg={12}>
            <ComplianceFolder data={fetchData} />
          </Col>
        )}
        {userDetail?.userType === "Employeer" && (
          <Col xs={24} lg={12}>
            <EscalationMessage data={fetchData} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DashboardUI;
