import React from "react";
// import { Card, Typography, Space, Row, Col, theme } from "antd";
import { Card, Row, Col, Typography, Statistic, Divider, Space, theme } from 'antd';
import { UserAddOutlined, TeamOutlined, PercentageOutlined } from '@ant-design/icons';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
  Legend,
  BarChart
} from "recharts";

const { Title, Text } = Typography;

const WorkHours = ({ data }) => {
  const { token } = theme.useToken();

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (!active || !payload || !payload.length) return null;

  //   return (
  //     <Card size="small" style={{ boxShadow: token.boxShadow }}>
  //       <Space direction="vertical" size="small">
  //         <Text strong>{label}</Text>
  //         {payload.map((entry, index) => (
  //           <Space key={index} align="center">
  //             <span
  //               style={{
  //                 display: "inline-block",
  //                 width: 8,
  //                 height: 8,
  //                 borderRadius: "50%",
  //                 backgroundColor: entry.color,
  //                 marginRight: token.padding,
  //               }}
  //             />
  //             <Text type="secondary">{entry.name}:</Text>
  //             <Text strong>{entry.value}</Text>
  //           </Space>
  //         ))}
  //       </Space>
  //     </Card>
  //   );
  // };

  // Custom label renderer for bars
  const renderCustomBarLabel = (props) => {
    const { x, y, width, height, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  // Custom label renderer for line
  const renderCustomLineLabel = (props) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y - 10}
        fill={token.colorInfo}
        textAnchor="middle"
        fontSize="12"
      >
        {`${value}%`}
      </text>
    );
  };

  const dataNew = [
    { date: 'Jan', "Talents Added": 45, "Associates Assigned With PE": 32 },
    { date: 'Feb', "Talents Added": 52, "Associates Assigned With PE": 38 },
    { date: 'Mar', "Talents Added": 48, "Associates Assigned With PE": 41 },
    { date: 'Apr', "Talents Added": 61, "Associates Assigned With PE": 47 },
    { date: 'May', "Talents Added": 55, "Associates Assigned With PE": 51 },
    { date: 'Jun', "Talents Added": 67, "Associates Assigned With PE": 55 }
  ];

  // Calculate summary metrics
  const totalTalents = dataNew?.reduce((sum, item) => sum + item["Talents Added"], 0);
  const totalAssociates = dataNew?.reduce((sum, item) => sum + item["Associates Assigned With PE"], 0);
  const assignmentRate = Math.round((totalAssociates / totalTalents) * 100);

  // Custom tooltip component using Ant Design styling
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card size="small" style={{ border: '1px solid #f0f0f0' }}>
          <Text strong>{label}</Text>
          <Divider style={{ margin: '8px 0' }} />
          {payload.map((entry, index) => (
            <div key={index}>
              <Text style={{ color: entry.color, marginRight: 8 }}>
                {entry.name}: {entry.value}
              </Text>
            </div>
          ))}
        </Card>
      );
    }
    return null;
  };

  return (
    // <Card style={{ borderRadius: 16 }}>
    //   <Space direction="vertical" size="large" style={{ width: "100%" }}>
    //     {/* Header section remains the same */}
    //     <Row gutter={[24, 24]} align="middle" justify="space-between">
    //       <Col>
    //         <Title level={4} style={{ marginBottom: 0 }}>
    //           Talent Analytics Overview
    //         </Title>
    //       </Col>
    //     </Row>
    //     <Row gutter={[24, 24]}>
    //       <div style={{ height: 300, width: "100%" }}>
    //         <ResponsiveContainer>
    //           <ComposedChart
    //             data={data?.data?.dateBasedTalentsCount}
    //             margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
    //           >
    //             <CartesianGrid
    //               strokeDasharray="3 3"
    //               stroke={token.colorBorderSecondary}
    //             />
    //             <XAxis dataKey="date" />
    //             <YAxis yAxisId="left" />
    //             <YAxis yAxisId="right" orientation="right" />
    //             <Tooltip content={<CustomTooltip />} />
    //             <Bar
    //               yAxisId="left"
    //               dataKey="Talents Added"
    //               fill={token.colorPrimary}
    //               radius={[4, 4, 0, 0]}
    //               barSize={20}
    //             >
    //               <LabelList content={renderCustomBarLabel} />
    //             </Bar>
    //             <Bar
    //               yAxisId="left"
    //               dataKey="Associates Assigned With PE"
    //               fill={token.colorSuccess}
    //               radius={[4, 4, 0, 0]}
    //               barSize={20}
    //             >
    //               <LabelList content={renderCustomBarLabel} />
    //             </Bar>
    //           </ComposedChart>
    //         </ResponsiveContainer>
    //       </div>
    //     </Row>
    //   </Space>
    // </Card>
    <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0,0,0,0.09)' }}>
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            Talent Acquisition Analytics
          </Title>
          <Text type="secondary">Performance overview of talent acquisition efforts</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Card style={{ backgroundColor: '#f0f5ff', borderRadius: 8 }}>
            <Statistic 
              title="Total Talents Added" 
              value={totalTalents} 
              prefix={<UserAddOutlined />} 
              valueStyle={{ color: '#1890ff' }}
            />
            <Text type="secondary">YTD</Text>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={{ backgroundColor: '#f6ffed', borderRadius: 8 }}>
            <Statistic 
              title="Total Associates Assigned" 
              value={totalAssociates} 
              prefix={<TeamOutlined />} 
              valueStyle={{ color: '#52c41a' }}
            />
            <Text type="secondary">YTD</Text>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={{ backgroundColor: '#f9f0ff', borderRadius: 8 }}>
            <Statistic 
              title="Assignment Rate" 
              value={assignmentRate} 
              suffix="%" 
              prefix={<PercentageOutlined />} 
              valueStyle={{ color: '#722ed1' }}
            />
            <Text type="secondary">YTD</Text>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card bodyStyle={{ padding: '12px 0' }}>
            <div style={{ height: 320, width: "100%" }}>
              <ResponsiveContainer>
                <BarChart
                  data={dataNew}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  barGap={8}
                  barCategoryGap={20}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" vertical={false} />
                  <XAxis 
                    dataKey="date" 
                    axisLine={{ stroke: '#f0f0f0' }}
                    tickLine={false}
                  />
                  <YAxis 
                    axisLine={{ stroke: '#f0f0f0' }}
                    tickLine={false}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend 
                    wrapperStyle={{ paddingTop: 10 }}
                    iconType="circle"
                  />
                  <Bar 
                    name="Talents Added"
                    dataKey="Talents Added" 
                    fill="#1890ff"
                    radius={[4, 4, 0, 0]}
                    barSize={24}
                  />
                  <Bar 
                    name="Associates Assigned" 
                    dataKey="Associates Assigned With PE"
                    fill="#52c41a"
                    radius={[4, 4, 0, 0]}
                    barSize={24}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
{/* 
      <Row justify="end">
        <Col>
          <Text type="secondary" style={{ fontSize: 12 }}>
            Last updated: March 12, 2025
          </Text>
        </Col>
      </Row> */}
    </Space>
  </Card>
  );
};

export default WorkHours;
