import React from "react";
import { Table, Avatar, Tag, Card, Typography } from "antd";

const { Title, Text } = Typography;

const columns = [
  {
    title: "Profile",
    dataIndex: "personal",
    render: (personal) => <Avatar src={personal[0]?.profile_image_url || ""} />,
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "DOB",
    dataIndex: "personal",
    render: (personal) => new Date(personal[0]?.dob).toLocaleDateString(),
  },
  {
    title: "Gender",
    dataIndex: "personal",
    render: (personal) => (
      <Tag
        color={
          personal[0]?.gender === "male"
            ? "blue"
            : personal[0]?.gender === "female"
            ? "pink"
            : "purple"
        }
      >
        {personal[0]?.gender.toUpperCase()}
      </Tag>
    ),
  },
  {
    title: "Mobile",
    dataIndex: "mobileNumber",
  },
  {
    title: "Talent Code",
    dataIndex: "talntCode",
  },
  {
    title: "Department",
    dataIndex: "department",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Designation",
    dataIndex: "designation",
  },
  {
    title: "Date of Join",
    dataIndex: "dateOfJoin",
    render: (date) => new Date(date).toLocaleDateString(),
  },
];

const AssociativeDetails = ({ data }) => {
  return (
    <Card
      style={{
        textAlign: "center",
        borderRadius: "16px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
        marginBottom: 24
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
          Associates Details Added Recently
        </Title>
      </div>
      <Table
        dataSource={data?.data?.associates_details_added_recently}
        columns={columns}
        rowKey="talntCode"
        pagination={{ pageSize: 5 }}
        bordered
      />
    </Card>
  );
};

export default AssociativeDetails;
