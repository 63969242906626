import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Spin, message, Modal } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import { getJobPostById } from "../../services/jobPostService";
import JobPostForm from "./JobPostForm";
import "./JobPostDetails.css";
import AppliedJobsList from "./AppliedJobList";
import { useSelector } from "react-redux";
const JobPostDetails = () => {
  const [jobPost, setJobPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  console.log(jobPost, "jobPost")
  useEffect(() => {
    loadJobPost();
  }, [id]);

  const loadJobPost = async () => {
    try {
      setLoading(true);
      const data = await getJobPostById(id);
      setJobPost(data);
    } catch (error) {
      console.error("Error fetching job post:", error);
      message.error("Failed to load job post details");
    } finally {
      setLoading(false);
    }
  };

  const handleExpand = (section) => {
    setExpandedSection(section);
  };

  const handleCloseExpand = () => {
    setExpandedSection(null);
  };
  let permission = useSelector((state) => {
    return state.user.permission.getUserRole;
  });
  console.log(permission, "permission")
  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    );
  }

  if (!jobPost) {
    return (
      <div className="not-found-container">
        <h2>Job post not found</h2>
        <Button type="primary" onClick={() => history.push("/job-posts")}>
          Back to Job Posts
        </Button>
      </div>
    );
  }



  return (
    <div className="job-post-details">
      <header className="details-header">
        <div className="header-left">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => history.replace("/Talntworx/jobpost")}
          >
            Post details
          </Button>
        </div>
        {permission && permission?.permission["Job Post"]?.edit && (
          <div className="header-right">
            <Button
              type="primary"
              onClick={() => setModalVisible(true)}
              icon={<EditOutlined />}
            >
              Edit Post
            </Button>
          </div>
        )}
      </header>

      <div className="details-grid">
        <div className="grid-row">
          <div className="grid-item">
            <div className="label">Job Id</div>
            <div className="value">
              {typeof jobPost.jobCode === "string" ? jobPost.jobCode : "N/A"}
            </div>
          </div>
          <div className="grid-item">
            <div className="label">Principal Employer</div>
            <div className="value">
              {jobPost?.primaryEmployeer?.name || "N/A"}
            </div>
          </div>
          <div className="grid-item">
            <div className="label">Salary</div>
            <div className="value">
              ₹
              {typeof jobPost.salaryFrom === "number"
                ? jobPost.salaryFrom
                : "N/A"}{" "}
              -{typeof jobPost.salaryTo === "number" ? jobPost.salaryTo : "N/A"}
              /
              {typeof jobPost.salaryPerPeriod === "string"
                ? jobPost.salaryPerPeriod
                : "N/A"}
            </div>
          </div>
          <div className="grid-item">
            <div className="label">Experience Required</div>
            <div className="value">
              {jobPost.experienceFrom || "N/A"} -{" "}
              {jobPost.experienceTo || "N/A"}/
              {jobPost.experiencePerPeriod || "N/A"}
            </div>
          </div>
          <div className="grid-item">
            <div className="label">Designation</div>
            <div className="value">{jobPost.designation || "N/A"}</div>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-item">
            <div className="label">Posted by</div>
            <div className="value">Talntworx Technologies</div>
          </div>
          <div className="grid-item">
            <div className="label">Location</div>
            <div className="value">
              {jobPost.district || "N/A"}, {jobPost.state || "N/A"}
            </div>
          </div>
          <div className="grid-item">
            <div className="label">Start date</div>
            <div className="value">{jobPost.startDate || "N/A"}</div>
          </div>
          <div className="grid-item">
            <div className="label">No. of questions</div>
            <div className="value">
              {jobPost.questions
                ? jobPost.questions.filter(
                  (q) =>
                    q.question.trim() !== "" || q.answerType.trim() !== ""
                ).length || "0"
                : "0"}
            </div>
          </div>
        </div>
      </div>

      <div className="expandable-sections">
        <div className="section">
          <div className="section-header">
            <h3>Description</h3>
            <Button
              type="text"
              icon={<ExpandOutlined />}
              onClick={() => handleExpand("description")}
            />
          </div>
          <div className="section-content">{jobPost.description || "N/A"}</div>
        </div>

        <div className="section">
          <div className="section-header">
            <h3>Formalities</h3>
            <Button
              type="text"
              icon={<ExpandOutlined />}
              onClick={() => handleExpand("formalities")}
            />
          </div>
          <div className="section-content">
            {Array.isArray(jobPost.joiningFormalities) &&
              jobPost.joiningFormalities.length > 0
              ? jobPost.joiningFormalities
                .filter((formality) => formality?.value?.trim()) // Filter out empty or invalid values
                .map((formality, index) => (
                  <div key={index}>
                    {index + 1}. {formality.value}
                  </div>
                ))
              : "N/A"}
          </div>
        </div>
      </div>
      <AppliedJobsList jobPostId={id} />
      <Modal
        title={
          expandedSection === "description" ? "Description" : "Formalities"
        }
        visible={!!expandedSection}
        onCancel={handleCloseExpand}
        footer={null}
        width={600}
      >
        {expandedSection === "description" ? (
          <div
            className="expanded-content"
            style={{
              marginTop: "10px",
              color: "rgb(53, 0, 107)",
              fontWeight: "500",
            }}
          >
            {jobPost.description}
          </div>
        ) : (


          <div className="expanded-content">
            {Array.isArray(jobPost.joiningFormalities) &&
              jobPost.joiningFormalities.length > 0 ? (
              jobPost.joiningFormalities.map((formality, index) => (
                <div key={index}>
                  {index + 1}. {formality.value}
                </div>
              ))
            ) : (
              <div>N/A</div>
            )}
          </div>
        )}
      </Modal>

      <JobPostForm
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        onSuccess={() => {
          loadJobPost();
          setModalVisible(false);
          //message.success("Job post updated successfully");
        }}
        data={jobPost}
        id={id}
        type="edit"
        initialValues={jobPost}
      />
    </div>
  );
};

export default JobPostDetails;
