import React from "react";
import { Modal, Form, Button } from "antd";

function DeletePopup({
  isDeleted,
  row,
  closeDeleteTalentHandler,
  deleteEmployeer,
}) {
  return (
    <Modal
      title={
        <div style={{ display: "flex", justifyContent: "center" }}>
          Do You Want To Remove Talent
        </div>
      }
      open={isDeleted}
      centered
      onCancel={closeDeleteTalentHandler}
      footer={[
        <Button key="cancel" onClick={closeDeleteTalentHandler}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => deleteEmployeer(row)}
        >
          Remove
        </Button>,
      ]}
    ></Modal>
  );
}

export default DeletePopup;
