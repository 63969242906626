import React, { useContext, useEffect, useState } from "react";
import { Modal, Table, Button, message, Spin } from "antd";
import "../../../styles/responsiveTable.scss";
import moreIcon from "../../../assets/icons/more.svg";
import dragIcon from "../../../assets/icons/drag.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import searchIcon from "../../../assets/icons/search.svg";
import clearIcon from "../../../assets/icons/clear.svg";
import YTV from "../../../assets/icons/status/YTV.svg";
import CP from "../../../assets/icons/status/CP.svg";
import IP from "../../../assets/icons/status/IP.svg";
import E from "../../../assets/icons/status/E.svg";
import UE from "../../../assets/icons/status/UE.svg";
import editIcon from "../../../assets/icons/icons8-edit.svg";
import deleteIcon from "../../../assets/icons/icons8-delete.svg";
import { useHistory } from "react-router";
import { dataContext } from "../../../context/data";
import { useAuthContext } from "../../../context/auth";
import InfiniteScroll from "react-infinite-scroll-component";
import { doc, getFirestore, deleteDoc } from "@firebase/firestore";
import app, { firebaseAuth } from "../../../firebase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import useSortableData from "../../useSortableData";

import { getAuth, deleteUser, updateProfile } from "firebase/auth";
import axios from "axios";
// import axios from "../../../axiosConfig/index";

import { collection, query, where, getDocs } from "firebase/firestore";

import baseUrl from "../../../utils/baseUrl";
import { useSelector, useDispatch } from "react-redux";
import { deletePost, fetchData } from "../../../backendApi";
import { updateSelectedTalent } from "../../login/loginSlice";

const db = getFirestore(app);

function ResponsiveTable({
  talents,
  data,
  disableColumnOrder,
  column,
  setColumn,
  setSelectedColumn,
  selectedColumn,
  dashboard,
  totoalTalents,
  userType,
}) {
  const auth = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const userContext = useContext(dataContext);
  const [localData, setLocalData] = useState(data);
  const [filterValue, setFilterValue] = useState([]);
  const { items, requestSort } = useSortableData(localData);

  const [optionVisibile, setOptionVisibile] = useState(false);
  const showOption = () => setOptionVisibile((pre) => !pre);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const [isCheckbox, setIsCheckbox] = useState([]);

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });
  let { talent, isSubmited } = useSelector((state) => {
    return state?.user?.selectedTalent;
  });

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    console.log(talent);

    if (isSubmited) {
      const updatedTalent = talent.map((ele) => {
        return {
          ...ele,
          isChecked: false,
        };
      });
      let updatedItem = items.map((ele) => {
        if (talent.some((t) => t.id === ele.key)) {
          return { ...ele, status: "Employed" };
        } else {
          return { ...ele };
        }
      });
      setLocalData(updatedItem);
      setIsCheckbox([updatedTalent]);
    }
  }, [talent]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(
    () => userContext.setUserData((pre) => ({ ...pre, selectedRows })),
    [selectedRows]
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    setFilterValue([]);
  }, [
    userContext.userData.activeContractTabIndex,
    userContext.userData.activePrimaryTabIndex,
    userContext.userData.activeTabIndex,
  ]);
  useEffect(() => {
    setSelectedRows([]);
  }, [
    filterValue,
    userContext.userData.filterValue,
    userContext.userData.filterQuery,
  ]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleRowSelection = (row) => {
    const found = selectedRows.some((el) => el.key === row.row.key);
    if (found) {
      setSelectedRows((pre) => pre.filter((x) => x.key !== row.row.key));
    } else {
      setSelectedRows((pre) => [
        ...pre,
        {
          key: row.row.key,
          employer: row.row.employer,
          employer_id: row.row.employer_id,
        },
      ]);
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchMore = () => {
    if (dashboard && !totoalTalents) {
      userContext.setDashboardData((pre) => ({
        ...pre,
        talentPage: pre.talentPage + 1,
      }));
    } else if (totoalTalents) {
      console.log("it is total");
      userContext.setDashboardData((pre) => ({
        ...pre,
        totalTalentPage: pre.totalTalentPage + 1,
      }));
    } else {
      userContext.setUserData((pre) => ({ ...pre, page: pre.page + 1 }));
    }
  };

  const handleSort = (columnName) => {
    if (
      auth.isSpectra &&
      ["2", "3", "4", "5", "6"].includes(userContext.userData.activeTabIndex)
    ) {
      requestSort(columnName);
    } else {
      let sortName = "";
      switch (columnName) {
        case "name":
          sortName = "personal.full_name.keyword";
          break;
        case "bGroup":
          sortName = "personal.blood_group.keyword";
          break;
        case "bankAC":
          sortName = "bank.account_number.keyword";
          break;
        case "contractor":
          sortName = "status.current_contractor_name.keyword";
          break;
        case "employer":
          sortName = "status.current_primary_employer_name.keyword";
          break;
        case "pincode":
          sortName = "contact.pincode.keyword";
          break;
        default:
          break;
      }

      const setSortColumn = (context, sortName, initialSort = "asc") => {
        if (context[sortName]) {
          context[sortName] = context[sortName] === "asc" ? "desc" : "asc";
        } else {
          context[sortName] = initialSort;
        }
        return context;
      };

      const updateSortColumn = (dashboardContext, userContext, sortName) => {
        if (totoalTalents) {
          const newSortColumn = setSortColumn(
            dashboardContext.sortColumn || {},
            sortName
          );
          userContext.setDashboardData((pre) => ({
            ...pre,
            sortColumn: newSortColumn,
            isSort: !pre.isSort,
          }));
        } else {
          const newSortColumn = setSortColumn(
            userContext.sortColumn || {},
            sortName
          );
          userContext.setUserData((pre) => ({
            ...pre,
            sortColumn: newSortColumn,
            isSort: !pre.isSort,
          }));
        }
      };

      updateSortColumn(
        userContext.dashboardData,
        userContext.userData,
        sortName
      );
    }
  };

  const CheckBoxElement = (row) => {
    const data = row;
    const [checked, setChecked] = useState(false);
    useEffect(() => {
      const found = selectedRows.some((el) => el.key === data.row.key);
      setChecked(found);
    }, [data.row.key, selectedRows]);

    return (
      <input
        checked={checked}
        onChange={() => handleRowSelection(row)}
        type="checkbox"
      />
    );
  };

  const handleEdit = (row) => {
    console.log("Edit user:", row);
    // Implement your edit logic here
    userContext.setUserData((pre) => {
      return { ...pre, editUser: row, editUserTrigger: !pre.editUserTrigger };
    });
    localStorage.setItem("count", 1);

    if (
      auth.isSpectra &&
      ["4", "5", "6"].includes(userContext.userData.activeTabIndex)
    ) {
      userContext.setUserData((pre) => {
        return {
          ...pre,
          editUser: row,
          editUserTrigger: !pre.editUserTrigger,
        };
      });
      userContext.setUserData((prev) => ({
        ...prev,
        isModalVisible: true, // Example: Show a modal or perform an action
      }));
      localStorage.setItem("count", 1);
      return;
    }
    if (
      dashboard &&
      auth.isSpectra &&
      userContext.userData.activeTabIndex === "1"
    ) {
      history.push({
        pathname: `/Talntworx/user`,
        state: { id: row.key },
      });
      userContext.setDashboardData((pre) => {
        return {
          ...pre,
          totalTalentTrigger: true,
        };
      });
    } else if (dashboard && auth.isContract) {
      history.push({
        pathname: `/contract/user`,
        state: { id: row.key },
      });
      userContext.setDashboardData((pre) => {
        return {
          ...pre,
          totalTalentTrigger: true,
        };
      });
    } else if (dashboard && auth.isPrimary) {
      history.push({
        pathname: `/primary/user`,
        state: { id: row.key },
      });
      userContext.setDashboardData((pre) => {
        return {
          ...pre,
          totalTalentTrigger: true,
        };
      });
    } else if (auth.isSpectra && userContext.userData.activeTabIndex === "1")
      history.push({
        pathname: `./userPanel/user/`,
        state: { id: row.key },
      });
    else if (auth.isSpectra && userContext.userData.activeTabIndex === "3")
      history.push({
        pathname: `./userPanel/primaryTabs/user/`,
        state: { id: row.key },
      });
    else if (auth.isContract)
      history.push({
        pathname: `./userPanel/user/`,
        state: { id: row.key },
      });
    else if (auth.isPrimary)
      history.push({
        pathname: `./userPanel/user/`,
        state: { id: row.key },
      });
  };

  const handleDelete = (row) => {
    setRowToDelete(row);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    const BASE_API_URL = "https://urchin-app-fi4og.ondigitalocean.app/api/web/";
    if (!rowToDelete) return;

    try {
      // Handle "talnt" user type condition
      if (rowToDelete?.key && rowToDelete?.data?.personal?.type === "talnt") {
        try {
          const res = await deletePost(
            `${BASE_API_URL}talnt/${rowToDelete?.data?.allUserId}/${rowToDelete?.key}/${rowToDelete?.data?.AllUser?.firebaseId}`
          );
          if (res?.data?.data?.id) {
            const newData = localData.filter(
              (item) => item.key !== rowToDelete.key
            );
            setLocalData(newData);
            userContext.setUserData((pre) => ({ ...pre, tableData: newData }));
            message.success({ content: "Users successfully deleted" });
            setIsDeleteModalVisible(false);
            console.log("State updated with new data:", newData);
            return; // Exit after successful deletion
          }
        } catch (error) {
          console.error("Error removing document (talnt):", error);
          return; // Exit on error
        }
      }
      // Handle spectra user type separately
      else if (userType === "spectra") {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const apiFetches = [
            fetchData(`${BASE_API_URL}employeerUsers?id=${rowToDelete.key}`),
            fetchData(`${BASE_API_URL}bizopsusers?id=${rowToDelete.key}`),
            fetchData(`${BASE_API_URL}primaryEmployeers/${rowToDelete.key}`),
          ];

          const responses = await Promise.all(apiFetches);
          console.log(responses, "responses bizopss");
          console.log(responses[2].data.data, "responses");
          console.log(responses[0]?.data?.status, "responses123");

          let employeerData, bizopsData, primaryData;
          if (responses[0]?.data?.status === 200) {
            employeerData = responses[0].data.data[0] || null;
          } else if (responses[1]?.data?.status === 200) {
            bizopsData = responses[1].data.data[0] || null;
            primaryData = responses[2].data?.data || null;
          } else if (responses[2]?.data?.status === 200) {
            primaryData = responses[2].data?.data || null;
          }

          const deleteEndpoints = [];

          if (employeerData) {
            const { allUserId, firebaseId, id } = employeerData;
            if (allUserId && firebaseId && id) {
              deleteEndpoints.push(
                `employeerUsers/${allUserId}/${rowToDelete.key}/${firebaseId}`
              );
            } else {
              console.warn(
                "Missing required employeerData fields, skipping employeer deletion."
              );
            }
          }

          if (bizopsData) {
            const { allUserId, firebaseId, id } = bizopsData;
            if (allUserId && firebaseId && id) {
              deleteEndpoints.push(
                `bizopsUsers/${allUserId}/${rowToDelete.key}/${firebaseId}`
              );
            } else {
              console.warn(
                "Missing required bizopsData fields, skipping bizops deletion."
              );
            }
          }

          if (primaryData) {
            const { allUserId, id } = primaryData;
            if (allUserId && id) {
              console.log("Hi123");
              deleteEndpoints.push(`primaryEmployeers/${rowToDelete.key}/`);
            } else {
              console.warn(
                "Missing required primary employeer fields, skipping bizops deletion."
              );
            }
          }

          if (deleteEndpoints.length === 0) {
            console.error("No valid user data available for deletion.");
            return;
          }

          try {
            const deletePromises = deleteEndpoints.map(async (endpoint) => {
              try {
                const res = await deletePost(endpoint);
                if (res.status === 200) {
                  message.success({ content: `${res?.data?.message}` });
                  userContext.setUserData((prev) => ({
                    ...prev,
                    reload: !prev.reload,
                  }));
                } else {
                  console.error(
                    `Failed to delete from API (${endpoint}):`,
                    res.data
                  );
                  message.success({ content: "Failed to delete from API" });
                }
              } catch (apiError) {
                console.error(
                  `Error deleting from API (${endpoint}):`,
                  apiError.message
                );
              }
            });

            await Promise.all(deletePromises);
          } catch (apiError) {
            console.error("Error deleting row from APIs:", apiError.message);
            message.error({
              content: apiError.message || "API deletion failed",
            });
            return;
          }

          // Delete from Firestore collections
          const collections = [
            "all_users",
            "talent_users",
            "spectrawise_bizops_users",
            "primary_employer_users",
            "contractor_users",
            "primary_employers",
            "contractors",
          ];
          try {
            const promises = collections.map((collectionName) => {
              const docRef = doc(db, collectionName, rowToDelete.key);
              return deleteDoc(docRef);
            });
            await Promise.all(promises);

            console.log("Document successfully deleted from all collections!");
            const newData = localData.filter(
              (item) => item.key !== rowToDelete.key
            );
            setLocalData(newData);
            userContext.setUserData((pre) => ({ ...pre, tableData: newData }));

            console.log("State updated with new data:", newData);
          } catch (error) {
            console.error("Error removing document:", error);
          }
        } else {
          console.log("No user is signed in.");
        }
      } else {
        message.error({ content: "Talent User associated with contract user" });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }

    setIsDeleteModalVisible(false);
    setRowToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setRowToDelete(null);
  };

  const [isAction, setIsAction] = useState({
    action: false,
    isEdit: false,
    isDelete: false,
  });

  useEffect(() => {
    const { activeTabIndex } = userContext.userData;
    let hasEditPermission = false;
    let hasDeletePermission = false;

    if (activeTabIndex === "6") {
      hasEditPermission = permission?.Bizops.edit || false;
      hasDeletePermission = permission?.Bizops.delete || false;
    } else if (activeTabIndex === "3") {
      hasEditPermission = permission?.["Principle Employer"].edit || false;
      hasDeletePermission = permission?.["Principle Employer"].delete || false;
    } else if (activeTabIndex === "2") {
      hasEditPermission = permission?.Contractors.edit || false;
      hasDeletePermission = permission?.Contractors.delete || false;
    } else if (activeTabIndex === "1") {
      hasEditPermission = permission?.Talent.edit || false;
      hasDeletePermission = permission.Talent?.delete || false;
    } else if (activeTabIndex === "5") {
      hasEditPermission = permission?.["PE User"].edit || false;
      hasDeletePermission = permission?.["PE User"]?.delete || false;
    }

    setIsAction({
      action: hasEditPermission || hasDeletePermission,
      isEdit: hasEditPermission,
      isDelete: hasDeletePermission,
    });
  }, [
    userContext.userData.activeTabIndex,
    permission.Bizops?.edit,
    permission.Bizops?.delete,
    permission?.["Principle Employer"]?.edit,
    permission?.["Principle Employer"]?.delete,
    permission?.["Principle Employer"]?.edit,
    permission?.["Principle Employer"]?.delete,
    permission?.["PE User"]?.edit,
    permission?.["PE User"]?.delete,
    permission?.Contractors?.edit,
    permission?.Contractors?.delete,
    permission?.Talent?.edit,
    permission?.Talent?.delete,
    permission?.Talent?.read,
    permission?.Talent?.write,
  ]);

  const checkboxHandler = (e, id) => {
    setIsCheckbox((prev) => {
      let newCheckboxData = prev.map((ele) =>
        ele.id === id ? { ...ele, isChecked: e.target.checked } : ele
      );
      if (e.target.checked && !newCheckboxData.some((ele) => ele.id === id)) {
        newCheckboxData.push({ id: id, isChecked: true });
      }
      if (!e.target.checked) {
        if (newCheckboxData.some((ele) => ele.id === id)) {
          newCheckboxData = newCheckboxData.map((ele) =>
            ele.id === id ? { ...ele, isChecked: false } : ele
          );
        } else {
          newCheckboxData.push({ id: id, isChecked: false });
        }
      }

      return newCheckboxData;
    });
  };

  useEffect(() => {
    let findIsChecked = isCheckbox.find((ele) => ele.isChecked);
    let findSelectedTalents = isCheckbox.filter((ele) => ele.isChecked);
    dispatch(
      updateSelectedTalent({
        findIsChecked: findIsChecked ? true : false,
        findSelectedTalents,
      })
    );
  }, [isCheckbox]);

  return (
    <div className="responsiveTable">
      <InfiniteScroll
        dataLength={localData.length}
        hasMore={true}
        key={userContext.userData.activeContractTabIndex}
        next={fetchMore}
        scrollableTarget="respTableScroll"
      >
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : localData.length === 0 ? (
          <div className="noDataFound">
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                fontSize: "24px",
                color: "#777",
                alignItems: "center",
              }}
            >
              No Data Found
            </h3>
          </div>
        ) : (
          <div className="tableWraper" id="respTableScroll">
            <table>
              <thead>
                <tr>
                  {talents && !totoalTalents ? <th></th> : <th></th>}

                  {column.map((field, i) => {
                    let ffd = filterValue.find(
                      (fv) => fv.dataIndex === field.dataIndex
                    );
                    if (
                      (selectedColumn.includes(i) ||
                        ["1", "2", "3", "4", "5", "6"].includes(
                          userContext.userData.activeTabIndex
                        )) &&
                      !(
                        (auth.isContract || auth.isPrimary) &&
                        field.dataIndex == "status"
                      )
                    )
                      return (
                        <th
                          key={i}
                          className="field head"
                          style={{
                            minWidth:
                              userInfo?.userType !== "Employeer"
                                ? "100px"
                                : "300px",
                          }}
                        >
                          {field.title}{" "}
                          {field.sort && (
                            <img
                              style={{ cursor: "pointer" }}
                              width="15px"
                              height="15px"
                              onClick={() => handleSort(field.dataIndex)}
                              src={sortIcon}
                              alt="sort"
                            />
                          )}
                        </th>
                      );
                    else return null;
                  })}
                  {isAction.action && userInfo?.userType !== "Employeer" && (
                    <th className="field head">Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {items.map((row, idx) => {
                  if ([0, 1, 2, 3, 5, 6, 9, 10, 4].length > 0) {
                    return (
                      <>
                        <tr key={idx} className="">
                          {talents && !totoalTalents ? <td></td> : <td></td>}

                          {column.map((field, i) => {
                            let statusSRC;

                            let statusText;
                            let statusStyle = {
                              fontWeight: "bold",
                              textAlign: "start", // Center align text
                              display: "block", // Ensures centering works inside table cell
                              marginLeft: "-12px",
                            };
                            if (field.dataIndex === "status")
                              switch (row[field.dataIndex]) {
                                case "Completed Profile":
                                  statusSRC = CP;
                                  break;
                                case "Incomplete Profile":
                                  statusSRC = IP;
                                  break;
                                case "Yet To Be Verified":
                                  statusSRC = YTV;
                                  break;
                                case "Employed":
                                  statusSRC = E;
                                  break;
                                case "Available":
                                  statusSRC = UE;
                                  break;
                                default:
                                  statusSRC = IP;
                                  break;
                              }

                            switch (row[field.dataIndex]) {
                              case "Completed Profile":
                                statusText = "Completed";
                                statusStyle.color = "blue";
                                break;
                              case "Incomplete Profile":
                                statusText = "Incomplete";
                                statusStyle.color = "red";
                                break;
                              case "Yet To Be Verified":
                                statusText = "Yet To Be Verified";
                                statusStyle.color = "orange";
                                break;
                              case "Employed":
                                statusText = "Employed";
                                statusStyle.color = "green";
                                break;
                              case "Available":
                                statusText = "Available";
                                statusStyle.color = "gray";
                                break;
                              default:
                                statusText = "Unknown";
                                statusStyle.color = "purple";
                                break;
                            }
                            if (
                              (selectedColumn.includes(i) ||
                                ["1", "2", "3", "4", "5", "6"].includes(
                                  userContext.userData.activeTabIndex
                                )) &&
                              !(
                                (auth.isContract || auth.isPrimary) &&
                                field.dataIndex == "status"
                              )
                            ) {
                              return (
                                <>
                                  <td
                                    onClick={() => {
                                      if (userInfo?.userType !== "Employeer") {
                                        if (
                                          auth.isSpectra &&
                                          ["4", "5", "6"].includes(
                                            userContext.userData.activeTabIndex
                                          )
                                        ) {
                                          userContext.setUserData((pre) => {
                                            return {
                                              ...pre,
                                              editUser: row,
                                              editUserTrigger:
                                                !pre.editUserTrigger,
                                            };
                                          });
                                          localStorage.setItem("count", 1);
                                          return;
                                        }
                                        if (
                                          dashboard &&
                                          auth.isSpectra &&
                                          userContext.userData
                                            .activeTabIndex === "1"
                                        ) {
                                          history.push({
                                            pathname: `/Talntworx/user`,
                                            state: { id: row.key },
                                          });
                                          userContext.setDashboardData(
                                            (pre) => {
                                              return {
                                                ...pre,
                                                totalTalentTrigger: true,
                                              };
                                            }
                                          );
                                        } else if (
                                          dashboard &&
                                          auth.isContract
                                        ) {
                                          history.push({
                                            pathname: `/contract/user`,
                                            state: { id: row.key },
                                          });
                                          userContext.setDashboardData(
                                            (pre) => {
                                              return {
                                                ...pre,
                                                totalTalentTrigger: true,
                                              };
                                            }
                                          );
                                        } else if (
                                          dashboard &&
                                          auth.isPrimary
                                        ) {
                                          history.push({
                                            pathname: `/primary/user`,
                                            state: { id: row.key },
                                          });
                                          userContext.setDashboardData(
                                            (pre) => {
                                              return {
                                                ...pre,
                                                totalTalentTrigger: true,
                                              };
                                            }
                                          );
                                        } else if (
                                          auth.isSpectra &&
                                          userContext.userData
                                            .activeTabIndex === "1"
                                        )
                                          history.push({
                                            pathname: `./userPanel/user/`,
                                            state: { id: row.key },
                                          });
                                        else if (
                                          auth.isSpectra &&
                                          userContext.userData
                                            .activeTabIndex === "3"
                                        ) {
                                          if (
                                            permission?.["Principle Employer"]
                                              ?.read ||
                                            permission?.["Principle Employer"]
                                              ?.edit ||
                                            permission?.["Compliance"]?.read ||
                                            permission?.["Job Role"]?.read ||
                                            permission?.["PE User"]?.read ||
                                            permission?.["Talent Employee"]
                                              ?.read
                                          ) {
                                            history.push({
                                              pathname: `./userPanel/primaryTabs/user/`,
                                              state: { id: row.key },
                                            });
                                          }
                                        } else if (auth.isContract)
                                          history.push({
                                            pathname: `./userPanel/user/`,
                                            state: { id: row.key },
                                          });
                                        else if (auth.isPrimary)
                                          history.push({
                                            pathname: `./userPanel/user/`,
                                            state: { id: row.key },
                                          });
                                      }
                                    }}
                                    style={{
                                      backgroundColor: selectedRows.includes(
                                        row.key
                                      )
                                        ? "#F5E9FF"
                                        : undefined,
                                    }}
                                    key={i}
                                    // style={{ width: field.width + "px" }}
                                    className=""
                                    title={row?.[field?.dataIndex]}
                                  >
                                    {field.dataIndex === "assignTalent" &&
                                      userInfo?.userType !== "Employeer" && (
                                        <input
                                          type="checkbox"
                                          disabled={
                                            row.status === "Available"
                                              ? false
                                              : true
                                          }
                                          checked={
                                            isCheckbox.length > 0 &&
                                            isCheckbox.find((ele) =>
                                              ele.id === row.key
                                                ? ele.isChecked
                                                : false
                                            )
                                          }
                                          onChange={(e) =>
                                            checkboxHandler(e, row.key)
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevents the event from bubbling up to <td>
                                          }}
                                        />
                                      )}
                                    {field?.dataIndex === "logo" ? (
                                      <img
                                        className="logo_img"
                                        src={row?.[field?.dataIndex]}
                                        alt={row?.[field?.dataIndex]}
                                      />
                                    ) : field.dataIndex === "status" ? (
                                      <>
                                        {/* <img
                                        src={statusSRC}
                                        alt={row?.[field?.dataIndex]}
                                      /> */}
                                        <span style={statusStyle}>
                                          {statusText}
                                        </span>
                                      </>
                                    ) : (
                                      // row[field.dataIndex] ? "verified" : <span style={{ color: "#53D060" }}>Yet to be verified</span>
                                      row?.[field?.dataIndex]
                                    )}
                                  </td>
                                </>
                              );
                            } else return null;
                          })}
                          {isAction.action &&
                            userInfo?.userType !== "Employeer" && (
                              <td>
                                <>
                                  {isAction.isEdit && (
                                    <img
                                      src={editIcon}
                                      alt="Edit"
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => handleEdit(row)}
                                    />
                                  )}
                                </>

                                <>
                                  {isAction.isDelete &&
                                    userInfo?.userType !== "Employeer" && (
                                      <img
                                        src={deleteIcon}
                                        alt="Delete"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDelete(row)}
                                      />
                                    )}
                                </>
                              </td>
                            )}
                        </tr>
                      </>
                    );
                  } else return null;
                })}
              </tbody>
            </table>
          </div>
        )}
      </InfiniteScroll>
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        style={{
          width: "100px",
          height: "100px",
          padding: "10px",
        }}
        onOk={confirmDelete}
        onCancel={handleCancelDelete}
        okText="Delete"
        cancelText="Cancel"
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "-22px",
          }}
        >
          Are you sure you want to delete this user?
        </h1>
      </Modal>
    </div>
  );
}

export default ResponsiveTable;
