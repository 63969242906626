import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import sortIcon from "../../../assets/icons/sort.svg";
import { useLocation } from "react-router-dom";
import { createPost } from "../../../backendApi";

const PreviouseEmployee = ({neme}) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);
  const [from, setFrom] = useState(0);
  const { id } = useLocation().state;
  const [detachTalents, setDetachTalents] = useState({
    detached: [],
    isDetachBtn: false,
  });

  const pageSize = 100;

  const columns = [
    { title: "", dataIndex: "", sort: false },
    { title: "Name", dataIndex: "fullName", sort: true },
    { title: "Mobile", dataIndex: "mobileNumber", sort: false },
    { title: "State", dataIndex: "state", sort: true },
    { title: "Role", dataIndex: "role", sort: false },
    { title: "Contractor", dataIndex: "contractor", sort: false },
    { title: "Date Of Joining", dataIndex: "dateOfJoin", sort: false },
    { title: "Date Of Exit", dataIndex: "dateOfExit", sort: false },
    { title: "Reason", dataIndex: "reason", sort: false },
  ];

  const fetchMoreData = async () => {
    debugger
    try {
      const response = await createPost("/dashboard/previouseEmployee", {
        from,
        size: pageSize,
        type: "initial",
        sortColumn: null,
        primaryEmployeerId: [id],
      });
      const fetchedData = response?.data?.data || [];

      const uniqueData = fetchedData.filter(
        (item) => !data.some((existingItem) => existingItem.id === item.id)
      );
      setData((prev) => [...prev, ...uniqueData]);
      setFrom((prev) => prev + pageSize);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setIsLoading(false);
    }
  };

  const handleSort = (dataIndex) => {
    const sortedData = [...data];
    const isAscending = sortOrder === "asc";

    sortedData.sort((a, b) => {
      if (a[dataIndex] < b[dataIndex]) return isAscending ? 1 : -1;
      if (a[dataIndex] > b[dataIndex]) return isAscending ? -1 : 1;
      return 0;
    });

    setSortOrder(isAscending ? "desc" : "asc");
    setData(sortedData);
  };

  useEffect(() => {
    fetchMoreData();
  }, [neme]);

  return (
    <div className="responsiveTable">
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={true}
        scrollableTarget="respTableScroll"
      >
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : data.length === 0 ? (
          <div className="noDataFound">
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                fontSize: "24px",
                color: "#777",
                alignItems: "center",
              }}
            >
              No Data Found
            </h3>
          </div>
        ) : (
          <div
            className="tableWraper"
            id="respTableScroll"
            style={{ paddingTop: "2rem" }}
          >
            <table>
              <thead>
                <tr>
                  {columns.map((field, i) => (
                    <th key={i} className="field head">
                      {field.title}{" "}
                      {field.sort && (
                        <img
                          style={{ cursor: "pointer" }}
                          width="15px"
                          height="15px"
                          onClick={() => handleSort(field.dataIndex)}
                          src={sortIcon}
                          alt="sort"
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      {columns.map((field, i) => {
                        return (
                          <>
                            <td key={i}>
                              {field.dataIndex === "dateOfJoin" &&
                              item[field.dataIndex] === "N/A"
                                ? "N/A"
                                : field.dataIndex === "dateOfJoin" &&
                                  item[field.dataIndex] !== "N/A"
                                ? item[field.dataIndex]
                                : item[field.dataIndex]}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default PreviouseEmployee;
