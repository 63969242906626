import React, { useContext, useEffect, useState } from "react";
import { message, Tabs, Select, Input, Button } from "antd";
import { dataContext } from "../../context/data";
import ContractUserList from "../contractuser/ContractUserList";
import "../../styles/Panel.scss";
import ContractorDetails from "./ContractorDetail";
import { getContractorById } from "../../services/contractService";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { fetchDataEachRow } from "../../backendApi";
import GoBack from "../../assets/icons/leftArrow.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import EmployeeList from "./Employeer";

const { TabPane } = Tabs;

const { Search } = Input;
const { Option } = Select;

const tabStyle = {
  fontSize: "1.3vw",
  fontWeight: "600",
};

function ContractorTabs() {
  const url = window.location.href;
  const contract_id = url.split("/").pop();
  const { userData, setUserData } = useContext(dataContext);
  const [primaryContrName, setPrimaryContrName] = useState();
  const { searchState, setSearchState } = useContext(dataContext);
  const [userName, setUserName] = useState("");
  const history = useHistory();
  console.log(userName, "userName");

  useEffect(() => {
    loadContractorDetails();
  }, [contract_id]);

  const handleSearchFieldChange = (value) => {
    setSearchState((prev) => ({
      ...prev,
      searchField: value, // Update search field
    }));
  };

  const handleSearchTermChange = (value) => {
    setSearchState((prev) => ({
      ...prev,
      searchTerm: value, // Update search term
    }));
  };

  const handleAddNewContractorUserClick = () => {
    setUserData((prev) => ({
      ...prev,
      isContractorUserModalVisible: true, // Example: Show a modal or perform an action
    }));
  };

  const loadContractorDetails = async () => {
    try {
      const data = await getContractorById(contract_id);
      setPrimaryContrName(data?.name);
    } catch (error) {
      console.error("Error loading contractor details:", error);
      message.error("Failed to load contractor details");
    }
  };

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await fetchDataEachRow(
          `https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryContractors/${contract_id}`
        );
        //console.log(response, "response22233")
        if (response?.data?.status === 200) {
          const data = response?.data?.data;
          if (Array.isArray(data)) {
            const user = data.find((user) => user.id === contract_id);
            setUserName(user?.name || "Unknown User");
          } else if (typeof data === "object" && data !== null) {
            setUserName(data?.name || "Unknown User");
          } else {
            console.error("Unexpected data format:", data);
          }
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (contract_id) {
      fetchUserName();
    }
  }, [contract_id]);

  console.log(userData.activePrimaryTabIndex, "userData.activePrimaryTabIndex");

  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      activePrimaryTabIndex: "1",
    }));
  }, [setUserData]);

  const handleTabChange = (tabKey) => {
    setUserData((prev) => ({
      ...prev,
      activePrimaryTabIndex: tabKey,
      selectedRows: [],
      filterValue: [],
      filterQuery: {},
      page: 0,
    }));
  };

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  return (
    <div className="tabs">
      {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
    <span
            className="leftArw"
            onClick={() => {
              setUserData((prev) => ({
                ...prev,
                activeTabIndex: "2", // Set the tab index to 3
              }));
              history.goBack(); // Go back to the previous page
            }}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <img
              src={GoBack}
              alt="go back"
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
          </span>
     <div className="searchwraper" style={{  width: "375px", display: "flex", marginLeft:"2rem"  }}>
     <span
              style={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {userName}
            </span>
                    <Select
                      defaultValue={"all"}
                      size="large"
                      className="searchSelect"
                      listHeight={200}
                      value={searchState.searchField}
                      dropdownClassName="searchOption"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={true}
                      onChange={handleSearchFieldChange}
                      allowClear
                    >
                    <Option value="All">All</Option>
                    <Option value="fullName">Name</Option>
                    <Option value="businessName">Business Name</Option>
                    <Option value="mobileNumber">Mobile Number</Option>
                    <Option value="roleName">Role Name</Option>
                    </Select>
                    <Search
                      placeholder="Search"
                      size="large"
                      allowClear
                      // value={filterText.value}
                      // id="searchTextInput"
                      style={{ marginRight: "20px", width: "15vw" }}
                      // className="searchBox"
                      onChange={debounce((e) => handleSearchTermChange(e.target.value), 300)}
                      //onSearch={(value) => handleSearchValueUpdate(value)}
                      enterButton
                    />
                  </div>
                  {permission["Contractors User"].write && (
          <Button
           // style={{ float: "right", right: "0px" }}
           style={{marginRight:"2rem"}}
            type="primary"
            onClick={handleAddNewContractorUserClick}
            className="mb-4"
          >
            New Contract User
          </Button>
        )}
        </div> */}
      {userData.activePrimaryTabIndex === "1" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          {/* Left Section: Go Back, Username, Search */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "7px",
              marginLeft: "10px",
            }}
          >
            {/* Go Back Button */}
            <span
              className="leftArw"
              onClick={() => {
                setUserData((prev) => ({
                  ...prev,
                  activeTabIndex: "2",
                }));
                history.goBack();
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={GoBack}
                alt="go back"
                style={{ width: "20px", height: "20px", objectFit: "contain" }}
              />
            </span>

            {/* Username */}
            <span
              style={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {userName}
            </span>

            {/* Search Wrapper */}
            <div
              className="searchwraper"
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "2rem",
              }}
            >
              <Select
                defaultValue={"all"}
                size="large"
                className="searchSelect"
                listHeight={200}
                value={searchState.searchField}
                dropdownClassName="searchOption"
                dropdownMatchSelectWidth={false}
                defaultActiveFirstOption={true}
                onChange={handleSearchFieldChange}
                allowClear
              >
                <Option value="All">All</Option>
                <Option value="fullName">Name</Option>
                <Option value="businessName">Business Name</Option>
                <Option value="mobileNumber">Mobile Number</Option>
                <Option value="roleName">Role Name</Option>
              </Select>

              <Search
                placeholder="Search"
                size="large"
                allowClear
                style={{ width: "15vw" }}
                onChange={debounce(
                  (e) => handleSearchTermChange(e.target.value),
                  300
                )}
                enterButton
              />
            </div>
          </div>

          {/* Right Section: Button */}
          {permission?.["Contractors User"]?.write && (
            <Button
              type="primary"
              onClick={handleAddNewContractorUserClick}
              className="mb-4"
              style={{ marginRight: "1rem" }}
            >
              New Contract User
            </Button>
          )}
        </div>
      )}
      <Tabs
        destroyInactiveTabPane
        // defaultActiveKey="1"
        activeKey={userData.activePrimaryTabIndex}
        onChange={handleTabChange}
        className="tabSlider"
        size="large"
      >
        {" "}
        {(permission?.["Contractors User"]?.read ||
          permission?.["Contractors User"]?.write) && (
          <TabPane tab="Users" tabKey="1" key="1" style={tabStyle}>
            <ContractUserList
              contract_id={contract_id}
              primaryCname={primaryContrName}
            />
          </TabPane>
        )}
        {permission?.Contractors?.read && (
          <TabPane tabKey="2" tab="Company" key="2" style={tabStyle}>
            <ContractorDetails contract_id={contract_id} />
          </TabPane>
        )}
        {permission?.Contractors?.read && (
          <TabPane tabKey="3" tab="Associates" key="3" style={tabStyle}>
            <EmployeeList />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default ContractorTabs;
