import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

function JobDetailHeader({ onClose }) {
  return (
    <div className="jobDetailHeader">
      <div className="left">
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={onClose}>
          Post details
        </Button>
      </div>
    </div>
  );
}

export default JobDetailHeader;
