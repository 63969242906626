import {
  Modal,
  AutoComplete,
  Input,
  Select,
  message,
  notification,
  DatePicker,
  TimePicker,
  Button,
  Tooltip,
  Form,
  Space,
} from "antd";
import upload from "../../../assets/icons/upload.svg";
import cancelBtn from "../../../assets/icons/cancel.svg";
import AssignTalent from "../../panel/panelHead/AssignTalent";
import { useEffect, useRef, useState } from "react";
import "./tabStyles.css";
import {
  addDoc,
  firestore,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getFirestore,
  Timestamp,
  deleteDoc,
  query,
  setDoc,
  where,
  serverTimestamp,
  get,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
//import axios from "axios";
import { useContext } from "react";
import { dataContext } from "../../../context/data";
import CSVReader from "react-csv-reader";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../../axiosConfig/index";
import { fetchData } from "../../../backendApi";

const functions_url = process.env.REACT_APP_FUNCTIONS_URL;
const CompanyTabs = ({ userType }) => {
  const { id } = useLocation().state;
  const history = useHistory();
  // console.log(id, "id get values")
  const db = getFirestore();
  const [form] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [isModalVisible, setisModalVisible] = useState(false);
  const { userData, setUserData } = useContext(dataContext);
  const [isEnrollModalVisible, setisEnrollModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  //for modal details
  const [userTypeSelected, setUserTypeSelected] = useState(
    "primary_employer_user"
  );
  const [businessName, setBusinessName] = useState("");
  const [editKey, setEditKey] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [{ primary_employers, contractors }, setPrimaryAndContract] = useState({
    primary_employers: [],
    contractors: [],
  });
  const [businessID, setBusinessID] = useState("");
  const [primaryEmpData, setPrimaryEmpData] = useState([]);
  const userContext = useContext(dataContext);
  const [imageValue, setImageValue] = useState(null);
  const [uploadErr, setUploadErr] = useState(false);
  const [imageUploads, setImageUpload] = useState([]);
  const [verify, setVerify] = useState(false);
  const [panIdName, setPanIdName] = useState("");
  const [companyTypes, setCompanyTypes] = useState([]); // State to store company types
  const [contractDetails, setContractDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
    linkedPrimaryEmployers: [],
  });
  const [primaryDetails, setPrimaryDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
  });
  console.log(primaryDetails, "primaryDetails");
  const [error, setError] = useState({
    pan: false,
    gstin: false,
    epf: false,
    esic: false,
    pt: false,
    tan: false,
  });

  //console.log(primaryDetails, "contractDetails")

  const { Option } = Select;
  const storage = getStorage();

  let { permission } = useSelector(
    (state) => state?.user?.permission?.getUserRole
  );

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (
        event.target.files[0].type === "image/png" &&
        event.target.files[0].size < 200000
      ) {
        setImageValue(URL.createObjectURL(event.target.files[0]));
        setImageUpload(event.target.files[0]);
        setUploadErr(false);
      } else {
        setUploadErr(true);
        setImageUpload([]);
      }
    }
  };
  const closeUpoloadImg = () => {
    setImageValue(false);
  };

  const showErrorModal = (errorMessages) => {
    Modal.error({
      title: "Validation Errors",
      content: (
        <ul>
          {errorMessages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      ),
      okText: "Close",
    });
  };

  useEffect(() => {
    const fetchResData = async () => {
      try {
        const response = await fetchData(`/primaryEmployeers/${id}`);
        if (response?.data?.data) {
          const data = response?.data?.data;
          const updatedUserData = {
            ...userData,
            editUser: {
              ...userData.editUser,
              key: data?.id,
              bname: data?.name,
              address: data?.address,
              pan: data?.panNumber,
              gstin: data?.gstinNumber,
              epf: data?.epfRegNumber,
              esic: data?.esicRegNumber,
              pt: data?.ptRegNumber,
              tan: data?.tanNumber,
              logo: data?.logo,
              company: data?.companyTypeId,
              linkedPrimaryEmployers: data?.linkedPrimaryEmployers || [],
            },
          };
          setUserData(updatedUserData);
        }
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err);
      }
    };

    if (id) {
      fetchResData();
    }
  }, [id]);

  useEffect(() => {
    if (userTypeSelected === "spectrawise_bizops_user")
      setBusinessName("Talntworx Technologies ");
    else setBusinessName("");
  }, [userTypeSelected]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const metadata = {
    contentType: "image/jpeg",
  };
  async function uploadImg(str) {
    try {
      // if (str === "" || /[]{}sd/.test(str))
      const StrRef = ref(
        getStorage(),
        `/docImages/${moment().format("YYYYMMDD_hhmmss_SSS")}`
      );
      await uploadString(StrRef, str, "data_url");
      const res = await getDownloadURL(StrRef);
      //  console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return str;
    }

    // docIploadString(${moment()})
  }

  async function handleModalSave() {
    // if (
    //   phoneNumber.match(/^[6789]\d{9}$/) &&
    //   ["4", "5", "6"].includes(userData?.activeTabIndex)
    // ) {
    //   message.error({ content: "Please enter correct mobile number" });
    //   return;
    // }

    if (userData?.activeTabIndex === "3") {
      if (uploadErr) {
        return;
      }
    }

    message.loading({ content: "Saving...", duration: 0, key: "saving" });

    if (["1", "2", "3", "4", "5", "6"].includes(userData?.activeTabIndex)) {
      try {
        let clecName =
          userData?.activeTabIndex === "2"
            ? "contractors"
            : "primary_employers";

        // Prepare the payload
        let payload = {
          name:
            userData.activeTabIndex === "2"
              ? contractDetails.bname
              : primaryDetails.bname,
          companyTypeId:
            userData.activeTabIndex === "2"
              ? contractDetails.company
              : primaryDetails.company,
          address:
            userData.activeTabIndex === "2"
              ? contractDetails.address
              : primaryDetails.address,
          panNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.pan
              : primaryDetails.pan,
          gstinNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.gstin
              : primaryDetails.gstin,
          epfRegNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.epf
              : primaryDetails.epf,
          esicRegNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.esic
              : primaryDetails.esic,
          ptRegNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.pt
              : primaryDetails.pt,
          tanNumber:
            userData.activeTabIndex === "2"
              ? contractDetails.tan
              : primaryDetails.tan,
          linkedPrimaryEmployers:
            userData.activeTabIndex === "2"
              ? contractDetails.linkedPrimaryEmployers
              : primaryDetails.linkedPrimaryEmployers,
        };

        // Handle image upload if needed
        if (imageUploads.length !== 0) {
          const imageUpload = ref(
            storage,
            `primary_employers/${moment().format("YYYYMMDD_hhmmss_SSS")}`
          );
          await uploadBytes(imageUpload, imageUploads, metadata);
          const settedUrl = await getDownloadURL(imageUpload);

          // Add the image URL to your payload
          payload.logo = settedUrl;
        } else {
          // If no new image is uploaded, retain the existing logo
          payload.logo = userData.editUser?.logo;
        }
        // Perform API call
        const apiUrl = `https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers`;
        const method = editKey !== null ? "PUT" : "POST";
        const url = editKey !== null ? `${apiUrl}/${editKey}` : apiUrl;

        const responses =
          editKey !== null
            ? axios.put(url, JSON.stringify(payload))
            : axios.post(url, JSON.stringify(payload));
        //console.log(response,"response1222")
        responses.then((response) => {
          //console.log(result,"results12333")
          if (response.status === 200 && response.data?.status === 200) {
            message.success({
              content:
                response.data.message ||
                (editKey !== null
                  ? "Updated successfully"
                  : "Created successfully"),
              key: "saving",
            });

            // Reload and close modal
            setUserData((pre) => ({ ...pre, reload: !pre.reload }));
            setisModalVisible(false);
          } else {
            throw new Error(response.data?.message || "Unexpected response");
          }
        });
      } catch (err) {
        message.error({
          content: err.message || "Something went wrong",
          key: "saving",
        });
        console.error(err);
      }
    } else {
      // Handle other cases for user creation/update
    }
  }

  // const duplicateCheck = async (field, val) => {
  //   const q = query(collection(db, 'talent_users'), where(field, '==', val));
  //   const qRes = await getDocs(q);
  //   return qRes.empty;
  // }

  // const getFormattedDate = (date) => {
  //   const formatDate = moment(date, "DD-MM-YYYY").toDate();
  //   if (formatDate.getTime()) return formatDate;
  //   else throw Error("Please enter Date in specified format");
  // };

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current && userData.editUser) {
      if (localStorage.getItem("count") == 1) {
        async function fetch() {
          if (["2", "3", "4", "5", "6"].includes(userData?.activeTabIndex)) {
            //get contractors and primary employers business name
            message.loading({
              content: "Please wait...",
              duration: 0,
              key: "fetching",
            });

            const primary_employers = [];
            const contractors = [];
            const q = query(collection(db, "primary_employers"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              primary_employers.push({ id: doc.id, name: doc.data().name });
            });
            const qcontra = query(collection(db, "contractors"));
            const snapshotContra = await getDocs(qcontra);
            // console.log("contractors",)
            snapshotContra.forEach((doc) => {
              contractors.push({ id: doc.id, name: doc.data().name });
              // console.log("contractors",doc.data())
            });
            setPrimaryAndContract((pre) => {
              return { contractors, primary_employers };
            });
            message.destroy("fetching");
            setisModalVisible(true);
          }
        }
        fetch();
      } else {
        localStorage.setItem("count", 1);
      }
      localStorage.setItem("count", 2);
      localStorage.setItem("editUsrMnum", userData.editUser?.mnum);
      setBusinessName(userData.editUser?.bName || "");
      setPhoneNumber(userData.editUser?.mnum?.slice(3) || "");
      setFullName(userData.editUser?.name || "");
      setCountryCode(userData.editUser?.mnum?.slice(0, 3) || "+91");
      setBusinessID(userData.editUser?.bId || "");
      setEditKey(userData.editUser?.key || "");
      setImageValue(userData.editUser?.logo || "");
      setVerify(
        userData.editUser?.pan || userData.editUser?.pan !== "" ? true : false
      );
      {
        userData.activeTabIndex == "2" && setContractDetails(userData.editUser);
      }
      {
        setPrimaryDetails((pre) => {
          return {
            bname: userData.editUser?.bname,
            company: userData.editUser?.company,
            address: userData.editUser?.address,
            pan: userData.editUser?.pan,
            gstin: userData.editUser?.gstin,
            epf: userData.editUser?.epf,
            esic: userData.editUser?.esic,
            pt: userData.editUser?.pt,
            tan: userData.editUser?.tan,
          };
        });
      }
      // console.log(userData.editUser);
    } else {
      isMounted.current = true;
    }
  }, [userData.editUser]);

  function handleModalCancel(e) {
    e.preventDefault();
    const userTypeCheck =
      userData?.activeTabIndex == "4"
        ? "contractor_user"
        : userData?.activeTabIndex == "5"
        ? "primary_employer_user"
        : userData?.activeTabIndex == "6"
        ? "spectrawise_bizops_user"
        : "";
    setUserTypeSelected(userTypeCheck);
    // setBusinessID(userData?.activeTabIndex);
    setUserData((prev) => ({
      ...prev,
      activeTabIndex: "3", // Set the tab index to 3
    }));
    history.goBack();
    setContractDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      linkedPrimaryEmployers: [],
    });
    setError({
      pan: false,
      gstin: false,
      epf: false,
      esic: false,
      pt: false,
      tan: false,
    });
    setImageValue(false);
    setisModalVisible(false);
    setVerify(false);
  }

  useEffect(() => {
    async function fetch() {
      let temp = [];
      const q = query(collection(db, "primary_employers"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        temp.push({ value: doc.data().name, id: doc.id, key: doc.id });
      });
      // console.log(temp);
      setPrimaryEmpData(temp);
    }
    if (userType === "spectra" && isModalVisible) fetch();
  }, [isModalVisible]);

  useEffect(() => {
    async function fetch() {
      let temp = [];
      const docRef = doc(
        db,
        "contractors",
        localStorage.getItem("contractor_id")
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const contractorData = docSnap.data();
        const linkedPrimaryEmployersList =
          contractorData.linked_primary_employers;
        linkedPrimaryEmployersList?.map((doc) => {
          temp.push({ value: doc.name, id: doc.id, key: doc.id });
        });
        setPrimaryEmpData(temp);
      } else {
        console.log("No such document!");
      }
    }
    if (userType === "contract" || isEnrollModalVisible) fetch();
  }, [isEnrollModalVisible]);

  // Fetch company types from the API
  useEffect(() => {
    const fetchCompanyTypes = async () => {
      try {
        const response = await fetch(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/companyType"
        );
        const data = await response.json();
        setCompanyTypes(data?.data);
      } catch (error) {
        console.error("Error fetching company types:", error);
      }
    };

    fetchCompanyTypes();
  }, []);

  // async function validateNumber() {
  //   let allClear = {
  //     //pan: false,
  //     gstin: false,
  //     pt: false,
  //     tan: false,
  //     // epf: false,
  //     // esic: false,
  //   };

  //   const Gstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // Correct regex for GSTIN
  //   const tan = /^([A-Z]{4}[0-9]{5}[A-Z]{1})$/;

  //   message.loading({ content: "Validating...", key: "validate", duration: 0 });

  //   let errorMessages = [];
  //   const apiURL = "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers";

  //   // Function to check duplicates
  //   const checkDuplicate = async (fieldName, value) => {
  //     try {
  //       const response = await axios.get(apiURL);
  //       const data = response.data?.data || [];
  //       return data.some((item) => item[fieldName] === value); // Check duplicates
  //     } catch (error) {
  //       console.error(`Error checking ${fieldName}:`, error);
  //       return false;
  //     }
  //   };

  //   // GSTIN Validation
  //   if (contractDetails.gstin || primaryDetails.gstin) {
  //     let value = contractDetails.gstin || primaryDetails.gstin;
  //     if (Gstin.test(value)) {
  //       const exists = await checkDuplicate("gstinNumber", value);
  //       if (exists && value !== userData.editUser?.gstin) {
  //         errorMessages.push(`GSTIN number - ${value} already exists`);
  //         setError((prev) => ({ ...prev, gstin: true }));
  //       } else {
  //         allClear.gstin = true;
  //         setError((prev) => ({ ...prev, gstin: false }));
  //       }
  //     } else {
  //       errorMessages.push(`Invalid GSTIN number: ${value}`);
  //       setError((prev) => ({ ...prev, gstin: true }));
  //     }
  //   } else {
  //     errorMessages.push("GSTIN number is required");
  //   }

  //   // PT Validation
  //   if (contractDetails.pt || primaryDetails.pt) {
  //     let value = contractDetails.pt || primaryDetails.pt;
  //     if (value.length > 10) {
  //       const exists = await checkDuplicate("ptRegNumber", value);
  //       if (exists && value !== userData.editUser?.pt) {
  //         errorMessages.push(`PT Reg. number - ${value} already exists`);
  //         setError((prev) => ({ ...prev, pt: true }));
  //       } else {
  //         allClear.pt = true;
  //         setError((prev) => ({ ...prev, pt: false }));
  //       }
  //     } else {
  //       errorMessages.push("PT Reg. number must be greater than 10 characters");
  //       setError((prev) => ({ ...prev, pt: true }));
  //     }
  //   }

  //   // TAN Validation
  //   if (contractDetails.tan || primaryDetails.tan) {
  //     let value = contractDetails.tan || primaryDetails.tan;
  //     if (tan.test(value)) {
  //       const exists = await checkDuplicate("tanNumber", value);
  //       if (exists && value !== userData.editUser?.tan) {
  //         errorMessages.push(`TAN number - ${value} already exists`);
  //         setError((prev) => ({ ...prev, tan: true }));
  //       } else {
  //         allClear.tan = true;
  //         setError((prev) => ({ ...prev, tan: false }));
  //       }
  //     } else {
  //       errorMessages.push(`Invalid TAN number: ${value}`);
  //       setError((prev) => ({ ...prev, tan: true }));
  //     }
  //   }

  //   // Add EPF Validation
  //   // if (contractDetails.epf || primaryDetails.epf) {
  //   //   let value = contractDetails.epf || primaryDetails.epf;
  //   //   const exists = await checkDuplicate("epfRegNumber", value);
  //   //   if (exists && value !== userData.editUser?.epf) {
  //   //     errorMessages.push(`EPF Reg. number - ${value} already exists`);
  //   //     setError((prev) => ({ ...prev, epf: true }));
  //   //   } else {
  //   //     allClear.epf = true;
  //   //     setError((prev) => ({ ...prev, epf: false }));
  //   //   }
  //   // }

  //   // // Add ESIC Validation
  //   // if (contractDetails.esic || primaryDetails.esic) {
  //   //   let value = contractDetails.esic || primaryDetails.esic;
  //   //   const exists = await checkDuplicate("esicRegNumber", value);
  //   //   if (exists && value !== userData.editUser?.esic) {
  //   //     errorMessages.push(`ESIC Reg. number - ${value} already exists`);
  //   //     setError((prev) => ({ ...prev, esic: true }));
  //   //   } else {
  //   //     allClear.esic = true;
  //   //     setError((prev) => ({ ...prev, esic: false }));
  //   //   }
  //   // }

  //   // // PAN Validation
  //   // if (contractDetails.pan || primaryDetails.pan) {
  //   //   let value = contractDetails.pan || primaryDetails.pan;
  //   //   const exists = await checkDuplicate("panNumber", value);
  //   //   if (exists && value !== userData.editUser?.pan) {
  //   //     errorMessages.push(`PAN number - ${value} already exists`);
  //   //     setError((prev) => ({ ...prev, pan: true }));
  //   //   } else {
  //   //     allClear.pan = true;
  //   //     setError((prev) => ({ ...prev, pan: false }));
  //   //   }
  //   // }

  //   // Display errors if any
  //   if (errorMessages.length > 0) {
  //     message.destroy("validate");
  //     showErrorModal(errorMessages);
  //     return; // Stop execution if errors exist
  //   }

  //   // Final Validation - Check if all flags are true
  //   if (Object.values(allClear).every(Boolean)) {
  //     message.destroy("validate");
  //     console.log("All validations passed, submitting form...");
  //     handleModalSave(); // Trigger form submission
  //   } else {
  //     message.destroy("validate");
  //     console.log("Validation failed: ", allClear);
  //   }
  // }
  async function validateNumber() {
    let allClear = {
      gstin: false,
      pt: false,
      tan: false,
    };

    const Gstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // Correct regex for GSTIN
    const tan = /^([A-Z]{4}[0-9]{5}[A-Z]{1})$/;

    message.loading({ content: "Validating...", key: "validate", duration: 0 });

    let errorMessages = [];
    const apiURL =
      "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers";

    const checkDuplicate = async (fieldName, value, excludeId) => {
      try {
        const response = await axios.get(apiURL);
        const data = response.data?.data || [];
        return data.some(
          (item) => item[fieldName] === value && item.id !== excludeId
        );
      } catch (error) {
        console.error(`Error checking ${fieldName}:`, error);
        return false;
      }
    };

    const isFieldUpdated = (field, value) =>
      value !== userData.editUser?.[field];

    if (contractDetails.gstin || primaryDetails.gstin) {
      let value = contractDetails.gstin || primaryDetails.gstin;
      if (isFieldUpdated("gstin", value)) {
        if (Gstin.test(value)) {
          const exists = await checkDuplicate("gstinNumber", value, id);
          if (exists) {
            errorMessages.push(`GSTIN number - ${value} already exists`);
            setError((prev) => ({ ...prev, gstin: true }));
          } else {
            allClear.gstin = true;
            setError((prev) => ({ ...prev, gstin: false }));
          }
        } else {
          errorMessages.push(`Invalid GSTIN number: ${value}`);
          setError((prev) => ({ ...prev, gstin: true }));
        }
      } else {
        allClear.gstin = true;
      }
    } else {
      allClear.gstin = true;
    }

    if (contractDetails.pt || primaryDetails.pt) {
      let value = contractDetails.pt || primaryDetails.pt;
      if (isFieldUpdated("pt", value)) {
        if (value.length > 10) {
          const exists = await checkDuplicate("ptRegNumber", value, id);
          if (exists) {
            errorMessages.push(`PT Reg. number - ${value} already exists`);
            setError((prev) => ({ ...prev, pt: true }));
          } else {
            allClear.pt = true;
            setError((prev) => ({ ...prev, pt: false }));
          }
        } else {
          errorMessages.push(
            "PT Reg. number must be greater than 10 characters"
          );
          setError((prev) => ({ ...prev, pt: true }));
        }
      } else {
        allClear.pt = true;
      }
    } else {
      allClear.pt = true;
    }

    if (contractDetails.tan || primaryDetails.tan) {
      let value = contractDetails.tan || primaryDetails.tan;
      if (isFieldUpdated("tan", value)) {
        if (tan.test(value)) {
          const exists = await checkDuplicate("tanNumber", value, id);
          if (exists) {
            errorMessages.push(`TAN number - ${value} already exists`);
            setError((prev) => ({ ...prev, tan: true }));
          } else {
            allClear.tan = true;
            setError((prev) => ({ ...prev, tan: false }));
          }
        } else {
          errorMessages.push(`Invalid TAN number: ${value}`);
          setError((prev) => ({ ...prev, tan: true }));
        }
      } else {
        allClear.tan = true;
      }
    } else {
      allClear.tan = true;
    }

    if (errorMessages.length > 0) {
      message.destroy("validate");
      showErrorModal(errorMessages);
      return;
    }

    if (Object.values(allClear).every(Boolean)) {
      message.destroy("validate");
      console.log("All validations passed, submitting form...");
      handleModalSave();
    } else {
      message.destroy("validate");
      console.log("Validation failed: ", allClear);
    }
  }

  function formSubmit(e) {
    // console.log("formSubmit", e)
    e.preventDefault();
    if (userData?.activeTabIndex === "1") {
      validateNumber();
    } else if (userData?.activeTabIndex === "2") {
      validateNumber();
    } else if (
      userData?.activeTabIndex === "3" ||
      userData?.activeTabIndex === "5" ||
      userData?.activeTabIndex === "4" ||
      userData?.activeTabIndex === "6"
    ) {
      console.log("formSubmit");
      validateNumber();
    }
  }
  const addCompanyDetails = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    setError((pre) => {
      return { ...pre, [field]: false };
    });
    if (userData?.activeTabIndex === "2") {
      setPrimaryDetails((pre) => {
        return { ...pre, [field]: value };
      });
    } else {
      if (field === "company") {
        const selectedCompany = companyTypes.find(
          (companyType) => companyType.id === value
        );
        value = selectedCompany ? selectedCompany.id : "";
      }
      setPrimaryDetails((pre) => {
        return { ...pre, [field]: value };
      });
    }
  };

  const verification = async (e) => {
    e.preventDefault();
    let url = `${functions_url}/verifyCompanyPan`;
    let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    let panNumber, collectionName;

    if (userData?.activeTabIndex === "2") {
      collectionName = "contractors";
      panNumber = contractDetails.pan;
    } else if (userData?.activeTabIndex === "3") {
      collectionName = "primary_employers";
      panNumber = primaryDetails.pan;
    }

    if (!regex.test(panNumber)) {
      setError((pre) => ({ ...pre, pan: true }));
      return;
    }

    try {
      const q = query(
        collection(db, collectionName),
        where("pan_number", "==", panNumber)
      );
      const qRes = await getDocs(q);

      if (panNumber !== userData.editUser?.pan && !qRes.empty) {
        message.error({
          content: `Pan number - ${panNumber} already exists`,
          key: "saving",
        });
        return;
      }

      message.loading({
        content: "Verifying...",
        key: "panVerify",
        duration: 0,
      });

      const response = await axios.post(url, {
        id_number: panNumber,
      });

      const { data, status_code, success, message_code } = response.data.data;

      if (status_code === 200 && success && message_code === "success") {
        //  console.log(data.category);
        // console.log(data.full_name);
        setPanIdName(data.full_name);
        setVerify(true);
        message.success({
          content: "Verified successfully!",
          key: "panVerify",
        });
      } else {
        throw new Error("Verification failed");
      }
    } catch (error) {
      console.error("Verification error:", error);
      message.error({
        content:
          error.response?.status === 500
            ? "Server error. Please try again later."
            : "Verification failed. Please check your PAN and try again.",
        key: "panVerify",
      });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const isEditable = permission?.["Principle Employer"]?.edit;

  return (
    // for contract user
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <span>
        {userType !== "primary" && (
          <div className="flexBox">
            {/* <div>
              <button
                className="btn btn-primary tabBtn"
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  setEditKey(null);
                  handleModal();
                  setUploadErr(false);
                }}
              >
                Add user
              </button>
            </div> */}
          </div>
        )}
      </span>

      <div className="ModalContainer" style={{ width: "100%" }}>
        {(userType === "spectra" && userData.activeTabIndex === "1") ||
        userType === "contract" ? null : (
          <form onSubmit={formSubmit}>
            <h3 className="modalHeader">
              {/* {userData?.activeTabIndex === "5"
                ? editKey
                  ? "Update Primary Employer"
                  : "Add Primary Employer"
                : ""} */}
            </h3>
            <div className="modalFirstLineInput"></div>

            <>
              <div className="Form">
                <div className="Vendor-Registered row">
                  <div className="firstLine half-width">
                    <label className="label_text" htmlFor="name">
                      Registered business name <p style={{ color: "red" }}>*</p>
                    </label>
                    <input
                      required
                      value={primaryDetails.bname}
                      readOnly={
                        permission?.["Principle Employer"]?.edit ? false : true
                      }
                      name="bname"
                      onChange={addCompanyDetails}
                      type="text"
                      className="labelOnBorderInput"
                      placeholder={"Enter"}
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="firstLine half-width">
                    <label className="label_text" htmlFor="name">
                      Company type <p style={{ color: "red" }}>*</p>
                    </label>
                    <select
                      value={primaryDetails.company}
                      required
                      name="company"
                      onChange={addCompanyDetails}
                      className="labelOnBorderInput"
                      disabled={!isEditable}
                      //disabled={permission?.["Principle Employer"]?.edit?false:true}
                    >
                      <option value="" disabled selected hidden>
                        Select company type
                      </option>
                      {companyTypes.map((companyType) => (
                        <option key={companyType.id} value={companyType.id}>
                          {companyType.companyType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="Compay-Address full-width">
                  <label className="label_text" htmlFor="name">
                    Address <p style={{ color: "red" }}>*</p>
                  </label>
                  <input
                    required
                    value={primaryDetails.address}
                    name="address"
                    onChange={addCompanyDetails}
                    type="text"
                    className="labelOnBorderInput"
                    placeholder={"Enter"}
                    disabled={!isEditable}
                    //readOnly={permission?.["Principle Employer"]?.edit?false:true}
                  />
                </div>

                <div className="Pan-Gstin row">
                  <div className="firstLine half-width">
                    <label
                      className="label_text"
                      htmlFor="pan"
                      style={{ display: "flex" }}
                    >
                      PAN <p style={{ color: "red", margin: "0 5px" }}>*</p>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        required
                        style={{
                          borderColor: error.pan ? "red" : "initial",
                          flex: "1", // Allows the input to take available space
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={primaryDetails.pan}
                        name="pan"
                        maxLength="10"
                        onChange={(e) => addCompanyDetails(e)}
                        className="labelOnBorderInput"
                        placeholder="Enter"
                        disabled={!isEditable || verify}
                        //  disabled={verify}
                        //   readOnly={permission?.["Principle Employer"]?.edit?false:true}
                      />
                      <button
                        style={{
                          width: "100px",
                          backgroundColor: verify ? "#34B53A" : "#0361fe",
                          border: "none",
                          color: "white",
                          fontWeight: "500",
                          cursor: "pointer",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                        onClick={(e) => verification(e)}
                        disabled={!isEditable || verify}
                      >
                        {verify && (
                          <span style={{ marginRight: "5px" }}>&#10003;</span>
                        )}
                        {verify ? "Verified" : "Verify"}
                      </button>
                    </div>
                    {error.pan && (
                      <label
                        className="error"
                        style={{ color: "red", marginTop: "5px" }}
                      >
                        *please enter valid pan number
                      </label>
                    )}
                  </div>

                  <div className="firstLine half-width">
                    <label className="label_text" htmlFor="name">
                      GSTIN <p style={{ color: "red" }}>*</p>
                    </label>
                    <input
                      required
                      value={primaryDetails.gstin}
                      name="gstin"
                      maxLength="15"
                      onChange={addCompanyDetails}
                      className="labelOnBorderInput"
                      placeholder={"Enter"}
                      disabled={!isEditable}
                      // readOnly={permission?.["Principle Employer"]?.edit?false:true}
                    />
                  </div>
                </div>

                {/* <div className="Epf-Esic row">
                    <div className="firstLine half-width">
                      <label className="label_text" htmlFor="name">
                        EPF Reg. no <p style={{ color: "red" }}>*</p>
                      </label>
                      <input
                        required
                        value={primaryDetails.epf}
                        name="epf"
                        onChange={addCompanyDetails}
                        className="labelOnBorderInput"
                        placeholder={"Enter"}
                        disabled={!isEditable}
                       // readOnly={permission?.["Principle Employer"]?.edit?false:true}
                      />
                    </div>
                    <div className="firstLine half-width">
                      <label className="label_text" htmlFor="name">
                        ESIC Reg. no <p style={{ color: "red" }}>*</p>
                      </label>
                      <input
                        required
                        value={primaryDetails.esic}
                        name="esic"
                        onChange={addCompanyDetails}
                        className="labelOnBorderInput"
                        placeholder={"Enter"}
                        disabled={!isEditable}
                      //  readOnly={permission?.["Principle Employer"]?.edit?false:true}
                      />
                    </div>
                  </div> */}

                <div className="Pt-Tan row">
                  {/* <div className="firstLine half-width">
                      <label className="label_text" htmlFor="name">
                        PT Reg. no <p style={{ color: "red" }}>*</p>
                      </label>
                      <input
                        required
                        value={primaryDetails.pt}
                        name="pt"
                        onChange={addCompanyDetails}
                        className="labelOnBorderInput"
                        placeholder={"Enter"}
                        disabled={!isEditable}
                      //  readOnly={permission?.["Principle Employer"]?.edit?false:true}
                      />
                    </div> */}
                  <div className="firstLine half-width">
                    <label className="label_text" htmlFor="name">
                      TAN <p style={{ color: "red" }}>*</p>
                    </label>
                    <input
                      required
                      value={primaryDetails.tan}
                      name="tan"
                      onChange={addCompanyDetails}
                      className="labelOnBorderInput"
                      placeholder={"Enter"}
                      disabled={!isEditable}
                      // readOnly={permission?.["Principle Employer"]?.edit?false:true}
                    />
                  </div>
                </div>
                <div className="file-Upload">
                  <div style={{ marginTop: "10px" }}>
                    <label id="" htmlFor="name" className="label_text">
                      Logo <span className="span_first">*</span>
                      <span
                        className={`span_class ${
                          uploadErr ? "span_error" : "span_second"
                        }`}
                      >
                        {" "}
                        png only, size &#60; 200kb
                      </span>
                    </label>
                  </div>

                  <div className="upload_btn" style={{ marginTop: "15px" }}>
                    {!imageValue && (
                      <input
                        type="file"
                        className="input_type_file"
                        name="contractor_user_image"
                        id=""
                        onChange={onImageChange}
                        required
                      />
                    )}
                    {!imageValue && (
                      <div className="upload_container">
                        <div className="upload_content">
                          <p>Upload</p>
                          <img
                            src={upload}
                            className="upload_img"
                            alt=""
                            srcset=""
                          />
                        </div>
                        {uploadErr && (
                          <p className="span_error">
                            Invalid “format” or “size”
                          </p>
                        )}
                      </div>
                    )}
                    {imageValue && (
                      <div className="is_upload_img">
                        <div>
                          {/* Small Image */}
                          <img
                            src={imageValue}
                            alt="preview image"
                            style={{
                              width: "54px",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={openModal}
                          />

                          {/* Modal for Large Image */}
                          {isModalOpen && (
                            <div
                              style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000,
                              }}
                              onClick={closeModal} // Close modal on click outside
                            >
                              <img
                                src={imageValue}
                                alt="full view"
                                style={{
                                  maxWidth: "90%",
                                  maxHeight: "90%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {isEditable && (
                          <img
                            src={cancelBtn}
                            alt=""
                            srcset=""
                            style={{ width: "30px" }}
                            onClick={closeUpoloadImg}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
            {permission?.["Principle Employer"]?.edit && (
              <div className="modalFooter">
                <button className="cancelButton" onClick={handleModalCancel}>
                  Cancel
                </button>
                <button
                  className="saveButton"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      userData.activeTabIndex == "6" ||
                      userData.activeTabIndex == "6"
                        ? verify
                          ? "#0361fe"
                          : "#DEB3FF"
                        : "8F00FF",
                  }}
                  disabled={
                    userData.activeTabIndex == "6" ||
                    userData.activeTabIndex == "6"
                      ? !verify
                      : false
                  }
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
      {/* </Modal> */}
    </div>
  );
};

export default CompanyTabs;
