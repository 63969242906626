import React from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "antd";
import logout from "../assets/icons/navBar_icon/logout.svg";
import { useProvideAuth } from "../hooks/auth";
import { useContext } from "react";
import { dataContext } from "../context/data";
import dashboard_active from "../assets/icons/navBar_icon/dashboard_active_ic.svg";
import dashboard_inactive from "../assets/icons/navBar_icon/dashboard_inactive_ic.svg";
import dashboard_onhover from "../assets/icons/navBar_icon/dashboard_onhover_ic.svg";
import userlist_onhover from "../assets/icons/navBar_icon/userlist_onhover_ic.svg";
import job_post_onhover from "../assets/icons/navBar_icon/job_post_onhover_ic.svg";
import userlist_active from "../assets/icons/navBar_icon/userlist_active_ic.svg";
import userlist_inactive from "../assets/icons/navBar_icon/userlist_inactive_ic.svg";
import job_post_active from "../assets/icons/navBar_icon/job_post_active_ic.svg";
import job_post_inactive from "../assets/icons/navBar_icon/job_post_inactive_ic.svg";
import request_inactive from "../assets/icons/navBar_icon/request_inactive.svg";
import request_active from "../assets/icons/navBar_icon/request_active.svg";
import request_onhover from "../assets/icons/navBar_icon/request_onhover.svg";
import logOut_onhover from "../assets/icons/navBar_icon/logOut_onhover_ic.svg";
import { WhatsAppOutlined } from "@ant-design/icons";
import {
  KeyOutlined,
  LockOutlined,
  UnlockOutlined,
  UserSwitchOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

import roleImg from "../assets/icons/navBar_icon/user.png";
import "../styles/TopBar.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function TopBar({ userPath }) {
  const [condtionOne, setConditionOne] = useState(true);
  const [condtionTwo, setConditionTwo] = useState(false);
  const [condtionThree, setConditionThree] = useState(false);
  const [conditonFour, setConditionFour] = useState(false);
  const [img1, setImg1] = useState(dashboard_active);
  const [img2, setImg2] = useState(userlist_inactive);
  const [img3, setImg3] = useState(job_post_inactive);
  const [img4, setImg4] = useState(request_inactive);
  const [logoutIcon, setLogoutIcon] = useState(logout);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const { setDashboardData, setNavList, navList } = useContext(dataContext);
  const location = useLocation();
  const isActive = location.pathname === `/${userPath}/whatsapp`;
  const isActiveRole = location.pathname === `/${userPath}/role`;

  const history = useHistory();
  const auth = useProvideAuth();
  // const logOut = () => {
  //     auth.signOut();
  //     history.replace('/');
  // }

  let { userStatus } = useSelector((state) => state?.user.permission);

  const handleLogoutClick = () => {
    setIsLogoutModalVisible(true);
  };

  const handleLogoutConfirm = () => {
    auth.signOut();
    history.replace("/");
    setIsLogoutModalVisible(false);
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalVisible(false);
  };

  useEffect(() => {
    if (
      navList.dashBoard === true &&
      navList.userList === false &&
      navList.jobPost === false &&
      navList.requests === false &&
      navList.whatsapp === false &&
      navList.role === false
    ) {
      setImg1(dashboard_active);
      setImg2(userlist_inactive);
      setImg3(job_post_inactive);
      setImg4(request_inactive);
      setConditionOne(true);
      setConditionTwo(false);
      setConditionThree(false);
      setConditionFour(false);
      setDashboardData((pre) => {
        return { ...pre, DblastFetch: undefined, page: 0 };
      });
    } else if (
      navList.dashBoard === false &&
      navList.userList === true &&
      navList.jobPost === false &&
      navList.requests === false &&
      navList.whatsapp === false &&
      navList.role === false
    ) {
      setImg1(dashboard_inactive);
      setImg2(userlist_active);
      setImg3(job_post_inactive);
      setImg4(request_inactive);
      setConditionTwo(true);
      setConditionThree(false);
      setConditionOne(false);
      setConditionFour(false);
    } else if (
      navList.dashBoard === false &&
      navList.userList === false &&
      navList.jobPost === true &&
      navList.requests === false &&
      navList.whatsapp === false &&
      navList.role === false
    ) {
      setImg1(dashboard_inactive);
      setImg2(userlist_inactive);
      setImg3(job_post_active);
      setImg4(request_inactive);
      setConditionThree(true);
      setConditionTwo(false);
      setConditionOne(false);
      setConditionFour(false);
    } else if (
      navList.dashBoard === false &&
      navList.userList === false &&
      navList.jobPost === false &&
      navList.requests === true &&
      navList.whatsapp === false &&
      navList.role === false
    ) {
      setImg1(dashboard_inactive);
      setImg2(userlist_inactive);
      setImg3(job_post_inactive);
      setImg4(request_active);
      setConditionThree(false);
      setConditionTwo(false);
      setConditionOne(false);
      setConditionFour(true);
    } else if (
      navList.dashBoard === false &&
      navList.userList === false &&
      navList.jobPost === false &&
      navList.requests === false &&
      navList.whatsapp === true &&
      navList.role === false
    ) {
      setImg1(dashboard_inactive);
      setImg2(userlist_inactive);
      setImg3(job_post_inactive);
      setImg4(request_inactive);
      setConditionThree(false);
      setConditionTwo(false);
      setConditionOne(false);
      setConditionFour(false);
    }
  }, [navList]);

  const handleMouseOverOne = () => {
    if (condtionOne) {
      setImg1(dashboard_active);
    } else {
      setImg1(dashboard_onhover);
    }
  };

  const handleMouseOverTwo = () => {
    if (condtionTwo) {
      setImg2(userlist_active);
    } else {
      setImg2(userlist_onhover);
    }
  };
  const handleMouseOverThree = () => {
    if (condtionThree) {
      setImg3(job_post_active);
    } else {
      setImg3(job_post_onhover);
    }
  };
  const handleMouseOverFour = () => {
    if (conditonFour) {
      setImg4(request_active);
    } else {
      setImg4(request_onhover);
    }
  };

  const handleMouseOutOne = () => {
    if (condtionOne) {
      setImg1(dashboard_active);
    } else {
      console.log("object");
      setImg1(dashboard_inactive);
    }
  };

  const handleMouseOutTwo = () => {
    if (condtionTwo) {
      setImg2(userlist_active);
    } else {
      setImg2(userlist_inactive);
    }
  };
  const handleMouseOutThree = () => {
    if (condtionThree) {
      setImg3(job_post_active);
    } else {
      setImg3(job_post_inactive);
    }
  };

  const handleMouseOutFour = () => {
    if (conditonFour) {
      setImg4(request_active);
    } else {
      setImg4(request_inactive);
    }
  };

  let [userMenu, setUserMenu] = useState({
    user: false,
    job: false,
    role: false,
  });

  let findMainMenuPermission = (permission) => {
    const hasUserAccess = [
      "Talent",
      "Bizops",
      "Contractors",
      "Principle Employer",
    ].some((key) =>
      ["read", "write", "edit", "delete"].some(
        (perm) => permission[key]?.[perm]
      )
    );

    const hasJobAccess = ["read", "write", "edit", "delete"].some(
      (perm) => permission["Job Post"]?.[perm]
    );

    const roleAccess = ["read", "write", "edit", "delete"].some(
      (perm) => permission["Role Management"]?.[perm]
    );

    setUserMenu((prev) => {
      return {
        ...prev,
        user: hasUserAccess,
        job: hasJobAccess,
        role: roleAccess,
      };
    });
  };

  let permission = useSelector(
    (state) => state?.user?.permission?.getUserRole?.permission
  );

  useEffect(() => {
    if (permission && Object.keys(permission).length) {
      findMainMenuPermission(permission);
    }
  }, [permission]);

  return (
    <div className="topBar">
      <div className="topBox">
        <div className="title" style={{ color: "#0361fe" }}>
          TALNTX
        </div>
        <div className="navBar">
          <Link to={"/" + userPath}>
            <img
              className="img1"
              src={img1}
              onMouseOver={handleMouseOverOne}
              onMouseOut={handleMouseOutOne}
              width="40px"
              height="auto"
            />
          </Link>
          {userMenu.user && userStatus !== "In Active" && (
            <Link to={"/" + userPath + "/userPanel"}>
              <img
                className="img2"
                src={img2}
                onMouseOver={handleMouseOverTwo}
                onMouseOut={handleMouseOutTwo}
                width="40px"
                height="auto"
              />
            </Link>
          )}
          {userMenu.job && userStatus !== "In Active" && (
            <Link to={"/" + userPath + "/jobpost"}>
              <img
                className="img3"
                src={img3}
                onMouseOver={handleMouseOverThree}
                onMouseOut={handleMouseOutThree}
                width="40px"
                height="auto"
              />
            </Link>
          )}
          {/* <Link to={"/" + userPath + "/requests"}>
            <img
              className="img3"
              src={img4}
              onMouseOver={handleMouseOverFour}
              onMouseOut={handleMouseOutFour}
              width="40px"
              height="auto"
            />
          </Link> */}
          {userMenu?.role && userStatus !== "In Active" && (
            <Link to={"/" + userPath + "/role"}>
              <UserSwitchOutlined
                onClick={() => {
                  setImg1(dashboard_inactive);
                  setImg2(userlist_inactive);
                  setImg3(job_post_inactive);
                  setImg4(request_inactive);
                  setConditionThree(false);
                  setConditionTwo(false);
                  setConditionOne(false);
                  setConditionFour(false);
                }}
                style={{
                  fontSize: "24px",
                  color: isActiveRole ? "#1890ff" : "#999",
                  cursor: "pointer",
                  backgroundColor: isActiveRole
                    ? "rgba(24, 144, 255, 0.1)"
                    : null,
                  padding: "5px",
                  borderRadius: "5px",
                }}
              />
            </Link>
          )}
          {userStatus !== "In Active" && (
            <Link to={"/" + userPath + "/whatsapp"}>
              <WhatsAppOutlined
                style={{
                  fontSize: "24px",
                  color: isActive ? "#25D366" : "#999",
                  transition: "color 0.3s",
                  cursor: "pointer",
                  marginTop: 28,
                }}
              />
            </Link>
          )}
        </div>
      </div>
      <div className="topBox">
        <img
          onClick={handleLogoutClick}
          src={logoutIcon}
          onMouseOver={() => setLogoutIcon(logOut_onhover)}
          onMouseOut={() => setLogoutIcon(logout)}
          width="40px"
          height="auto"
          alt="logout"
        />
      </div>

      <Modal
        title="Confirm Logout"
        visible={isLogoutModalVisible}
        onOk={handleLogoutConfirm}
        onCancel={handleLogoutCancel}
        okText="Logout"
        cancelText="Cancel"
        width={400}
        bodyStyle={{ textAlign: "center", marginBottom: "-20px" }} // Adjust margin to move buttons up
      >
        <p
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
        >
          Are you sure you want to log out?
        </p>
      </Modal>
    </div>
  );
}

export default TopBar;
