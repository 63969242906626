// import React from "react";
// import {
//   Card,
//   Row,
//   Col,
//   Typography,
//   Statistic,
//   Space,
//   Progress,
//   Divider,
// } from "antd";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   ReferenceLine,
// } from "recharts";
// import { UserAddOutlined, TeamOutlined, RiseOutlined } from "@ant-design/icons";

// const { Title, Text } = Typography;

// const TalentAnalyticsDashboard = ({ data:FS, user }) => {
//   const data = {
//     data: {
//       assignedTalentsCountBasedOnPE: [
//         {
//           date: "Feb 2025",
//           "No of Associates on-boarded with the particular PE": "2",
//           Resigned: "2",
//         },
//         {
//           date: "Mar 2025",
//           "No of Associates on-boarded with the particular PE": "1",
//           Resigned: "4",
//         },
//         {
//           date: "Apr 2025",
//           "No of Associates on-boarded with the particular PE": "4",
//           Resigned: "2",
//         }
//       ],
//     },
//   };
//   const additionHead =
//     user === "Employeer"
//       ? "No of Associates on-boarded with the particular PE"
//       : "Talents Added";
//   const resignHead = user === "Employeer" ? "Resigned" : "Associates Assigned";

//   // Calculate summary metrics
//   const totalTalents = data?.data?.assignedTalentsCountBasedOnPE?.reduce(
//     (sum, item) => sum + Number(item[additionHead] || 0),
//     0
//   );

//   const totalAssociates = data?.data?.assignedTalentsCountBasedOnPE?.reduce(
//     (sum, item) => sum + Number(item[resignHead] || 0),
//     0
//   );

//   const assignmentRate = Number(totalTalents) && Number(totalAssociates)
//   ? Math.round((Number(totalAssociates) / Number(totalTalents)) * 100)
//   : 0;

//   // Custom tooltip component
//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       return (
//         <Card size="small" style={{ border: "1px solid #f0f0f0" }}>
//           <Text strong>{label}</Text>
//           <Divider style={{ margin: "8px 0" }} />
//           {payload.map((entry, index) => (
//             <div key={index}>
//               <Text style={{ color: entry.color, marginRight: 8 }}>
//                 {entry.name}: {entry.value}
//               </Text>
//             </div>
//           ))}
//         </Card>
//       );
//     }
//     return null;
//   };

//   return (
//     <Card style={{ borderRadius: 8, boxShadow: "0 2px 8px rgba(0,0,0,0.09)", marginBottom: 24 }}>
//       <Space direction="vertical" size="large" style={{ width: "100%" }}>
//         <Row gutter={[16, 16]} align="middle" justify="space-between">
//           <Col>
//             <Title level={4} style={{ marginBottom: 0 }}>
//               Talent Acquisition Analytics
//             </Title>
//             <Text type="secondary">
//               Performance overview of talent acquisition efforts
//             </Text>
//           </Col>
//         </Row>

//         {/* Summary Metrics */}
//         <Row gutter={[16, 16]}>
//           <Col xs={24} md={8}>
//             <Card style={{ backgroundColor: "#f0f5ff", borderRadius: 8 }}>
//               <Statistic
//                 title={
//                   user === "Employeer"
//                     ? "Total No of Associates On-boarded"
//                     : "Total Talents Added"
//                 }
//                 value={totalTalents}
//                 prefix={<UserAddOutlined />}
//                 valueStyle={{ color: "#1890ff" }}
//               />
//               <Text type="secondary">YTD</Text>
//             </Card>
//           </Col>

//           <Col xs={24} md={8}>
//             <Card style={{ backgroundColor: "#f6ffed", borderRadius: 8 }}>
//               <Statistic
//                 title={
//                   user === "Employeer"
//                     ? "Total Resigned"
//                     : "Total Associates Assigned"
//                 }
//                 value={totalAssociates}
//                 prefix={<TeamOutlined />}
//                 valueStyle={{ color: "#52c41a" }}
//               />
//               <Text type="secondary">YTD</Text>
//             </Card>
//           </Col>

//           <Col xs={24} md={8}>
//             <Card style={{ backgroundColor: "#fff7e6", borderRadius: 8 }}>
//               <Statistic
//                 title="Assignment Rate"
//                 value={assignmentRate}
//                 suffix="%"
//                 prefix={<RiseOutlined />}
//                 valueStyle={{ color: "#fa8c16" }}
//               />
//               <Text type="secondary">YTD</Text>
//             </Card>
//           </Col>
//         </Row>

//         {/* Area Chart */}
//         <Row gutter={[16, 16]}>
//           <Col xs={24} lg={16}>
//             <Card
//               title="Talent Acquisition Trends"
//               bodyStyle={{ padding: "12px 0" }}
//             >
//               <div style={{ height: 320, width: "100%" }}>
//                 <ResponsiveContainer>
//                   <AreaChart
//                     data={data?.data?.assignedTalentsCountBasedOnPE}
//                     margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
//                   >
//                     <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
//                     <XAxis
//                       dataKey="date"
//                       axisLine={{ stroke: "#f0f0f0" }}
//                       tickLine={false}
//                     />
//                     <YAxis axisLine={{ stroke: "#f0f0f0" }} tickLine={false} />
//                     <Tooltip content={<CustomTooltip />} />
//                     <Legend wrapperStyle={{ paddingTop: 10 }} />
//                     <ReferenceLine
//                       y={60}
//                       stroke="#faad14"
//                       strokeDasharray="3 3"
//                       label="Target"
//                     />
//                     <Area
//                       type="monotone"
//                       dataKey={additionHead}
//                       stackId="1"
//                       stroke="#1890ff"
//                       fill="#1890ff"
//                       fillOpacity={0.6}
//                     />
//                     <Area
//                       type="monotone"
//                       dataKey={resignHead}
//                       stackId="2"
//                       stroke="#52c41a"
//                       fill="#52c41a"
//                       fillOpacity={0.6}
//                     />
//                   </AreaChart>
//                 </ResponsiveContainer>
//               </div>
//             </Card>
//           </Col>
//         </Row>
//       </Space>
//     </Card>
//   );
// };

// export default TalentAnalyticsDashboard;

import React from "react";
import { Card, Row, Col, Typography, Statistic, Space, Divider } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { UserAddOutlined, TeamOutlined, RiseOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const TalentAnalyticsDashboard = ({ data, user }) => {
  // const data = {
  //   data: {
  //     assignedTalentsCountBasedOnPE: [
  //       {
  //         date: "Feb 2025",
  //         "No of Associates on-boarded with the particular PE": "2",
  //         Resigned: "2",
  //       },
  //       {
  //         date: "Mar 2025",
  //         "No of Associates on-boarded with the particular PE": "1",
  //         Resigned: "4",
  //       },
  //       {
  //         date: "Apr 2025",
  //         "No of Associates on-boarded with the particular PE": "4",
  //         Resigned: "2",
  //       },
  //     ],
  //   },
  // };

  const additionHead =
    user === "Employeer"
      ? "No of Associates on-boarded with the particular PE"
      : "Talents Added";
      console.log(additionHead, "additionHead")
  const resignHead = user === "Employeer" ? "Resigned" : "Associates Assigned With PE";

  const calculateMonthlyAttritionRate =
    data?.data?.[user === "Employeer" ? 'assignedTalentsCountBasedOnPE' : 'dateBasedTalentsCount']?.map((item, index, array) => {
      const startingEmployees =
        index === 0
          ? 100
          : Number(array[index - 1][additionHead]) +
            Number(array[index - 1][resignHead]);
      const endingEmployees =
        Number(item[additionHead]) + Number(item[resignHead]);
      const avgEmployees = (startingEmployees + endingEmployees) / 2;
      const attritionRate = avgEmployees
        ? ((Number(item[resignHead]) / avgEmployees) * 100).toFixed(1)
        : 0;
      return { ...item, AttritionRate: attritionRate };
    });

  const totalResigned = data?.data?.[user === "Employeer" ? 'assignedTalentsCountBasedOnPE' : 'dateBasedTalentsCount']?.reduce(
    (sum, item) => sum + Number(item[resignHead] || 0),
    0
  );

  const totalTalents = data?.data?.[user === "Employeer" ? 'assignedTalentsCountBasedOnPE' : 'dateBasedTalentsCount']?.reduce(
    (sum, item) => sum + Number(item[additionHead] || 0),
    0
  );

  const attritionRateYTD =
    totalResigned && totalTalents
      ? ((totalResigned / ((100 + 90) / 2)) * 100).toFixed(1)
      : 0;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card size="small" style={{ border: "1px solid #f0f0f0" }}>
          <Text strong>{label}</Text>
          <Divider style={{ margin: "8px 0" }} />
          {payload.map((entry, index) => (
            <div key={index}>
              <Text style={{ color: entry.color, marginRight: 8 }}>
                {entry.name}: {entry.value}
              </Text>
            </div>
          ))}
        </Card>
      );
    }
    return null;
  };

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 2px 8px rgba(0,0,0,0.09)",
        marginBottom: 24,
      }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col>
            <Title level={4} style={{ marginBottom: 0 }}>
              Talent Acquisition Analytics
            </Title>
            <Text type="secondary">
              Performance overview of talent acquisition efforts
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card style={{ backgroundColor: "#f0f5ff", borderRadius: 8 }}>
              <Statistic
                title="Total Talents Added"
                value={totalTalents}
                prefix={<UserAddOutlined />}
                valueStyle={{ color: "#1890ff" }}
              />
              <Text type="secondary">YTD</Text>
            </Card>
          </Col>

          <Col xs={24} md={8}>
            <Card style={{ backgroundColor: "#f6ffed", borderRadius: 8 }}>
              <Statistic
                title="Total Resigned"
                value={totalResigned}
                prefix={<TeamOutlined />}
                valueStyle={{ color: "#52c41a" }}
              />
              <Text type="secondary">YTD</Text>
            </Card>
          </Col>

          <Col xs={24} md={8}>
            <Card style={{ backgroundColor: "#fff7e6", borderRadius: 8 }}>
              <Statistic
                title="Attrition Rate"
                value={attritionRateYTD}
                suffix="%"
                prefix={<RiseOutlined />}
                valueStyle={{ color: "#fa8c16" }}
              />
              <Text type="secondary">YTD</Text>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} >
          <Col xs={24} lg={24}>
            <Card title="Attrition Trends" bodyStyle={{ padding: "12px 0" }}>
              <div style={{ height: 320, width: "100%" }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={calculateMonthlyAttritionRate}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis
                      dataKey="date"
                      axisLine={{ stroke: "#f0f0f0" }}
                      tickLine={false}
                    />
                    <YAxis axisLine={{ stroke: "#f0f0f0" }} tickLine={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={{ paddingTop: 10 }} />
                    <Area
                      type="monotone"
                      dataKey="AttritionRate"
                      stackId="1"
                      stroke="#fa8c16"
                      fill="#fa8c16"
                      fillOpacity={0.6}
                    />
                    <Area
                      type="monotone"
                      dataKey={additionHead}
                      stackId="1"
                      stroke="#1890ff"
                      fill="#1890ff"
                      fillOpacity={0.6}
                    />
                    <Area
                      type="monotone"
                      dataKey={resignHead}
                      stackId="2"
                      stroke="#52c41a"
                      fill="#52c41a"
                      fillOpacity={0.6}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default TalentAnalyticsDashboard;
