import { Modal, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import { fetchMenuOption, updateRoleCreate, fetchEachRole } from "./api";
import { fetchData, fetchDataEachRow } from "../../backendApi";

const { Option } = Select;

function NewRolePopUp({
  isVisible,
  roleId,
  onSave,
  onCancel,
  initialValues,
  closePopup,
  form,
}) {
  const [currentRole, setCurrentRole] = useState({});
  // const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFieldsChange = () => {
    const hasErrors = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    const allFieldsTouched = form.isFieldsTouched(true);
    setIsButtonDisabled(hasErrors || !allFieldsTouched);
  };

  const fetchMenu = async function () {
    const res = await fetchData("role/menuList");
    if (res?.data?.data) {
      setMenu(res.data.data);
    }
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  useEffect(() => {
    if (roleId) {
      getCurrentRole(roleId);
    }
  }, [roleId]);

  async function getCurrentRole(roleId) {
    const currentRole = await fetchEachRole(`/role/${roleId}`);
    if (currentRole?.data?.status === 200) {
      form.setFieldsValue(currentRole?.data?.data);
    }
  }
  return (
    <Modal
      visible={isVisible}
      title={roleId ? "Update Role" : "Create New Role"}
      okText="Next"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        closePopup();
        if (onCancel) onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            closePopup();
            if (onSave) onSave(values);
          })
          .catch((info) => {
            console.error("Validation Failed:", info);
          });
      }}
      okButtonProps={{ disabled: isButtonDisabled }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={currentRole}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item
          name="roleName"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input the title of collection!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="menuId"
          label="Applicable To"
          rules={[
            {
              required: true,
              message: "Please select an option!",
            },
          ]}
          style={{ marginTop: "4%" }}
        >
          <Select placeholder="Choose an option" mode="multiple" allowClear>
            {menu.length > 0 &&
              menu.map((ele) => (
                <Option value={`${ele.id}`} id={ele.id}>
                  {ele.menuName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label="Select Status"
          rules={[
            {
              required: true,
              message: "Please select an option!",
            },
          ]}
          style={{ marginTop: "4%" }}
        >
          <Select placeholder="Choose an option">
            <Option value="Active">Active</Option>
            <Option value="In Active">In Active</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewRolePopUp;
