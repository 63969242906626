import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spin } from "antd";
import axios from "axios";
import sortIcon from "../../../assets/icons/sort.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { render } from "@testing-library/react";
import { useSelector } from "react-redux";
import TalentRemoveModal from "./TalentRemove";
import deleteIcon from "../../../assets/icons/icons8-delete.svg";
import editIcon from "../../../assets/icons/icons8-edit.svg";
import DeletePostButton from "../../../assets/icons/deletePostBtn.svg";
import { deletePost, createPost } from "../../../backendApi";
import DeletePopup from "./DeletePopup";
import EmployeeEditPopup from "./EmployeeEditPopup";

const EmployeesTabs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);
  const [from, setFrom] = useState(0);
  const { id } = useLocation().state;
  const [detachTalents, setDetachTalents] = useState({
    detached: [],
    isDetachBtn: false,
  });

  const [isDetachedPopup, setIsDetachedPopup] = useState(false);
  const [editedRow, setEditedRow] = useState({
    isEdited: false,
    row: {},
  });
  const [deleteData, setDeleteData] = useState({
    isDeleted: false,
    row: {},
  });

  const pageSize = 10;

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  const columns = [
    { title: "", dataIndex: "", sort: false },
    ...(permission?.["Talent Assignment"]?.read &&
    permission?.["Talent Assignment"]?.write
      ? [
          {
            title: "Remove Talent",
            sort: false,
            dataIndex: "removeTalent",
            position: 1,
          },
        ]
      : []),

    { title: "Name", dataIndex: "fullName", sort: true },
    { title: "Mobile", dataIndex: "mobileNumber", sort: false },
    { title: "State", dataIndex: "state", sort: true },
    { title: "Role", dataIndex: "role", sort: false },
    { title: "Date Of Joining", dataIndex: "dateOfJoin", sort: false },
    { title: "Date Of Exit", dataIndex: "dateOfExit", sort: false },
    { title: "Reason", dataIndex: "reason", sort: false },
    ...(permission?.["Talent Assignment"]?.delete
      ? [
          {
            title: "Actions",
            sort: false,
            dataIndex: "action",
            position: 1,
          },
        ]
      : []),
  ];

  const fetchMoreData = async () => {
    try {
      const response = await createPost("/dashboard", {
        from,
        size: pageSize,
        type: "initial",
        sortColumn: null,
        primaryEmployeerId: [id],
      });
      const fetchedData = response?.data?.data || [];

      const uniqueData = fetchedData.filter(
        (item) => !data.some((existingItem) => existingItem.id === item.id)
      );
      setData((prev) => [...prev, ...uniqueData]);
      setFrom((prev) => prev + pageSize);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setIsLoading(false);
    }
  };

  const handleSort = (dataIndex) => {
    const sortedData = [...data];
    const isAscending = sortOrder === "asc";

    sortedData.sort((a, b) => {
      if (a[dataIndex] < b[dataIndex]) return isAscending ? 1 : -1;
      if (a[dataIndex] > b[dataIndex]) return isAscending ? -1 : 1;
      return 0;
    });

    setSortOrder(isAscending ? "desc" : "asc");
    setData(sortedData);
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const handleChange = (e, row) => {
    const { checked } = e.target;

    setDetachTalents((prev) => {
      let updatedDetached = [...prev.detached];
      const existingIndex = updatedDetached.findIndex(
        (item) => item.id === row.id
      );

      if (checked) {
        if (existingIndex !== -1) {
          updatedDetached[existingIndex] = {
            ...updatedDetached[existingIndex],
            isChecked: true,
            dateOfJoin: row.dateOfJoin,
          };
        } else {
          updatedDetached.push({
            id: row.id,
            isChecked: true,
            dateOfJoin: row.dateOfJoin,
          });
        }
      } else {
        updatedDetached = updatedDetached.filter((item) => item.id !== row.id);
      }

      const isDetachBtn = updatedDetached.some((item) => item.isChecked);

      return {
        ...prev,
        detached: updatedDetached,
        isDetachBtn,
      };
    });
  };

  const closeTalentHandler = () => {
    setIsDetachedPopup(false);
  };

  const updateTalentEmployeeData = (updatedData) => {
    let updatedState = detachTalents.detached.map((ele) => {
      updatedData.forEach((d) => {
        if (d.id !== ele.id) {
          ele.isChecked = false;
          ele.dateOfExist = d.dateOfLeave;
        }
      });
      return ele;
    });

    setDetachTalents((prev) => ({
      ...prev,
      detached: updatedState,
      isDetachBtn: false,
    }));

    let res = data.filter((ele) => {
      const istTimestamp = new Date();
      const istOffset = 5.5 * 60 * 60 * 1000;
      istTimestamp.setTime(istTimestamp.getTime() + istOffset);
      const todayIST = istTimestamp.toISOString().split("T")[0];
      let shouldRemove = false;

      updatedData.forEach((d) => {
        if (d.talntId !== ele.id) return;
        const [day, month, year] = d.dateOfLeave.split("/").map(Number);
        const formattedDateOfLeave = `${year}-${String(month).padStart(
          2,
          "0"
        )}-${String(day).padStart(2, "0")}`;

        if (formattedDateOfLeave === todayIST) {
          ele["reason"] = d?.reason;
          ele["dateOfExit"] = d?.dateOfLeave;
        } else {
          shouldRemove = true;
        }
      });

      return !shouldRemove;
    });    

    setData(res);
  };

  const deleteEmployeerPopup = (isDeleted, row) => {
    setDeleteData((prev) => ({
      ...prev,
      isDeleted: true,
      row: row,
    }));
  };

  const deleteEmployeer = async (row) => {
    try {
      const res = await deletePost(
        `/dashboard/${row.NewTalntUser.id}/${row.id}`
      );
      if (res.status === 200) {
        setDetachTalents((prev) => {
          let updatedDetached = prev.detached.filter(
            (item) => item.id !== row.id
          );
          return { ...prev, detached: updatedDetached };
        });
        let res = data.filter((ele) => {
          if (row.id !== ele.id) {
            return ele;
          }
        });
        setData(res);
        setDeleteData((prev) => ({
          ...prev,
          isDeleted: false,
          row: {},
        }));
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeDeleteTalentHandler = () => {
    setDeleteData((prev) => ({
      ...prev,
      isDeleted: false,
    }));
  };

  const closeEditAssignTalentHandler = () => {
    setEditedRow((prev) => ({
      ...prev,
      isEdited: false,
    }));
  };

  function updatedRemovedDispatchDate(row) {
    setDetachTalents((prev) => {
      return {
        ...prev,
        detached: prev.detached.map((ele) => {
          if (row.id === ele.id) {
            return { ...ele, dateOfExist: null };
          }
          return ele;
        }),
      };
    });
  }

  return (
    <div className="responsiveTable">
      {detachTalents.isDetachBtn && (
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "1%" }}
        >
          <button
            className="btn btn-primary tabBtn"
            onClick={() => {
              setIsDetachedPopup(true);
            }}
          >
            Detach Talent
          </button>
        </div>
      )}

      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={true}
        scrollableTarget="respTableScroll"
      >
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : data.length === 0 ? (
          <div className="noDataFound">
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                fontSize: "24px",
                color: "#777",
                alignItems: "center",
              }}
            >
              No Data Found
            </h3>
          </div>
        ) : (
          <div
            className="tableWraper"
            id="respTableScroll"
            style={{ paddingTop: "2rem" }}
          >
            <table>
              <thead>
                <tr>
                  {columns.map((field, i) => (
                    <th key={i} className="field head">
                      {field.title}{" "}
                      {field.sort && (
                        <img
                          style={{ cursor: "pointer" }}
                          width="15px"
                          height="15px"
                          onClick={() => handleSort(field.dataIndex)}
                          src={sortIcon}
                          alt="sort"
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      {columns.map((field, i) => {
                        const isChecked = detachTalents?.detached?.find(
                          (ele) => ele.id === item.id
                        );
                        return (
                          <>
                            <td key={i}>
                              {field.dataIndex === "removeTalent" && (
                                <input
                                  type="checkbox"
                                  checked={isChecked?.isChecked ? true : false}
                                  disabled={
                                    (item?.dateOfLeave &&
                                      item?.dateOfLeave !== "N/A") ||
                                    (isChecked?.dateOfExist &&
                                      isChecked?.dateOfExist !== "N/A")
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleChange(e, item)}
                                />
                              )}
                              {field?.dataIndex === "action" &&
                              permission?.["Talent Assignment"]?.delete ? (
                                <div style={{ display: "flex", gap: "5%" }}>
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "15%",
                                    }}
                                    onClick={() => {
                                      {
                                        setDetachTalents((prev) => ({
                                          ...prev,
                                          detached: [],
                                          isDetachBtn: false,
                                        }));
                                        deleteEmployeerPopup(true, item);
                                      }
                                    }}
                                    className="logo_img"
                                    src={deleteIcon}
                                    alt="deleteIcon"
                                  />
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "15%",
                                    }}
                                    onClick={() => {
                                      console.log(detachTalents);
                                      setDetachTalents((prev) => ({
                                        ...prev,
                                        detached: [],
                                        isDetachBtn: false,
                                      }));
                                      setEditedRow((prev) => ({
                                        ...prev,
                                        isEdited: true,
                                        row: item,
                                      }));
                                    }}
                                    className="logo_img"
                                    src={editIcon}
                                    alt="deleteIcon"
                                  />
                                </div>
                              ) : field.dataIndex === "dateOfJoin" &&
                                item[field.dataIndex] === "N/A" ? (
                                "N/A"
                              ) : field.dataIndex === "dateOfJoin" &&
                                item[field.dataIndex] !== "N/A" ? (
                                item[field.dataIndex]
                              ) : (
                                item[field.dataIndex]
                              )}
                            </td>

                            {/* <td key={i}>delete</td> */}
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </InfiniteScroll>
      <EmployeeEditPopup
        isEdited={editedRow.isEdited}
        row={editedRow?.row}
        closeEditAssignTalentHandler={closeEditAssignTalentHandler}
        setDetachTalents={setDetachTalents}
        setData={setData}
        data={data}
        setEditedRow={setEditedRow}
        detachTalents={detachTalents}
        updatedRemovedDispatchDate={updatedRemovedDispatchDate}
      />

      <TalentRemoveModal
        isDetached={isDetachedPopup}
        closeTalentHandler={closeTalentHandler}
        detachTalents={detachTalents.detached}
        updateTalentEmployeeData={updateTalentEmployeeData}
      />

      {deleteData?.isDeleted && (
        <DeletePopup
          isDeleted={deleteData?.isDeleted}
          row={deleteData?.row}
          deleteEmployeer={deleteEmployeer}
          closeDeleteTalentHandler={closeDeleteTalentHandler}
        />
      )}
    </div>
  );
};

export default EmployeesTabs;