import { useState } from "react";
import { Table, Modal, Typography, Tag, Input } from "antd";
import dayjs from "dayjs";

const { Text } = Typography;
const { Search } = Input;

export const MessageHistoryTable = ({ whatsUpStoredData }) => {
  const [isRecipientModalVisible, setIsRecipientModalVisible] = useState(false);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [recipientSearchText, setRecipientSearchText] = useState("");

  const showRecipientDetails = (recipients_list) => {
    setSelectedRecipients(recipients_list);
    setIsRecipientModalVisible(true);
  };

  const showFullMessage = (message) => {
    setSelectedMessage(message);
    setIsMessageModalVisible(true);
  };

  const recipientCall = async (recipients_list) => {
    showRecipientDetails(recipients_list);
  };

  const filteredData = whatsUpStoredData?.filter(
    (item) =>
      item.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
      item.message.toLowerCase().includes(searchText.toLowerCase())
    // item.role.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredRecipients = selectedRecipients?.filter(
    (recipient) =>
      recipient.fullName
        .toLowerCase()
        .includes(recipientSearchText.toLowerCase()) ||
      recipient.talntCode.includes(recipientSearchText)
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <Text color="blue">{dayjs(text).format("DD-MMM-YYYY")}</Text>
      ),
    },
    {
      title: "Message Owner",
      dataIndex: "owner",
      key: "owner",
      render: (text) => <Text style={{ color: "#333" }}>{text}</Text>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <Text style={{ color: "#333" }}>{text}</Text>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => (
        <Text
          ellipsis
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => showFullMessage(text)}
        >
          {text.length > 50 ? text.substring(0, 50) + "..." : text}
        </Text>
      ),
    },
    {
      title: "Recipients",
      dataIndex: "recipients_list",
      key: "recipients_list",
      render: (recipients_list) => (
        <Text
          style={{ color: "#52c41a", cursor: "pointer" }}
          onClick={() => recipientCall(recipients_list)}
        >
          View Recipients
        </Text>
      ),
    },
  ];

  return (
    <>
      <Search
        placeholder="Search by Owner or Message"
        value={searchText} 
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16, width: 300 }}
        enterButton={true}
      />
      <Table
        columns={columns}
        dataSource={filteredData?.map((item, index) => ({
          key: index,
          s_no: index + 1,
          created_at: item.createdAt,
          owner: item.createdBy,
          message: item.message,
          recipients_list: item.recipients_list,
          role: item.role,
        }))}
        pagination={{ pageSize: 8 }}
        scroll={{ x: "max-content" }}
        bordered
      />

      {/* Modal for Full Message */}
      <Modal
        title="Full Message"
        visible={isMessageModalVisible}
        onCancel={() => {
          setIsMessageModalVisible(false);
        }}
        footer={null}
      >
        <Text style={{ fontSize: "16px", color: "#333" }}>
          {selectedMessage}
        </Text>
      </Modal>

      {/* Modal for Recipients */}
      <Modal
        title="Recipient Details"
        open={isRecipientModalVisible}
        onCancel={() => {
          setRecipientSearchText("");
          setIsRecipientModalVisible(false);
        }}
        footer={null}
        width={700}
        // bodyStyle={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <Search
          placeholder="Search by Name or Code"
          value={recipientSearchText}
          onChange={(e) => setRecipientSearchText(e.target.value)}
          style={{ marginBottom: 16, width: 300 }}
          enterButton={true}
        />
        <Table
          dataSource={filteredRecipients?.map((recipient, index) => ({
            key: index,
            serial_number: `${index + 1}`,
            name: recipient?.personal?.full_name,
            number: recipient?.mobileNumber,
            code: recipient?.talntCode,
          }))}
          columns={[
            {
              title: "No",
              dataIndex: "serial_number",
              key: "serial_number",
              width: 70,
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Code",
              dataIndex: "code",
              key: "code",
              render: (text) => (
                <Tag color={text === "Delivered" ? "green" : "orange"}>
                  {text}
                </Tag>
              ),
            },
          ]}
          pagination={false}
          bordered
          scroll={{ y: 300 }}
        />
      </Modal>
    </>
  );
};

export const getValuesForParams = (status) => {
  var paramsValueEmp = [];
  status.forEach((val, i) => {
    if (val === "incomplete_profile") {
      paramsValueEmp.push(
        {
          status: {
            is_completed: false,
            is_submitted: true,
            is_employed: false,
          },
        },
        {
          status: {
            is_completed: false,
            is_submitted: false,
            is_employed: false,
          },
        },
        {
          status: {
            is_completed: null,
            is_submitted: null,
            is_employed: null,
          },
        }
      );
    }
    if (val === "completed_profile") {
      const completedStatus = {
        "status.is_approved": false,
        "status.is_completed": true,
        "status.is_submitted": false,
        "status.is_employed": false,
      };
      paramsValueEmp.push(completedStatus);
    }
    if (val === "yet_to_be_verified") {
      const yetToBeVerifiedStatus = {
        "status.is_approved": false,
        "status.is_submitted": true,
        "status.is_employed": false,
      };
      paramsValueEmp.push(yetToBeVerifiedStatus);
    }
    if (val === "available") {
      const availableStatus = {
        "status.is_approved": true,
        "status.is_completed": true,
        "status.is_submitted": true,
        "status.is_employed": false,
      };
      paramsValueEmp.push(availableStatus);
    }
    if (
      val === "employed" &&
      !(status.includes("assigned") || status.includes("unassigned"))
    ) {
      const assignedStatus = {
        "status.is_employed": true,
      };
      paramsValueEmp.push(assignedStatus);
    }
  });
  return paramsValueEmp;
};
