import { Avatar, Tag, Typography } from "antd";

const { Text } = Typography;

export const employeeColumns = [
  {
    title: "Talent Name",
    dataIndex: "fullName",
    key: "fullName",
    width: "50%",
    render: (text, record) => {
      const firstLetter = text?.charAt(0)?.toUpperCase() || "?";

      return (
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          {record.profilePicture ? (
            <Avatar size={40} src={record.profilePicture} />
          ) : (
            <Avatar
              style={{
                backgroundColor: "#1890ff",
                color: "#fff",
                fontWeight: "bold",
                fontSize: 16,
                width: 40,
                height: 40,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                textTransform: "uppercase",
                flexShrink: 0,
                lineHeight: "40px",
              }}
            >
              {firstLetter}
            </Avatar>
          )}
          <div
            style={{
              maxWidth: 200,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Text strong style={{ display: "block", fontSize: 12, color: "#1890ff", fontWeight: 600 }}>
              {text}
            </Text>
            <Text style={{ display: "block", fontSize: 13, color: "green", fontWeight: 600 }}>{record?.talntCode}</Text>
            <Text type="secondary" style={{ display: "block" }}>
              {record.mobileNumber}
            </Text>
          </div>
        </div>
      );
    },
  },
  {
    title: "Skills",
    dataIndex: "employment",
    key: "employment",
    render: (employment) => (
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <span
          style={{
            width: 6,
            height: 6,
            borderRadius: "50%",
            backgroundColor:
              employment?.skill === "Design" ? "#52c41a" : "#1890ff",
            display: "inline-block",
          }}
        />
        <Tag
          color={employment?.skill === "Design" ? "success" : "blue"}
          style={{ margin: 0 }}
        >
          {employment?.skill || "N/A"}
        </Tag>
      </div>
    ),
  },
  {
    title: "Experience",
    dataIndex: "employment",
    key: "experience",
    render: (employment) => <Text>{employment?.experience || "N/A"}</Text>,
  },
];