import React from "react";
import { Card, Typography, Row, Col, Divider, Button } from "antd";
import { employeeColumns } from "./Data";
import { useHistory } from "react-router-dom";

const { Text, Title } = Typography;

const EmployeeDetails = ({ data }) => {
  const history = useHistory();
  const deals = [
    {
      number: data?.data?.incompleted_Count?.toString().padStart(2, "0"),
      status: "Incompleted",
    },
    {
      number: data?.data?.yet_to_verify_Count?.toString().padStart(2, "0"),
      status: "Yet to Verify",
    },
    {
      number: (data?.data?.yet_to_verify_Count + data?.data?.incompleted_Count)
        ?.toString()
        .padStart(2, "0"),
      status: "Overall",
    },
  ];

  const cardStyle = {
    width: "100%",
    maxWidth: "800px",
    borderRadius: "8px",
  };

  const titleStyle = {
    fontSize: "20px",
    fontWeight: 800,
    marginBottom: "24px",
    display: "block",
    textAlign: "center",
    color: "black",
  };

  const numberStyle = {
    fontSize: "32px",
    fontWeight: 600,
    margin: 0,
    color: "#1890ff",
    textAlign: "center",
    display: "block",
  };

  const statusStyle = {
    fontSize: "14px",
    margin: "4px 0",
    display: "block",
    textAlign: "center",
  };

  const dividerStyle = {
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    margin: "0",
  };

  return (
    <Card style={cardStyle} bordered={false}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
          Unverified Talent Details
        </Title>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => history.push("Talntworx/userPanel")}
        >
          Verify Talents
        </Button>
      </div>
      <Row justify="space-between" align="middle">
        {deals.map((deal, index) => (
          <Col
            key={index}
            xs={8}
            style={{
              position: "relative",
              padding: "0 12px",
            }}
          >
            <div>
              <Title level={2} style={numberStyle}>
                {deal.number}
              </Title>
              <Text
                style={{
                  ...statusStyle,
                  color: index === 0 ? "red" : index === 1 ? "orange" : "green",
                }}
              >
                {deal.status}
              </Text>
            </div>
            {index < deals.length - 1 && (
              <Divider type="vertical" style={dividerStyle} />
            )}
          </Col>
        ))}
      </Row>
    </Card>
    //     <Card style={{ borderRadius: 16, marginBottom: 24 }} bodyStyle={{ padding: 24 }}>
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //           marginBottom: 16,
    //         }}
    //       >
    //         <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
    //           Unverified Talent Details
    //         </Title>
    //       </div>
    //       <Table
    //         columns={employeeColumns}
    //         dataSource={data?.data?.talentsDetails}
    //         pagination={false}
    //         showHeader={true}
    //         scroll={{ y: 280 }}
    //         style={{
    //           borderRadius: 8,
    //           overflow: "hidden",
    //         }}
    //         className="custom-table"
    //       />
    //       <style>
    //         {`
    // .custom-table .ant-table-thead > tr > th {
    // background: #f5f5f5;
    // border-bottom: 1px solid #f0f0f0;
    // }

    // .custom-table .ant-table-thead > tr > th:first-child {
    // border-top-left-radius: 8px;
    // border-bottom-left-radius: 8px;
    // }

    // .custom-table .ant-table-thead > tr > th:last-child {
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    // }

    // .custom-table .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #f0f0f0;
    // }
    // `}
    //       </style>
    //     </Card>
  );
};

export default EmployeeDetails;
