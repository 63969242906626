import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { createPost, fetchData, updatePost } from "../../../backendApi";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { TextArea } = Input;

function EmployeeEditPopup({
  data,
  isEdited,
  row,
  closeEditAssignTalentHandler,
  setData,
  setDetachTalents,
  setEditedRow,
  detachTalents,
  updatedRemovedDispatchDate,
}) {
  const [form] = Form.useForm();
  const [jobRole, setJobRole] = useState([]);
  const [isExitData, setIsExitDate] = useState(row?.dateOfExit ? true : false);
  const [initialValues, setInitialValues] = useState({}); // Store initial form values

  const fetchJobRoleDetail = async (value) => {
    try {
      if (value) {
        const res = await fetchData(`/jobRole/primaryEmployeer/${value}`);
        if (res?.data?.status === 200) {
          setJobRole(res?.data?.data);
        } else {
          setJobRole([]);
        }
      }
    } catch (e) {
      console.error("Error fetching job roles:", e);
    }
  };

  useEffect(() => {
    if (row?.NewTalntUser?.primaryEmployeerId) {
      fetchJobRoleDetail(row?.NewTalntUser?.primaryEmployeerId);
    }

    if (row) {
      const parseDate = (date) => {
        return date
          ? dayjs(date, ["D/M/YYYY", "DD/MM/YYYY", "YYYY-MM-DD"], true)
          : null;
      };

      const formValues = {
        name: row?.fullName || "",
        mobile: row?.mobileNumber || "",
        state: row?.state || "",
        jobRole: row?.role || undefined,
        jobRoleId: row?.jobRoleId || "",
        dateOfJoin:
          row?.dateOfJoin !== "N/A" ? parseDate(row?.dateOfJoin) : null,
        dateOfLeave:
          row?.dateOfExit !== "N/A" ? parseDate(row?.dateOfExit) : null,
        detachedReason: row?.reason && row?.reason !== "N/A" ? row?.reason : "",
      };

      setInitialValues(formValues); // Store the initial values
      form.setFieldsValue(formValues);
    }
  }, [row, form, data]);

  const handleCancel = () => {
    form.setFieldsValue(initialValues); // Restore initial values
    closeEditAssignTalentHandler();
  };

  const onFinish = async (values) => {
    try {
      let updateData = {
        ...values,
        primaryEmployeerId: row.primaryEmployeerId,
      };
      const res = await updatePost(
        `/talnt/updateAssignTalent/${row.id}`,
        updateData
      );
      if (res.data.status === 200) {
        form.setFieldsValue({ jobRoleId: res?.data?.data?.jobRoleId });

        const istTimestamp = new Date();
        const istOffset = 5.5 * 60 * 60 * 1000;
        istTimestamp.setTime(istTimestamp.getTime() + istOffset);
        const todayIST = istTimestamp.toISOString().split("T")[0];

        setData((prev) => {

          let updatedData = prev.filter((ele) => {
            debugger
            if (ele.id === row.id) {
              if (
                res?.data?.data?.dataValues?.dateOfLeave.split("T")[0] ===
                todayIST
              ) {
                return {
                  ...ele,
                  role: res.data.data.role,
                  dateOfJoin: dayjs(
                    res?.data?.data?.dataValues?.dateOfJoin
                  ).format("DD/MM/YYYY"),
                  dateOfExit: res?.data?.data?.dataValues?.dateOfLeave
                    ? dayjs(res?.data?.data?.dataValues?.dateOfLeave).format(
                        "DD/MM/YYYY"
                      )
                    : "N/A",
                  dateOfLeave: res?.data?.data?.dataValues?.dateOfLeave
                    ? dayjs(res?.data?.data?.dataValues?.dateOfLeave).format(
                        "DD/MM/YYYY"
                      )
                    : "N/A",
                  reason: res?.data?.data?.dataValues?.reason || "N/A",
                };
              }
            } else {
              updatedRemovedDispatchDate(ele);
              return ele;
            }
          });
          return updatedData;
        });

        setEditedRow((prev) => ({
          ...prev,
          isEdited: false,
        }));

        closeEditAssignTalentHandler();

        // if (!res?.data?.data?.dataValues?.dateOfLeave) {
        //   debugger
        //   updatedRemovedDispatchDate(row);
        // }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      title={<div style={{ textAlign: "center" }}>Edit Employee Detail</div>}
      open={isEdited}
      centered
      onCancel={handleCancel} // Call handleCancel instead of closeEditAssignTalentHandler
      bodyStyle={{ maxHeight: "65vh", overflowY: "auto" }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" label="Name">
          <Input placeholder="Name" readOnly disabled />
        </Form.Item>

        <Form.Item name="mobile" label="Mobile">
          <Input placeholder="Mobile" readOnly disabled />
        </Form.Item>

        <Form.Item name="state" label="State">
          <Input placeholder="State" readOnly disabled />
        </Form.Item>

        <Form.Item
          name="jobRole"
          label="Select Job Role"
          rules={[{ required: true, message: "Please select a job role!" }]}
        >
          <Select
            placeholder="Choose an option"
            allowClear
            onChange={(value) => {
              form.setFieldsValue({ jobRoleId: value });
            }}
          >
            {jobRole.map((ele) => (
              <Option key={ele.id} value={ele.id}>
                {ele.designation}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="jobRoleId" hidden>
          <Input />
        </Form.Item>

        {/* <Form.Item
          name="dateOfJoin"
          label="Joining Date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            onChange={(e) => {
              // const dateOfExit = form.getFieldValue("dateOfLeave")
              //   ? dayjs(form.getFieldValue("dateOfJoin")).startOf("day")
              //   : null;

              const utcDate = new Date(form.getFieldValue("dateOfLeave"));
              const istOffset = 5.5 * 60 * 60 * 1000;
              const istTimestamp = new Date(utcDate.getTime() + istOffset);

              const joiningDate = new Date(form.getFieldValue("dateOfJoin"));
              const joinTimestamp = new Date(joiningDate.getTime() + istOffset);

              if (
                istTimestamp.toISOString().split("T")[0] <=
                joinTimestamp.toISOString().split("T")[0]
              ) {
                return false;
              } else {
                return true;
              }
            }}
          />
        </Form.Item> */}

        <Form.Item
          name="dateOfJoin"
          label="Joining Date"
          rules={[
            { required: true, message: "Please select a date!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const dateOfLeave = getFieldValue("dateOfLeave");
                if (dateOfLeave && value && value.isAfter(dateOfLeave, "day")) {
                  return Promise.reject(
                    new Error("Joining Date cannot be after Leaving Date!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
        </Form.Item>

        {row?.dateOfExit && row?.dateOfExit !== "N/A" && (
          <>
            <Form.Item
              name="dateOfLeave"
              label="Leaving Date"
              style={{ marginTop: "5%" }}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD/MM/YYYY"
                onChange={(e) => {
                  if (e) {
                    setIsExitDate(false);
                  } else {
                    setIsExitDate(true);
                    form.setFieldsValue({
                      detachedReason: "",
                    });
                  }
                }}
                disabledDate={(current) => {
                  const dateOfJoin = form.getFieldValue("dateOfJoin")
                    ? dayjs(form.getFieldValue("dateOfJoin")).startOf("day")
                    : null;
                  const today = dayjs().startOf("day");

                  return (
                    !dateOfJoin ||
                    current.isBefore(dateOfJoin, "day") ||
                    current.isAfter(today, "day")
                  );
                }}
              />
            </Form.Item>

            <Form.Item name="detachedReason" label="Talent Detached Reason">
              <TextArea
                placeholder="Enter reason for detaching talent"
                rows={2}
                disabled={isExitData}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}

export default EmployeeEditPopup;
