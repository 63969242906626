import React from "react";
import {
  Row,
  Col,
  Typography,
  // Button,
  // Space,
  // ConfigProvider,
  // DatePicker,
} from "antd";
// import { ExportOutlined, DownOutlined } from "@ant-design/icons";
// import dayjs from "dayjs";

const { Title, Text } = Typography;
// const { RangePicker } = DatePicker;

const Header = () => {
  // const [dateRange, setDateRange] = useState([
  //   dayjs().startOf("month"),
  //   dayjs().endOf("month"),
  // ]);

  // const handleRangeChange = (dates) => {
  //   setDateRange(dates);
  // };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      {" "}
      <Col xs={24} sm={12}>
        {" "}
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              width: 40,
              height: 40,
              backgroundColor: "white",
              borderRadius: 8,
              padding: 6,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 4,
              }}
            >
              {[...Array(4)].map((_, i) => (
                <div
                  key={i}
                  style={{
                    width: 8,
                    height: 8,
                    background: "#2538be",
                    borderRadius: 2,
                  }}
                />
              ))}
            </div>
          </div>

          <div>
            <Title level={4} style={{ margin: 0, fontWeight: "bold" }}>
              Dashboard
            </Title>
            <Text type="secondary">Welcome back to TalntX</Text>
          </div>
        </div>
      </Col>
      {/* <Col
        xs={24}
        sm={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {" "}
        <ConfigProvider
          theme={{
            components: { DatePicker: { activeBorderColor: "#d9d9d9" } },
          }}
        >
          <Space>
            <RangePicker
              value={dateRange}
              onChange={handleRangeChange}
              format="D MMM YYYY"
              allowClear={false}
              suffixIcon={<DownOutlined />}
              style={{
                borderRadius: "10px",
                height: "32px",
              }}
              renderExtraFooter={() => (
                <div style={{ padding: "8px 0" }}>
                  <Button
                    size="small"
                    type="text"
                    onClick={() => {
                      setDateRange([
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ]);
                    }}
                  >
                    Reset to Current Month
                  </Button>
                </div>
              )}
              popupStyle={{
                zIndex: 1000,
              }}
            />
            <Button
              type="primary"
              style={{
                borderRadius: "10px",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              icon={<ExportOutlined />}
            >
              Export
            </Button>
          </Space>
        </ConfigProvider>
      </Col> */}
    </Row>
  );
};

export default Header;
