import React from "react";
import { useContext } from "react";
import { dataContext } from "../../context/data";
import { Select, Spin, message } from "antd";
import viewbtn from "../../assets/icons/viewBtn.svg";
import sort from "../../assets/icons/sort.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../context/auth";
import { useHistory } from "react-router";
import useSortableData from "../useSortableData";
import { useSelector } from "react-redux";

const DashBoardTalents = ({
  talentsData,
  talentsColumn,
  filterOption,
  userPath,
}) => {
  const userContext = useContext(dataContext);
  const { Option } = Select;
  const [filterValue, setFilterValue] = useState([]);
  const auth = useAuthContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { items, requestSort } = useSortableData(talentsData);

  const moreData = () => {
    userContext.setDashboardData((pre) => {
      return { ...pre, page: pre.page + 1 };
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    userContext.setDashboardData((pre) => {
      return { ...pre, filterValue };
    });
    userContext.setDashboardData((pre) => {
      return { ...pre, DblastFetch: undefined, page: 0 };
    });
  }, [filterValue]);

  const handleOnClick = (e, row) => {
    // message.success(`you clicked id value - ${row.key}`)
    history.push({
      pathname: `./${userPath}/user`,
      state: { id: row.key },
    });
  };

  const sortingFunction = (columnName) => {
    if (columnName || columnName !== "") {
      var sortName = "";
      if (columnName == "name") {
        sortName = "personal.full_name.keyword";
      } else if (columnName == "aadhaar") {
        sortName = "identity.aadhaar.aadhaar_number.keyword";
      } else if (columnName == "employer") {
        sortName = "status.current_primary_employer_name.keyword";
      } else if (columnName == "status") {
        sortName = "status.is_assigned";
      } else if (columnName == "contractor") {
        sortName = "status.current_contractor_name.keyword";
      }
    }
    if (userContext.dashboardData.empTalSort) {
      if (
        Object.keys(userContext.dashboardData.empTalSort).includes(sortName)
      ) {
        let value = userContext.dashboardData.empTalSort;
        if (value[sortName] === "asc") {
          value[sortName] = "desc";
        } else if (value[sortName] === "desc") {
          value[sortName] = "asc";
        }
        userContext.setDashboardData((pre) => {
          return { ...pre, empTalSort: value, isEmpTalSort: !pre.isEmpTalSort };
        });
      } else {
        let temp;
        if (columnName == "name") {
          temp = {
            [sortName]: "desc",
          };
        } else {
          temp = {
            [sortName]: "asc",
          };
        }
        console.log("again new", temp);
        userContext.setDashboardData((pre) => {
          return { ...pre, empTalSort: temp };
        });
      }
    } else {
      let temp;
      if (columnName == "name") {
        temp = {
          [sortName]: "desc",
        };
      } else {
        temp = {
          [sortName]: "asc",
        };
      }
      userContext.setDashboardData((pre) => {
        return { ...pre, empTalSort: temp };
      });
    }
  };

  return (
    <div className="rightCover">
      <div className="topSearchbar">
        <div className="title">
          <h2>Employed Talent Details</h2>
        </div>
        <div className="selectFilter">
          <Select
            mode="multiple"
            showSearch
            showArrow
            size="large"
            maxTagCount="responsive"
            listHeight={200}
            allowClear
            style={{ minWidth: "18vw" }}
            placeholder={
              auth.isPrimary ? "Filter Contractor" : "Filter Employer"
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(value) => {
              //
              setFilterValue(value);
            }}
          >
            {filterOption?.map((opt, i) => {
              return (
                <Option
                  key={i}
                  value={opt.id}
                  disabled={
                    filterValue.length > 9
                      ? filterValue.includes(opt.id)
                        ? false
                        : true
                      : false
                  }
                >
                  {opt.name}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      <InfiniteScroll
        dataLength={talentsData.length}
        hasMore={true}
        key={userContext.dashboardData.activeContractTabIndex}
        next={moreData}
        scrollableTarget="scrolltable"
      >
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : talentsData.length === 0 ? (
          <div className="noDataFound">
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                fontSize: "24px",
                color: "#777",
                alignItems: "center",
              }}
            >
              No Data Found
            </h3>
          </div>
        ) : (
          <div className="talentsList" id="scrolltable">
            <table>
              <thead>
                <tr>
                  {talentsColumn.map((itm, i) => {
                    return (
                      <th key={i}>
                        {itm.title}{" "}
                        {itm.dataIndex !== "aadhaar" && (
                          <img
                            src={sort}
                            alt="sorticon"
                            width="15px"
                            height="auto"
                            onClick={() => sortingFunction(itm.dataIndex)}
                            style={{
                              marginTop: "-5px",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items.map((row, idx) => {
                  return (
                    <tr key={idx} style={{ display: row == "" ? "none" : "" }}>
                      {talentsColumn.map((field, i) => {
                        return <td>{row[field.dataIndex]}</td>;
                      })}

                      <td>
                        <img
                          src={viewbtn}
                          alt="viewBtn"
                          onClick={(e) => handleOnClick(e, row)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default DashBoardTalents;
