import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import axios from "axios";
import { fetchPrimaryEmployeersList } from "../../services/contractUserService";
import { useSelector } from "react-redux";
import { dataContext } from "../../context/data";
import { fetchData } from "../../backendApi";

const { Option } = Select;

const ContractUserForm = ({
  visible,
  onClose,
  onSubmit,
  initialValues = {}, // Default to an empty object
  primaryContractorId,
  primaryCname,
  contractUsers,
}) => {
  let permission = useSelector((state) => {
    return state.user.permission.getUserRole;
  });
  console.log(permission, "permission");
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [primaryEmployeer, setPrimaryEmployeer] = useState([]);
  const [selectedPrimaryEmployeer, setSelectedPrimaryEmployeer] = useState([]);
  const [selectRole, setSelectRole] = useState("");
  const [permissions, setPermissions] = useState({});
  const [showPrimaryEmployer, setShowPrimaryEmployer] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const { userData, setUserData } = useContext(dataContext);
  const [mobileNumber, setMobileNumber] = useState("");

  // Fetch roles from API
  const fetchContractorRole = async () => {
    try {
      const res = await fetchData("/role/user/Contractor");
      if (res?.data?.status === 200) {
        setRoles(res.data.data);
      } else {
        console.error("Failed to fetch roles:", res?.data?.message);
      }
    } catch (e) {
      console.error("Error fetching roles:", e);
    }
  };

  const fetchRolePermissions = async (roleId) => {
    try {
      const res = await fetchData(`/web/role/rolePermision/${roleId}`);
      if (res?.data?.status === 200) {
        const permissionData = JSON.parse(res.data.data.fetchRole.permission);
        console.log(permissionData, "permissionData123");
        setPermissions(permissionData);

        const hasTalentAssignment =
          permissionData?.["Principle Employer"]?.["read"] &&
          permissionData?.["Principle Employer"]?.["edit"] &&
          permissionData?.["Contractors"]?.["read"] &&
          permissionData?.["Assign Contractors"]?.["read"] &&
          permissionData?.["Assign Contractors"]?.["write"];
        // const hasPrincipleEmployer = permissionData?.["Principle Employer"]?.["read"] && permissionData?.["Principle Employer"]?.["write"];

        setShowPrimaryEmployer(hasTalentAssignment);
      } else {
        console.error("Failed to fetch role permissions:", res?.data?.message);
      }
    } catch (e) {
      console.error("Error fetching role permissions:", e);
    }
  };

  const fetchPrimaryEmployeer = async () => {
    if (!initialValues?.id) return;
    try {
      const response = await fetchData(
        `/employeerContractor?primaryContractorId=${initialValues.id}`
      );
      if (response?.data?.status === 200) {
        const primaryEmployerIds = response.data?.data?.map((item) => item);
        const employeerRes = await fetchPrimaryEmployeersList();
        if (employeerRes?.status === 200) {
          const allEmployeers = employeerRes?.dropdownList;
          const matchedEmployeers = allEmployeers.filter((emp) =>
            primaryEmployerIds.includes(emp.id)
          );
          setPrimaryEmployeer(allEmployeers);
          setSelectedPrimaryEmployeer(matchedEmployeers.map((emp) => emp.id));
        }
      }
    } catch (e) {
      console.error("Error fetching primary employer data:", e);
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setMobileNumber(initialValues?.mobileNumber || "");
      const selectedRole = roles.find(
        (role) => role.roleName === initialValues?.Rolemanagement?.roleName
      );
      if (selectedRole) {
        setSelectedRoleId(selectedRole.id);
        fetchRolePermissions(selectedRole.id);
      }
      //  setSelectedRoleId(selectedRole);
      form.setFieldsValue({
        ...initialValues,
        businessName: primaryCname,
        menuId: selectedRole ? selectedRole.id : undefined, // Map roleName to menuId,
        primaryEmployerId: selectedPrimaryEmployeer.length
          ? selectedPrimaryEmployeer
          : undefined, // Fix: Prevent resetting menuId
        mobileNumber: initialValues?.mobileNumber || "",
      });
    }
  }, [visible, initialValues, roles, form, primaryCname]);

  const handleRoleChange = (roleId) => {
    //setSelectRole(roleId);
    setSelectedRoleId(roleId);
    fetchRolePermissions(roleId); // Fetch permissions immediately when role changes
    setSelectedPrimaryEmployeer([]);
    setUserData((pre) => {
      return { ...pre, reload: !pre.reload };
    });
  };

  // Effect to trigger permission fetching when selectedRoleId updates
  useEffect(() => {
    if (selectedRoleId) {
      fetchRolePermissions(selectedRoleId);
    }
  }, [selectedRoleId]);

  const fetchPrimaryEmployeerListValues = async () => {
    const employeerRes = await fetchPrimaryEmployeersList();
    console.log(employeerRes, "employeerRes");
    if (employeerRes?.status === 200) {
      const allEmployeers = employeerRes?.dropdownList;
      setPrimaryEmployeer(allEmployeers);
    }
  };

  useEffect(() => {
    fetchContractorRole();
    fetchPrimaryEmployeerListValues();
  }, []);

  useEffect(() => {
    if (visible && initialValues?.id) {
      fetchPrimaryEmployeer();
    } else {
      setSelectedPrimaryEmployeer([]);
    }
  }, [visible, initialValues?.id]); // Fetch only when visible and ID changes

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        primaryEmployerId: selectedPrimaryEmployeer,
      });
    }
  }, [selectedPrimaryEmployeer]);

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({
        ...values,
        primaryContractorId,
        businessName: primaryCname,
        roleId: selectedRoleId,
        primaryEmployerId: selectedPrimaryEmployeer,
      });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Edit Contract User" : "New Contract User"}
      onCancel={onClose}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {initialValues ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Form.Item
          name="fullName"
          // label="Full Name"
          label={
            <span>
              Full Name <span style={{ color: "red" }}>*</span>
            </span>
          }
          rules={[{ required: true, message: "Please enter the full name" }]}
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
          {/* Business Name Field */}
          <div style={{ flex: 1 }}>
            {/* <label className="modalBottomLineInput" id="name">
            Business Name
          </label> */}
            <Form.Item
              name="businessName"
              label="Business Name"
              // rules={[
              //   { required: true, message: "Please enter the mobile number" },
              //   {
              //     pattern: /^[6-9]\d{9}$/,
              //     message: "Please enter a valid 10-digit mobile number",
              //   },
              // ]}
            >
              <input
                type="text"
                disabled
                value={primaryCname}
                htmlFor="name"
                placeholder=" "
                className="labelOnBorderInput"
                style={{
                  width: "100%",
                  height: "40px",
                  padding: "8px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </Form.Item>
          </div>

          {/* Mobile Number Field */}
          <div style={{ flex: 1 }}>
            {/* <Form.Item
          name="mobileNumber"
         // label="Mobile Number"
         label={
    <span>
      Mobile Number <span style={{ color: "red" }}>*</span>
    </span>
  }
          rules={[
            { required: true, message: "Please enter the mobile number" },
            {
              pattern: /^[6-9]\d{9}$/,
              message: "Please enter a valid mobile number",
            },
          ]}
        >
          <Input
            type="text"
            maxLength={10}
            onKeyPress={(event) => {
              if (!/^\d$/.test(event.key)) {
                event.preventDefault();
              }
            }}
            style={{
          width: "100%",
          height: "40px",
          padding: "8px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
          />
        </Form.Item> */}
            <Form.Item
              name="mobileNumber"
              label={
                <span>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </span>
              }
              rules={[
                { required: true, message: "Please enter the mobile number" },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "Please enter a valid mobile number",
                },
              ]}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "0px" }}
              >
                <Select
                  defaultValue="+91"
                  style={{
                    width: "70px",
                    height: "40px", // Force height
                    lineHeight: "40px", // Align text vertically
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="custom-select"
                  dropdownStyle={{ lineHeight: "1.5" }}
                  //dropdownRender={(menu) => <div style={{ lineHeight: "1.5" }}>{menu}</div>}
                >
                  <Option key="1" value="+91">
                    +91
                  </Option>
                  <Option key="2" value="+82">
                    +82
                  </Option>
                  <Option key="3" value="+72">
                    +72
                  </Option>
                  <Option key="4" value="+53">
                    +53
                  </Option>
                </Select>

                <Input
                  type="text"
                  maxLength={10}
                  value={mobileNumber} // Bind value to state
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setMobileNumber(newValue); // Update local state
                    form.setFieldsValue({ mobileNumber: newValue }); // Sync with AntD form
                  }}
                  onKeyPress={(event) => {
                    if (!/^\d$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{
                    flex: 1,
                    height: "40px",
                    padding: "8px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </Form.Item>
          </div>
        </div>
        {/* Select Role Field */}
        <Form.Item
          name="menuId"
          //label="Select Role"
          label={
            <span>
              Select Role <span style={{ color: "red" }}>*</span>
            </span>
          }
          rules={[{ required: true, message: "Please select an option!" }]}
          initialValue={initialValues?.Rolemanagement?.roleName}
        >
          <Select
            placeholder="Choose an option"
            onChange={handleRoleChange}
            allowClear
          >
            {roles.length > 0 ? (
              roles.map((role) => (
                <Option key={role?.id} value={role?.id}>
                  {role?.roleName}
                </Option>
              ))
            ) : (
              <Option disabled>Loading roles...</Option>
            )}
          </Select>
        </Form.Item>

        {/* Primary Employeers  showPrimaryEmployer */}

        <Form.Item
          name="primaryEmployerId"
          label="Select Principal Employer"
          style={{ marginTop: "4%" }}
        >
          <Select
            placeholder="Choose an option"
            mode="multiple"
            onChange={(val) => setSelectedPrimaryEmployeer(val)}
            value={selectedPrimaryEmployeer}
            allowClear
            disabled={
              permission &&
              permission?.permission?.["Principle Employer"]?.read &&
              permission?.permission?.["Principle Employer"]?.edit &&
              permission?.permission?.["Assign Contractors"]?.read &&
              permission?.permission?.["Assign Contractors"]?.write
                ? false
                : true
            }
          >
            {primaryEmployeer.length > 0 &&
              primaryEmployeer.map((emp) => (
                <Option key={emp.id} value={emp.id}>
                  {emp.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContractUserForm;
