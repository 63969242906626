import React from "react";
import { Card, Typography } from "antd";
import { JobCard } from "./JobCard";

const { Title } = Typography;

const WhatsOn = ({ data }) => {
  return (
    <Card style={{ borderRadius: 16 }} bodyStyle={{ padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
          Job Posts
        </Title>
      </div>
      <div
        style={{
          maxHeight: "312px",
          overflowY: "auto",
          paddingRight: "5px",
          // backgroundColor: "	#F0FFFF"
        }}
      >
        {data?.data?.jobPosts?.map((person, idx) => (
          <JobCard
            designation={person.designation}
            qualification={person.qualification}
            count={person.applied_user_count}
            index={idx}
          />
        ))}
      </div>
    </Card>
  );
};

export default WhatsOn;
