import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { dataContext } from "../context/data";
import { message } from "antd";
import app from "../firebase";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  limit,
  startAfter,
  startAt,
  where,
  onSnapshot,
} from "firebase/firestore";
import moment from "moment";
import DashBoardTalents from "./dashboard/DashBoardTalents";
import DashBoardPieChart from "./dashboard/DashBoardPieChart";
import { useAuthContext } from "../context/auth";
import { Route } from "react-router-dom";
import UserDetailsBase from "./panel/UserDetails/UserDetailsBase";
import { Switch } from "react-router-dom";
import { getAuth } from "@firebase/auth";
import axios from "axios";
// import axios from "../axiosConfig/index";
import baseUrl from "../utils/baseUrl";
import { fetchData, createPost } from "../backendApi";
import axiosClient from "../axiosConfig";

const db = getFirestore(app);

const talentsDataColumn = [
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "AADHAAR NUMBER",
    dataIndex: "aadhaar",
  },
  {
    title: "PRINCIPAL EMPLOYER",
    dataIndex: "employer",
  },
  {
    title: "STATUS",
    dataIndex: "status",
  },
];
const talentsDataColumnPrimary = [
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "AADHAAR NUMBER",
    dataIndex: "aadhaar",
  },
  {
    title: "CONTRACTOR",
    dataIndex: "contractor",
  },
  {
    title: "STATUS",
    dataIndex: "status",
  },
];
function DashBoard({ userType, userPath }) {
  const [talentsData, setTalentsData] = useState([]);
  const {
    dashboardData,
    setDashboardData,
    setNavList,
    setJobData,
    setUserData,
  } = useContext(dataContext);
  const [filterOption, setFilterOption] = useState([]);
  const [contractFilter, setContractFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuthContext();
  const [objData, setObjData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [objectD, setObjectD] = useState([]);
  const [totalTalentValue, setTotalTalentValue] = useState(0);
  // const [primaryChart, setPrimaryChart] = useState([
  //   {
  //     name: "Current Employees",
  //     value: 0,
  //     color: "#EE3964"
  //   },
  //   {
  //     name: "Past Employees",
  //     value: 0,
  //     color: "#EE3964"
  //   },
  //   {
  //     name: "Unassigned",
  //     value: 0,
  //     color: "#EE3964"
  //   },{
  //     name: "Ready for Employement",
  //     value: 0,
  //     color: "#EE3964"
  //   }
  // ])

  // const [primaryLabel, setPrimaryLabel] = useState([
  //   {
  //     name: "Current Employees",
  //     value: 0,
  //     dataIndex: 'currentEmployee'
  //   },
  //   {
  //     name: "Past Employees",
  //     value: 0,
  //     dataIndex: 'pastEmployee',
  //   },
  //   {
  //     name: "Unassigned",
  //     value: 0,
  //     dataIndex: 'unassigned'
  //   },
  //   {
  //     name: "Ready for Employement",
  //     value: 0,
  //     dataIndex: 'readyForEmployee'
  //   }

  // ])
  // const [chartData, setChartData] = {[]}
  //const [deleteKey, setDeleteKey] = useState([]);
  //const [selectedArray, setSelectedArray]

  useEffect(() => {
    setNavList((pre) => {
      return {
        ...pre,
        dashBoard: true,
        userList: false,
        jobPost: false,
        requests: false,
        whatsapp: false
      };
    });
    setDashboardData((pre) => {
      return {
        ...pre,
        DblastFetch: undefined,
        page: 0,
        totalTalentTrigger: false,
        filterQuery: {},
        contractValue: [],
        filterValue: [],
        sortColumn: undefined,
      };
    });
    setJobData((pre) => {
      return { ...pre, DblastFetch: undefined, page: 0 };
    });
    setUserData((pre) => {
      return { ...pre, DblastFetch: undefined, page: 0 };
    });
  }, []);

  const statusFinder = (s) => {
    if (s?.is_approved && s?.is_completed && s?.is_submitted && s?.is_employed)
      s = "Employed";
    else if (s?.is_approved && s?.is_completed && s?.is_submitted)
      s = "Available"; //need to clarify
    else if (!s?.is_approved && s?.is_completed && !s?.is_submitted)
      s = "Completed Profile";
    else if (!s?.is_approved && s?.is_submitted) s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };

  const mapTalentData = (d, itm, emp = "", con = "", st = "") => {
    let s = st !== "" ? st : statusFinder(d.status);
    let dob = new Timestamp(
      d.personal?.dob?.seconds | d.personal?.dob?._seconds,
      d.personal?.dob?.nanoseconds | d.personal?.dob?._nanoseconds
    ).toDate();
    let aadhaar = d.identity?.aadhaar.aadhaar_number;
    let change = aadhaar.replace(/.(?=.{4,}$)/g, "X");
    return {
      key: itm.id,
      data: d,
      contractor: con,
      employer: emp,
      status: s,
      name: d.personal?.full_name,
      dob: moment(dob).format("DD-MM-YYYY"),
      address: `${d.contact?.address_line_1},
      ${d.contact?.address_line_2},
      ${d.contact?.city},
      ${d.contact?.state},
      ${d.contact?.country}-${d.contact?.pincode}`,
      mnum: d.contact?.mobile_number,
      gender: d.personal?.gender,
      bGroup: d.personal?.blood_group,
      email: d.contact?.email,
      aadhaar: change,
      pan: d.identity?.pan?.pan_number,
      pincode: d.contact.pincode,
      bankAC: d.bank?.account_number,
      handicapped: d.personal?.is_handicapped
        ? d.personal.handicapped_reason
        : "NO",
    };
  };

  //fetch Contracter and employer
  const getCatagory = async (type, sVal = "") => {
    try {
      let d = [],
        q = query(collection(db, type), where("name", ">=", sVal));
      const res = await getDocs(q);
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id }));
      // console.log(type, d);
      return d;
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //
  //   async function getContractorAndEmployersData() {
  //     setLoading(true);
  //     const responses = await Promise.all(
  //       ["contractors", "primary_employers"].map(async (type) => {
  //         const res = await getCatagory(type);
  //         return res;
  //       })
  //     );
  //     if (responses || responses.length > 0) {
  //       setContractFilter(responses[0]);
  //       if (auth.isPrimary) {
  //         setFilterOption(responses[0]);
  //       } else {
  //         setFilterOption(responses[1]);
  //       }
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }

  //   const res = getContractorAndEmployersData();
  // }, []);

  useEffect(() => {
    async function getContractorAndEmployersData() {
      setLoading(true);
      const responses = await fetchData("/dashboard/primaryEmployeer");
      if (responses?.data?.data || responses?.data?.data?.length > 0) {
        if (auth.isPrimary) {
          setFilterOption(responses?.data?.data);
        } else {
          setFilterOption(responses?.data?.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    const res = getContractorAndEmployersData();
  }, []);

  useEffect(() => {
    async function getContractorAndEmployersData() {
      setLoading(true);
      const responses = await fetchData("/dashboard/primaryContractor");
      if (responses?.data?.data || responses?.data?.data?.length > 0) {
        setContractFilter(responses?.data?.data);
        // if (auth.isPrimary) {
        //   setFilterOption(responses?.data?.data);
        // } else {
        //   setFilterOption(responses?.data?.data);
        // }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    const res = getContractorAndEmployersData();
  }, []);

  // useEffect(() => {
  //   message.loading({ content: "Loading...", duration: 0, key: "talent" });
  //   var contractor, employer;
  //   let queryArray = [];
  //   let selectedArray = [];
  //   let deleteArray = [];
  //   const f = dashboardData.filterValue;
  //   if (f.length > selectedArray.length) {
  //     f.map((itm) => {
  //       if (!selectedArray.includes(itm)) {
  //         selectedArray.push(itm);
  //         queryArray = itm;
  //       }
  //     });
  //   } else if (f.length < selectedArray.length) {
  //     selectedArray.map((val) => {
  //       if (!f.includes(val)) {
  //         deleteArray = val;
  //       }
  //     });
  //   } else {
  //     selectedArray = [];
  //     queryArray = [];
  //   }
  //   async function fetch() {
  //     try {
  //       const temp = [];
  //       let q,
  //         count = 0;
  // contractor = await getCatagory("contractors");
  // employer = await getCatagory("primary_employers");
  //       setFilterOption(employer);
  //       setContractFilter(contractor);

  //       if (
  //         dashboardData.contractValue &&
  //         dashboardData.contractValue.length !== 0 &&
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch == undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (
  //         dashboardData.contractValue &&
  //         dashboardData.contractValue.length !== 0 &&
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch !== undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch == undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch !== undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           startAfter(dashboardData.DblastFetch),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (dashboardData.DblastFetch) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           startAfter(dashboardData.DblastFetch),
  //           limit(15)
  //         );
  //       } else {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       }

  //       const querySnapshot = await getDocs(q);
  //       querySnapshot.forEach(async (itm) => {
  //         let d = itm.data(),
  //           empName = "",
  //           conName = "";

  //         // to find employer name
  //         empName = employer.filter(
  //           (e) => e.id == d.status.current_primary_employer_id
  //         );
  //         //to find contractor name
  //         conName = contractor.filter(
  //           (c) => c.id == d.status.current_contractor_id
  //         );

  //         let actEmpQ = query(
  //           collection(itm.ref, `employer`),
  //           where("is_current_employer", "==", true)
  //         );
  //         let actEmp = await getDocs(actEmpQ);
  //         // let q1 = query(collection(itm.ref, `employer`), where("is_current_assignment", "==", true));
  //         let q1 = query(
  //           collection(actEmp.docs[0].ref, `assignment`),
  //           where("is_current_assignment", "==", true)
  //         );
  //         const qres1 = await getDocs(q1);
  //         // console.log(itm.id, qres1, qres1.empty);

  //         if (!qres1.empty) {
  //           let st = "Assigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         } else {
  //           let st = "Unassigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         }

  //         if (querySnapshot.docs.length - 1 === count++) {
  //           message.destroy("talent");
  //           // setTalentsData(pre => {
  //           //   if (dashboardData.page > 0) {
  //           //     return [...pre, ...temp];
  //           //   }
  //           //   else return temp;
  //           // });
  //           setDashboardData((pre) => {
  //             return { ...pre, DblastFetch: itm };
  //           });
  //           // console.log(temp);
  //         }
  //       });
  //       if (querySnapshot.docs.length <= 0) {
  //         message.destroy("talent");

  //         // setTalentsData(pre => {
  //         //   if (dashboardData.page > 0) {
  //         //     return [...pre];
  //         //   }
  //         //   else return [];
  //         // });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       message.destroy("talent");
  //     }
  //   }

  //   async function fetchContract() {
  //     try {
  //       const temp = [];
  //       let q,
  //         count = 0;
  //       contractor = await getCatagory("contractors");
  //       employer = await getCatagory("primary_employers");
  //       setFilterOption(employer);
  //       setContractFilter(contractor);

  //       if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch == undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_contractor_id",
  //             "==",
  //             localStorage.getItem("contractor_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch !== undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_contractor_id",
  //             "==",
  //             localStorage.getItem("contractor_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_primary_employer_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           startAfter(dashboardData.DblastFetch),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (dashboardData.DblastFetch) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_contractor_id",
  //             "==",
  //             localStorage.getItem("contractor_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           startAfter(dashboardData.DblastFetch),
  //           limit(15)
  //         );
  //       } else {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_contractor_id",
  //             "==",
  //             localStorage.getItem("contractor_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       }

  //       const querySnapshot = await getDocs(q);
  //       //console.log("querySnapshot",querySnapshot.docs);
  //       querySnapshot.forEach(async (itm) => {
  //         let d = itm.data(),
  //           empName = "",
  //           conName = "";

  //         // to find employer name
  //         empName = employer.filter(
  //           (e) => e.id == d.status.current_primary_employer_id
  //         );
  //         //to find contractor name
  //         conName = contractor.filter(
  //           (c) => c.id == d.status.current_contractor_id
  //         );

  //         let actEmpQ = query(
  //           collection(itm.ref, `employer`),
  //           where("is_current_employer", "==", true)
  //         );
  //         let actEmp = await getDocs(actEmpQ);
  //         // let q1 = query(collection(itm.ref, `employer`), where("is_current_assignment", "==", true));
  //         let q1 = query(
  //           collection(actEmp.docs[0].ref, `assignment`),
  //           where("is_current_assignment", "==", true)
  //         );
  //         const qres1 = await getDocs(q1);
  //         // console.log(itm.id, qres1, qres1.empty);

  //         if (!qres1.empty) {
  //           let st = "Assigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         } else {
  //           let st = "Unassigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         }

  //         if (querySnapshot.docs.length - 1 === count++) {
  //           message.destroy("talent");
  //           // setTalentsData(pre => {
  //           //   if (dashboardData.page > 0) {
  //           //     return [...pre, ...temp];
  //           //   }
  //           //   else return temp;
  //           // });
  //           setDashboardData((pre) => {
  //             return { ...pre, DblastFetch: itm };
  //           });
  //           // console.log(temp);
  //         }
  //       });
  //       if (querySnapshot.docs.length <= 0) {
  //         message.destroy("talent");

  //         // setTalentsData(pre => {
  //         //   if (dashboardData.page > 0) {
  //         //     return [...pre];
  //         //   }
  //         //   else return [];
  //         // });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       message.destroy("talent");
  //     }
  //   }

  //   async function fetchPrimary() {
  //     try {
  //       const temp = [];
  //       let q,
  //         count = 0;
  //       contractor = await getCatagory("contractors");
  //       employer = await getCatagory("primary_employers");
  //       setFilterOption(contractor);
  //       setContractFilter(contractor);

  //       if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch == undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_primary_employer_id",
  //             "==",
  //             localStorage.getItem("primary_employer_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_contractor_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (
  //         dashboardData?.filterValue.length !== 0 &&
  //         dashboardData?.filterValue !== undefined &&
  //         dashboardData.DblastFetch !== undefined
  //       ) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_primary_employer_id",
  //             "==",
  //             localStorage.getItem("primary_employer_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           where(
  //             "status.current_contractor_id",
  //             "in",
  //             dashboardData.filterValue
  //           ),
  //           startAfter(dashboardData.DblastFetch),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       } else if (dashboardData.DblastFetch) {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_primary_employer_id",
  //             "==",
  //             localStorage.getItem("primary_employer_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           startAfter(dashboardData.DblastFetch),
  //           limit(15)
  //         );
  //       } else {
  //         q = query(
  //           collection(db, "talent_users"),
  //           where(
  //             "status.current_primary_employer_id",
  //             "==",
  //             localStorage.getItem("primary_employer_id")
  //           ),
  //           where("status.is_employed", "==", true),
  //           orderBy("personal.full_name"),
  //           limit(15)
  //         );
  //       }

  //       const querySnapshot = await getDocs(q);
  //       //console.log("querySnapshot",querySnapshot.docs);
  //       querySnapshot.forEach(async (itm) => {
  //         let d = itm.data(),
  //           empName = "",
  //           conName = "";

  //         // to find employer name
  //         empName = employer.filter(
  //           (e) => e.id == d.status.current_primary_employer_id
  //         );
  //         //to find contractor name
  //         conName = contractor.filter(
  //           (c) => c.id == d.status.current_contractor_id
  //         );

  //         let actEmpQ = query(
  //           collection(itm.ref, `employer`),
  //           where("is_current_employer", "==", true)
  //         );
  //         let actEmp = await getDocs(actEmpQ);
  //         // let q1 = query(collection(itm.ref, `employer`), where("is_current_assignment", "==", true));
  //         let q1 = query(
  //           collection(actEmp.docs[0].ref, `assignment`),
  //           where("is_current_assignment", "==", true)
  //         );
  //         const qres1 = await getDocs(q1);
  //         // console.log(itm.id, qres1, qres1.empty);

  //         if (!qres1.empty) {
  //           let st = "Assigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         } else {
  //           let st = "Unassigned";
  //           temp.push(
  //             mapTalentData(d, itm, empName[0]?.name, conName[0]?.name, st)
  //           );
  //         }

  //         if (querySnapshot.docs.length - 1 === count++) {
  //           message.destroy("talent");
  //           // setTalentsData(pre => {
  //           //   if (dashboardData.page > 0) {
  //           //     return [...pre, ...temp];
  //           //   }
  //           //   else return temp;
  //           // });
  //           setDashboardData((pre) => {
  //             return { ...pre, DblastFetch: itm };
  //           });
  //           // console.log(temp);
  //         }
  //       });
  //       if (querySnapshot.docs.length <= 0) {
  //         message.destroy("talent");

  //         setTalentsData((pre) => {
  //           if (dashboardData.page > 0) {
  //             return [...pre];
  //           } else return [];
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       message.destroy("talent");
  //     }
  //   }
  //   if (userType === "spectra")
  //     setTimeout(() => {
  //       fetch();
  //     }, 0);
  //   else if (userType === "contract")
  //     setTimeout(() => {
  //       fetchContract();
  //     }, 0);
  //   else if (userType === "primary")
  //     setTimeout(() => {
  //       fetchPrimary();
  //     }, 0);
  // }, [dashboardData.page, dashboardData.filterValue]);

  // const updateObjectInArray = (val, type) => {
  //   setPrimaryLabel(current =>
  //     current.map(obj => {
  //       if (obj.dataIndex == type) {
  //         return {...obj, value: val};
  //       }

  //       return obj;
  //     }),
  //   );
  // };

  useEffect(() => {
    const user_id = localStorage.getItem("uid");
    let user_type;
    const isSpectra = auth.isSpectra;
    const isPrimary = auth.isPrimary;
    const isContract = auth.isContract;
    if (isSpectra) {
      user_type = "spectraWise";
    } else if (isPrimary) {
      user_type = "primary";
    } else {
      user_type = "contract";
    }
    async function dashboard() {
      try {
        message.loading({ content: "Loading", key: "Dashboard", duration: 0 });
        const response = await axiosClient.post(
          `https://urchin-app-fi4og.ondigitalocean.app/api/web/dashboard/dashboardDetails`,
          {
            title: "Dashboard_initial_fetch",
            user_id: user_id,
            user_type,
            contractorId: dashboardData?.contractValue || [],
          }
        );
        if (response.data) {
          if (!isPrimary) {
            const { incomplete, yet_to_be_verified, available, employer } =
              response.data;
            setObjData([
              {
                name: "Incomplete Profile",
                value: incomplete,
                dataIndex: "Incomplete Profile",
              },
              {
                name: "Yet to Be Verified",
                value: yet_to_be_verified,
                dataIndex: "Yet to Be Verified",
              },
              {
                name: "Available",
                value: available,
                dataIndex: "Available",
              },
              {
                name: "Employed",
                value: employer,
                dataIndex: "Employed",
              },
            ]);
            setChartData([
              {
                name: "Incomplete Profile",
                value: response.data.incomplete,
                color: "#EE3964",
              },
              {
                name: "Yet To Be Verified",
                value: response.data.yet_to_be_verified,
                color: "#F01CB5",
              },
              {
                name: "Available",
                value: response.data.available,
                color: "#34B53A",
              },
              {
                name: "Employed",
                value: response.data.employer,
                color: "#4339F2",
              },
            ]);
          } else {
            setObjData([
              {
                name: "Current Employees",
                value: response.data.current_employes_count,
                dataIndex: "currentEmployee",
              },
              {
                name: "Past Employees",
                value: 0,
                dataIndex: "Past Employees",
              },
              {
                name: "Unassigned",
                value: 0,
                dataIndex: "unassigned",
              },
              {
                name: "Ready for Employement",
                value: 0,
                dataIndex: "Ready for Employement",
              },
            ]);
            setChartData([
              {
                name: "Current Employees",
                value: response.data.current_employes_count,
                color: "#34B53A",
              },
              {
                name: "Past Employees",
                value: 0,
                color: "#EE3964",
              },
              {
                name: "Unassigned",
                value: 0,
                color: "#4339F2",
              },
              {
                name: "Ready for Employement",
                value: 0,
                color: "#F01CB5",
              },
            ]);
          }
          setTotalTalentValue(response.data.total_talent);
          message.destroy("Dashboard");
        }
      } catch (e) {
        console.log(e);
      }

      // .then((response) => {
      //   if (!isPrimary) {
      //     const { incomplete, yet_to_be_verified, available, employer } =
      //       response.data;
      //     setObjData([
      //       {
      //         name: "Incomplete Profile",
      //         value: incomplete,
      //         dataIndex: "Incomplete Profile",
      //       },
      //       {
      //         name: "Yet to Be Verified",
      //         value: yet_to_be_verified,
      //         dataIndex: "Yet to Be Verified",
      //       },
      //       {
      //         name: "Available",
      //         value: available,
      //         dataIndex: "Available",
      //       },
      //       {
      //         name: "Employed",
      //         value: employer,
      //         dataIndex: "Employed",
      //       },
      //     ]);
      //     setChartData([
      //       {
      //         name: "Incomplete Profile",
      //         value: response.data.incomplete,
      //         color: "#EE3964",
      //       },
      //       {
      //         name: "Yet To Be Verified",
      //         value: response.data.yet_to_be_verified,
      //         color: "#F01CB5",
      //       },
      //       {
      //         name: "Available",
      //         value: response.data.available,
      //         color: "#34B53A",
      //       },
      //       {
      //         name: "Employed",
      //         value: response.data.employer,
      //         color: "#4339F2",
      //       },
      //     ]);
      //   } else {
      //     setObjData([
      //       {
      //         name: "Current Employees",
      //         value: response.data.current_employes_count,
      //         dataIndex: "currentEmployee",
      //       },
      //       {
      //         name: "Past Employees",
      //         value: 0,
      //         dataIndex: "Past Employees",
      //       },
      //       {
      //         name: "Unassigned",
      //         value: 0,
      //         dataIndex: "unassigned",
      //       },
      //       {
      //         name: "Ready for Employement",
      //         value: 0,
      //         dataIndex: "Ready for Employement",
      //       },
      //     ]);
      //     setChartData([
      //       {
      //         name: "Current Employees",
      //         value: response.data.current_employes_count,
      //         color: "#34B53A",
      //       },
      //       {
      //         name: "Past Employees",
      //         value: 0,
      //         color: "#EE3964",
      //       },
      //       {
      //         name: "Unassigned",
      //         value: 0,
      //         color: "#4339F2",
      //       },
      //       {
      //         name: "Ready for Employement",
      //         value: 0,
      //         color: "#F01CB5",
      //       },
      //     ]);
      //   }
      //   setTotalTalentValue(response.data.total_talent);
      //   message.destroy("Dashboard");
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    }

    // if (
    //   !dashboardData.contractValue ||
    //   dashboardData.contractValue.length == 0
    // ) {
    //
    //   dashboard();
    // }
    dashboard();
  }, [dashboardData.contractValue]);

  useEffect(() => {
    var user_id = localStorage.getItem("uid");
    function is_spectra() {
      var empDetails = [];

      if (
        auth.isSpectra &&
        dashboardData.contractValue &&
        dashboardData.contractValue.length !== 0
      ) {
        if (dashboardData.filterValue <= 0) {
          message.loading({
            content: "Loading",
            key: "Dashboard",
            duration: 0,
          });
          axios
            .post(`${baseUrl}/spectrawiseSearchDashboard`, {
              title: "Dashboard_spectra_user",
              contractor_ids: dashboardData.contractValue,
              employer_ids: dashboardData.filterValue,
              filter: false,
              sortColumn: dashboardData.empTalSort
                ? [dashboardData.empTalSort]
                : null,
            })
            .then((response) => {
              setObjData([
                {
                  name: "Incomplete Profile",
                  value: response.data.incomplete,
                  dataIndex: "Incomplete Profile",
                },
                {
                  name: "Yet to Be Verified",
                  value: response.data.yet_to_be_verified,
                  dataIndex: "Yet to Be Verified",
                },
                {
                  name: "Available",
                  value: response.data.available,
                  dataIndex: "Available",
                },
                {
                  name: "Employed",
                  value: response.data.employer,
                  dataIndex: "Employed",
                },
              ]);
              setChartData([
                {
                  name: "Incomplete Profile",
                  value: response.data.incomplete,
                  color: "#EE3964",
                },
                {
                  name: "Yet To Be Verified",
                  value: response.data.yet_to_be_verified,
                  color: "#F01CB5",
                },
                {
                  name: "Available",
                  value: response.data.available,
                  color: "#34B53A",
                },
                {
                  name: "Employed",
                  value: response.data.employer,
                  color: "#4339F2",
                },
              ]);
              setTotalTalentValue(response.data.total_talent);
              message.destroy("Dashboard");
              const mapTalentData = (d) => {
                return {
                  key: d.key,
                  status: d.data.status.is_assigned ? "Assigned" : "Unassigned",
                  name: d.data.personal.full_name,
                  aadhaar: d.data.identity?.aadhaar.aadhaar_number,
                  employer: d.data.status.current_primary_employer_name,
                };
              };
              response.data.employerDetails.forEach((val) => {
                empDetails.push(mapTalentData(val));
              });
              if (empDetails.length > 0) {
                setTalentsData(empDetails);
              } else {
                setTalentsData([]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (auth.isSpectra && dashboardData.filterValue) {
          message.loading({
            content: "Loading",
            key: "Dashboard",
            duration: 0,
          });
          axios
            .post(`${baseUrl}/spectrawiseSearchDashboard`, {
              title: "Dashboard_spectra_user",
              contractor_ids: dashboardData.contractValue,
              employer_ids: dashboardData.filterValue,
              filter: true,
              size: 1000,
              sortColumn: dashboardData.empTalSort
                ? [dashboardData.empTalSort]
                : null,
            })
            .then((response) => {
              console.log("is_spectra filter", response);

              const mapTalentData = (d) => {
                return {
                  key: d.key,
                  status: d.data.status.is_assigned ? "Assigned" : "Unassigned",
                  name: d.data.personal.full_name,
                  aadhaar: d.data.identity?.aadhaar.aadhaar_number,
                  employer: d.data.status.current_primary_employer_name,
                };
              };

              response.data.employerDetails.forEach((val) => {
                empDetails.push(mapTalentData(val));
              });
              if (empDetails.length > 0) {
                setTalentsData(empDetails);
              } else {
                setTalentsData([]);
              }
              message.destroy("Dashboard");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (auth.isSpectra && dashboardData.filterValue) {
        message.loading({ content: "Loading", key: "Dashboard", duration: 0 });
        let obj;
        if (dashboardData.filterValue <= 0) {
          if (dashboardData.empTalSort) {
            obj = {
              title: "Dashboard_spectra_user",
              employer_ids: dashboardData.filterValue,
              filter: true,
              type: "initial",
              size: 10 + dashboardData.page * 10,
              sortColumn: [dashboardData.empTalSort],
              primaryEmployeerId: dashboardData?.filterValue || [],
            };
          } else {
            obj = {
              title: "Dashboard_spectra_user",
              employer_ids: dashboardData.filterValue,
              filter: true,
              from: dashboardData.page * 10,
              size: 10,
              type: "initial",
              sortColumn: null,
              primaryEmployeerId: dashboardData?.filterValue || [],
            };
          }
        } else {
          obj = {
            title: "Dashboard_spectra_user",
            employer_ids: dashboardData.filterValue,
            filter: true,
            type: "initial",
            size: 1000,
            sortColumn: dashboardData.empTalSort
              ? [dashboardData.empTalSort]
              : null,
            primaryEmployeerId: dashboardData?.filterValue || [],
          };
        }

        async function detchTalntEmployeeData() {
          try {
            const response = await axios.post(
              "https://urchin-app-fi4og.ondigitalocean.app/api/web/dashboard",
              obj
            );
            const mapTalentData = (d) => {
              return {
                key: d?.id,
                status: d?.PrimaryEmployeer?.name ? "Assigned" : "Unassigned",
                name: d?.personal?.full_name,
                aadhaar: d?.identity?.aadhaar.aadhaar_number,
                employer: d?.PrimaryEmployeer?.name,
              };
            };
            response.data.data.forEach((val) => {
              empDetails.push(mapTalentData(val));
            });
            if (empDetails.length > 0) {
              setTalentsData((pre) => {
                if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                  return [...pre, ...empDetails];
                } else {
                  return empDetails;
                }
              });
            } else {
              setTalentsData((pre) => {
                if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                  return [...pre, ...empDetails];
                } else {
                  return empDetails;
                }
              });
            }
            message.destroy("Dashboard");
          } catch (e) {
            console.log(e);
          }
        }

        detchTalntEmployeeData();
      }
    }

    async function is_employer() {
      message.loading({ content: "Loading", key: "Dashboard", duration: 0 });
      const snap = await getDoc(doc(db, `primary_employer_users`, user_id));
      const primaryEmployerId = snap.data().personal.contractor_id;
      var empDetails = [];
      let obj;
      if (dashboardData.filterValue <= 0) {
        if (dashboardData.empTalSort) {
          obj = {
            contractor_ids: dashboardData.filterValue,
            primary_emp_id: primaryEmployerId,
            size: 10 + dashboardData.page * 10,
            sortColumn: [dashboardData.empTalSort],
          };
        } else {
          obj = {
            contractor_ids: dashboardData.filterValue,
            primary_emp_id: primaryEmployerId,
            size: 10,
            from: dashboardData.page * 10,
            sortColumn: null,
          };
        }
      } else {
        obj = {
          filter: true,
          contractor_ids: dashboardData.filterValue,
          primary_emp_id: primaryEmployerId,
          size: 1000,
          sortColumn: dashboardData.empTalSort
            ? [dashboardData.empTalSort]
            : null,
        };
      }
      axios
        .post(`${baseUrl}/employerSearchDashboard`, obj)
        .then((response) => {
          console.log("is_employer", response);
          const mapTalentData = (d) => {
            return {
              key: d.key,
              status: d.data.status.is_assigned ? "Assigned" : "Unassigned",
              name: d.data.personal.full_name,
              aadhaar: d.data.identity?.aadhaar.aadhaar_number,
              // employer: d.data.status.current_primary_employer_name,
              contractor: d.data.status.current_contractor_name,
            };
          };
          console.log("res[ooc", response.data.employerDetails);
          response?.data?.employerDetails?.forEach((val) => {
            empDetails.push(mapTalentData(val));
          });
          if (empDetails.length > 0) {
            setTalentsData((pre) => {
              if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                return [...pre, ...empDetails];
              } else {
                return empDetails;
              }
            });
          } else {
            setTalentsData((pre) => {
              if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                return [...pre, ...empDetails];
              } else {
                return empDetails;
              }
            });
          }
          message.destroy("Dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function is_contractor() {
      var empDetails = [];

      const snap = await getDoc(doc(db, `contractor_users`, user_id));
      const contractorId = snap?.data()?.personal?.contractor_id;
      message.loading({ content: "Loading", key: "Dashboard", duration: 0 });
      let obj;
      if (dashboardData.filterValue <= 0) {
        if (dashboardData.empTalSort) {
          obj = {
            contractor_id: contractorId,
            primary_emp_ids: dashboardData.filterValue,
            size: 10 + dashboardData.page * 10,
            sortColumn: [dashboardData.empTalSort],
          };
        } else {
          obj = {
            contractor_id: contractorId,
            primary_emp_ids: dashboardData.filterValue,
            size: 10,
            from: dashboardData.page * 10,
            sortColumn: null,
          };
        }
      } else {
        obj = {
          filter: true,
          contractor_id: contractorId,
          primary_emp_ids: dashboardData.filterValue,
          size: 1000,
          sortColumn: dashboardData.empTalSort
            ? [dashboardData.empTalSort]
            : null,
        };
      }
      axios
        .post(`${baseUrl}/contractorSearchDashboard`, obj)
        .then((response) => {
          const mapTalentData = (d) => {
            return {
              key: d.key,
              status: d.data.status.is_assigned ? "Assigned" : "Unassigned",
              name: d.data.personal.full_name,
              aadhaar: d.data.identity?.aadhaar.aadhaar_number,
              employer: d.data.status.current_primary_employer_name,
            };
          };
          response.data.employerDetails.forEach((val) => {
            empDetails.push(mapTalentData(val));
          });
          console.log("empDetails", empDetails);
          if (empDetails.length > 0) {
            setTalentsData((pre) => {
              if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                return [...pre, ...empDetails];
              } else {
                return empDetails;
              }
            });
          } else {
            setTalentsData((pre) => {
              if (dashboardData.page > 0 && !dashboardData.empTalSort) {
                return [...pre, ...empDetails];
              } else {
                return empDetails;
              }
            });
          }
          message.destroy("Dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (auth.isSpectra) {
      is_spectra();
    } else if (auth.isPrimary) {
      is_employer();
    } else {
      is_contractor();
    }
  }, [
    // dashboardData.contractValue,
    dashboardData.filterValue,
    dashboardData.page,
    dashboardData.empTalSort,
    dashboardData.isEmpTalSort,
  ]);

  return (
    <div className="dashBoard" style={{ height: "100vh", overflow: "auto" }}>
      {!loading && (
        <>
          <DashBoardPieChart
            filter={contractFilter}
            objData={objData}
            chartData={chartData}
            totalTalent={totalTalentValue}
            filterOption={filterOption}
            userPath={userPath}
          />
          <DashBoardTalents
            talentsData={talentsData}
            talentsColumn={
              auth.isPrimary ? talentsDataColumnPrimary : talentsDataColumn
            }
            filterOption={filterOption}
            userPath={userPath}
          />
        </>
      )}
    </div>
  );
}

export default DashBoard;