import React, { useContext, useEffect, useState } from "react";
import "../../../styles/Panel.scss";
import { Tabs } from "antd";
import UserList from "./UserList";
import { dataContext } from "../../../context/data";
import ContractorList from "../../contractor/ContractorList";
import { useSelector } from "react-redux";
// import uploadImage from '../../../assets/icons/uploadIcon.svg'
// import downloadImage from '../../../assets/icons/downloadIcon.svg'
// import AssignTalent from './AssignTalent';
// import searchIcon from '../../../assets/icons/search.svg';

function PanelHead({ userType }) {
  const { userData, setUserData } = useContext(dataContext);

  const { TabPane } = Tabs;
  const tabStyle = {
    fontSize: "1.3vw !important",
    fontWeight: "600",
  };

  const contractTabs = [
    "Employed",
    "Available",
    "Incomplete profile",
    "Yet to be verified",
  ];

  let [isUserMenu, setUserMenu] = useState({
    talent: false,
    bizop: false,
    contractor: false,
    principaleEmployer: false,
  });

  let findMainMenuPermission = (permission) => {
    if (
      permission.Talent?.read ||
      permission.Talent?.write ||
      permission.Talent?.edit ||
      permission.Talent?.delete
    ) {
      setUserMenu((prev) => ({
        ...prev,
        talent: true,
      }));
    }
    if (
      permission.Bizops?.read ||
      permission.Bizops?.write ||
      permission.Bizops?.edit ||
      permission.Bizops?.delete
    ) {
      setUserMenu((prev) => ({
        ...prev,
        bizop: true,
      }));
    }
    if (
      permission.Contractors?.read ||
      permission.Contractors?.write ||
      permission.Contractors?.edit ||
      permission.Contractors?.delete
    ) {
      setUserMenu((prev) => ({
        ...prev,
        contractor: true,
      }));
    }
    if (
      permission["Principle Employer"]?.read ||
      permission["Principle Employer"]?.write ||
      permission["Principle Employer"]?.edit ||
      permission["Principle Employer"]?.delete
    ) {
      setUserMenu((prev) => ({
        ...prev,
        principaleEmployer: true,
      }));
    }
  };

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  useEffect(() => {
    if (permission && Object.keys(permission)) {
      findMainMenuPermission(permission);
    }
  }, [permission]);





  return (
    <>
      {userType === "spectra" && (
        <div className="tabs">
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey="1"
            activeKey={userData.activeTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => {
                return {
                  ...pre,
                  activeTabIndex: tabidx,
                  filterValue: [],
                  filterQuery: {},
                  page: 0,
                };
              })
            }
            className="tabSlider"
            size="large"
          >
            {isUserMenu?.talent && (
              <TabPane
                tabKey="1"
                className="tabSlider"
                style={tabStyle}
                tab="Talents"
                key="1"
              >
                <UserList userType={userType} talents={true} />
              </TabPane>
            )}
            {isUserMenu?.contractor && (
              <TabPane
                tabKey="2"
                className="tabSlider"
                style={tabStyle}
                tab="Contractors"
                key="2"
              >
                {/* <UserList userType={userType} contractor={true} /> */}
                <ContractorList />
              </TabPane>
            )}
            {isUserMenu?.principaleEmployer && (
              <TabPane
                tabKey="3"
                className="tabSlider"
                style={tabStyle}
                tab="Principle Employers"
                key="3"
              >
                <UserList userType={userType} contractor={true} />
              </TabPane>
            )}
            {isUserMenu?.bizop && (
              <TabPane
                tabKey="6"
                className="tabSlider"
                style={tabStyle}
                tab="Talntworx Bizops"
                key="6"
              >
                <UserList userType={userType} />
              </TabPane>
            )}
          </Tabs>
        </div>
      )}
      {userType === "contract" && (
        <div className="tabs">
          <div className="tabSlider tab-container-vmm">
            {contractTabs.map((name, i) => {
              return (
                <h1
                  className={
                    userData.activeContractTabIndex === `${i + 1}`
                      ? "tabs active"
                      : "tabs"
                  }
                  key={`${i + 1}`}
                  onClick={() =>
                    setUserData((pre) => {
                      return {
                        ...pre,
                        activeContractTabIndex: `${i + 1}`,
                        selectedRows: [],
                        filterValue: [],
                        filterQuery: {},
                        page: 0,
                      };
                    })
                  }
                >
                  {name}
                </h1>
              );
            })}
          </div>
          <UserList talents={true} userType="contract" />
        </div>
      )}
      {userType === "primary" && (
        <div className="tabs ">
          <Tabs
            defaultActiveKey="1"
            activeKey={userData.activePrimaryTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => {
                return {
                  ...pre,
                  activePrimaryTabIndex: tabidx,
                  filterValue: [],
                  filterQuery: {},
                  page: 0,
                };
              })
            }
            className="tabSlider"
            size="large"
          >
            <TabPane
              className="tabSlider"
              style={tabStyle}
              tab="Talents"
              key="1"
            >
              <UserList talents={true} userType={userType} />
            </TabPane>
            {/*<TabPane className='tabSlider' style={tabStyle} tab="Unassigned" key="2">
            <UserList talents={true} userType={userType} unassigned={true} />
        </TabPane>*/}
          </Tabs>
        </div>
      )}
    </>
  );
}

export default PanelHead;
