import React, { useState, useEffect, useCallback, useContext } from "react";
import editIcon from "../../assets/icons/icons8-edit.svg";
import viewIcon from "../../assets/icons/viewBtn.svg";
import deleteIcon from "../../assets/icons/icons8-delete.svg";
import { Table, Button, Input, Space, message, Select, Modal } from "antd";
import JobRoleForm from "./JobRoleForm";
import {
  deleteJobRole,
  fetchJobRolesList,
  getJobRoleById,
} from "../../services/jobRoleService";
import debounce from "lodash/debounce";
import "./JobRoleList.css";
import { useSelector } from "react-redux";
import { dataContext } from "../../context/data";
import JobRoleViewDetails from "./JobRoleViewDetails";
const { Search } = Input;

const { Option } = Select;

export default function JobRoleList(primaryEmployerId) {
  const [jobRoles, setJobRoles] = useState([]);
   const { searchState, setSearchState } = useContext(dataContext);
   const { userData, setUserData } = useContext(dataContext);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [type, setType] = useState("create");
  const [page, setPage] = useState(1);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  // const [searchField, setSearchField] = useState("All");
  // const [searchTerm, setSearchTerm] = useState("");
console.log(searchState, "searchState")
console.log(selectedRole, "selectedRolesVlaues")
  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  const fetchJobRoles = useCallback(
    async (pageNum) => {
      setLoading(true);
      try {
        const response = await fetchJobRolesList(
          pageNum,
          10,
          searchState.searchField,
          searchState.searchTerm,
          primaryEmployerId.primaryEmployerId
        );
        setJobRoles(pageNum === 1 ? response : [...jobRoles, ...response]);
        setPage(pageNum);
      } catch (error) {
        message.error("Failed to load job roles");
      } finally {
        setLoading(false);
      }
    },
    [searchState.searchField, searchState.searchTerm]
  );

  useEffect(() => {
    fetchJobRoles(1);
  }, [fetchJobRoles]);

  const handleEditClick = async (roleId) => {
    try {
      setLoading(true);
      const roleDetails = await getJobRoleById(roleId);
      setSelectedRole(roleDetails);
      setType("edit");
      setIsModalVisible(true);
    } catch (error) {
      message.error("Failed to load job role details");
    } finally {
      setLoading(false);
    }
  };

  // const handleNewJobRole = () => {
  //   setSelectedRole(null);
  //   setType("create");
  //   setIsModalVisible(true);
  // };

  useEffect(() => {
    if (userData.isJobRoleModalVisible) {
      setIsModalVisible(true);
      setSelectedRole(null);
      setType("create");
    }
  }, [userData.isJobRoleModalVisible]);

  const handleDeleteClick = (role) => {
    setSelectedRole(role);
    setDeleteConfirmVisible(true);
  };

  const handleViewClick = async (roleId) => {
    //setLoading(true);
    const roleDetails = await getJobRoleById(roleId);
    setSelectedRole(roleDetails);
    setIsViewModalVisible(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteJobRole(selectedRole);
      fetchJobRoles(1);
      // setJobRoles(jobRoles.filter((role) => role.id !== selectedRole.id));
      message.success("Job role deleted successfully");
    } catch (error) {
      message.error("Failed to delete job role");
    } finally {
      setDeleteConfirmVisible(false);
    }
  };

  const handleCreateSuccess = (newRole) => {
    // message.success(
    //   `Job role ${type === "edit" ? "updated" : "created"} successfully`
    // );
    setIsModalVisible(false);
    setUserData((prev) => ({
      ...prev,
      isJobRoleModalVisible: false, // Example: Show a modal or perform an action
    }));
    setSelectedRole(null);
    fetchJobRoles(1);
  };

   const columns = [
    { title: "DESIGNATION", dataIndex: "designation", key: "designation" },
    { title: "DEPARTMENT", dataIndex: "department", key: "department" },
  ];

  {
    (permission?.["Job Role"]?.edit ||
      permission?.["Job Role"]?.delete) &&
        columns.push({
          title: "Actions",
          key: "actions",
          render: (_, record) => (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {permission?.["Job Role"]?.edit && (
                <img
                  src={editIcon}
                  alt="Edit"
                  style={{
                    cursor: "pointer",
                    width: "4%",
                  }}
                  onClick={() => handleEditClick(record.id)}
                />
              )}
              {permission?.["Job Role"]?.delete && (
                <img
                  src={deleteIcon}
                  alt="Delete"
                  style={{
                    cursor: "pointer",
                    width: "5%",
                  }}
                  onClick={() => handleDeleteClick(record.id)}
                />
              )}
              {permission?.["Job Role"]?.read && (
                <img
                  src={viewIcon}
                  alt="View"
                  style={{
                    cursor: "pointer",
                    width: "5%",
                  }}
                  onClick={() => handleViewClick(record.id)}
                />
              )}
            </div>
          ),
        });
  }

  return (
    <div className="job-role-list">
      <div
        className="flex justify-between items-center mb-4"
        style={{ margin: "15px 0 0 10px" }}
      >
        <Space>
          {/* <Select
            style={{ width: 150 }}
            value={searchField}
            onChange={(value) => setSearchField(value)}
          >
            <Option value="All">All</Option>
            <Option value="department">Department</Option>
            <Option value="designation">Designation</Option>
          </Select>
          <Input
            placeholder="Search job roles..."
            style={{ width: 200 }}
            onChange={debounce((e) => setSearchTerm(e.target.value), 300)}
          /> */}
          {/* <div className="searchwraper" style={{width:"375px"}}>
         <Select
                  defaultValue={"all"}
                  size="large"
                  className="searchSelect"
                  listHeight={200}
                  value={searchField}
                  dropdownClassName="searchOption"
                  dropdownMatchSelectWidth={false}
                  defaultActiveFirstOption={true}
                  onChange={(value) => setSearchField(value)}
                  allowClear
                >
                 
                  
                  <Option value="All">All</Option>
            <Option value="department">Department</Option>
            <Option value="designation">Designation</Option>
                </Select>
        <Search
              placeholder="Search"
              size="large"
              allowClear
             // value={filterText.value}
              // id="searchTextInput"
              style={{ marginRight: "20px", width: "15vw" }}
              // className="searchBox"
              onChange={debounce((e) => setSearchTerm(e.target.value), 300)}
              //onSearch={(value) => handleSearchValueUpdate(value)}
              enterButton
            />
            </div> */}
          {/* <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              // margin: "0 0 0 675px",
              // marginLeft:"53rem",
              marginLeft:"47rem",
            }}
          >
            {permission?.["Job Role"]?.write && (
              <Button
                type="primary"
                onClick={handleNewJobRole}
                size="large"
                style={{
                  padding: "6px 15px",
                  fontSize: "16px",
                  height: "47px",
                }}
              >
                New Job Role
              </Button>
            )}
          </div> */}
        </Space>
        
      </div>
      <div className={"table-wrapper"}>
        <Table
          columns={columns}
          dataSource={jobRoles}
          rowKey="id"
          loading={loading}
          pagination={false}
          rowClassName={"table-row-light"}
          scroll={{ y: window.innerHeight - 200 }}
        />
      </div>
      {isModalVisible && (
        <JobRoleForm
          primaryEmployerId={primaryEmployerId}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSuccess={handleCreateSuccess}
          type={type}
          id={selectedRole?.id}
          data={selectedRole}
        />
      )}

      <Modal
        title="Confirm Delete"
        visible={deleteConfirmVisible}
        onOk={confirmDelete}
        onCancel={() => setDeleteConfirmVisible(false)}
        width={400}
        bodyStyle={{
          textAlign: "center",
          // padding: "16px",
          // marginBottom: "-10px",
          //marginBottom:"-20px"
           marginBottom:"-32px"
        }}
        style={{ top: 100 }}
      >
        <p
          style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}
        >
          Are you sure you want to delete this job role?
        </p>
      </Modal>

      <JobRoleViewDetails visible={isViewModalVisible} onClose={() => setIsViewModalVisible(false)} data={selectedRole} />
    </div>
  );
}
