import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Space,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  fetchStatesAndDistricts,
  createJobPost,
  fetchPrimaryEmployer,
  updateJobPost,
} from "../../services/jobPostService.js";
import moment from "moment";
import GoBack from "../../assets/icons/leftArrow.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { dataContext } from "../../context/data.js";

export default function JobPostForm({
  visible,
  onClose,
  onSuccess,
  type,
  data,
  id = "",
}) {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [employers, setEmployers] = useState([]);
  const [statesData, setStatesData] = useState({});
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstPageData, setFirstPageData] = useState(null);
  const [fields, setFields] = useState([{ value: "" }]);
  const history = useHistory();
  const { userData, setUserData } = useContext(dataContext);

  useEffect(() => {
    if (fields.length === 0) {
      setFields([{ value: "" }]);
    }
    form.setFieldsValue({ joiningFormalities: fields });
  }, [fields, form]);

  useEffect(() => {
    if (type === "edit" && data) {
      if (statesData && statesData[data.state]) {
        setDistricts(statesData[data.state]); // Populate districts first
      } else {
        setDistricts([]); // Clear districts if state is invalid
      }

      form.setFieldsValue({
        ...data,
        state: data.state,
        district: data.district || undefined, // Ensure district is set
      });
    }
  }, [type, data, statesData, form]);

  useEffect(() => {
    if (type === "edit" && data) {
      if (Object.values(data?.joiningFormalities).length) {
        setFields(data?.joiningFormalities);
      }
      form.setFieldsValue({
        ...data,
        state: data.state,
        district: data.district,
        experience: {
          from: data.experienceFrom,
          to: data.experienceTo,
          period: data.experiencePerPeriod,
        },
        salary: {
          from: data.salaryFrom,
          to: data.salaryTo,
          period: data.salaryPerPeriod,
        },
        primaryEmployer: data.primaryEmployeerId,
        startDate: data.startDate,
      });
      if (statesData && statesData[data.state]) {
        setDistricts(statesData[data.district]);
      } else {
        setDistricts([]);
      }
      setFirstPageData(data);
      handleStateChange(data.state);
    }
  }, [type, data, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [employersData, statesDistrictsData] = await Promise.all([
          fetchPrimaryEmployer(),
          fetchStatesAndDistricts(),
        ]);

        setEmployers(employersData || []);
        setStatesData(statesDistrictsData || {});
      } catch (err) {
        setError("Failed to fetch data");
        message.error("Failed to load form data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleGoBack = () => {
    setCurrentStep(1);
  };

  const handleStateChange = (value) => {
    form.setFieldsValue({ district: undefined });
    if (statesData && statesData[value]) {
      setDistricts(statesData[value]);
    } else {
      setDistricts([]);
    }
  };

  // const handleSubmit = async (values) => {
  //   if (currentStep === 1) {
  //     setFirstPageData(values);
  //     setCurrentStep(2);
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const { experience, salary, ...restValues } = firstPageData;
  //     const finalValues = {
  //       ...restValues,
  //       ...Object.fromEntries(
  //         Object.entries({
  //           ...values,
  //           questions: values.questions || [],
  //           experienceFrom: experience?.from,
  //           experienceTo: experience?.to,
  //           experiencePerPeriod: experience?.period,
  //           salaryFrom: salary?.from,
  //           salaryTo: salary?.to,
  //           salaryPerPeriod: salary?.period,
  //           primaryEmployeerId: firstPageData.primaryEmployer,
  //         }).filter(([_, value]) => value !== undefined)
  //       ),
  //     };
  //     let response;
  //     if (type === "edit") {
  //       response = await updateJobPost(id, finalValues);
  //     } else {
  //       response = await createJobPost(finalValues);
  //     }
  //     if(response?.status === 200){
  //       message.success((response?.message === "Success") ? "Job post created successfully" : response?.message);
  //     }
  //     setUserData((prev) => ({ ...prev, reload: !prev.reload }));
  //     //message.success("Job post created successfully");
  //     form.resetFields();
  //     setCurrentStep(1);
  //     setFirstPageData(null);
  //     onClose();
  //     if (onSuccess) {
  //       onSuccess(response);
  //     }
  //   } catch (error) {
  //     console.error("API Error:", error);

  //     // Extract the error message properly
  //     const errorMessage =
  //       error?.response?.data?.message || // Access the API response error message
  //       error?.message || // Default to the error's message property
  //       "An unexpected error occurred"; // Fallback message

  //     message.error(errorMessage); // Display the error message in the UI
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (values) => {
    if (currentStep === 1) {
      setFirstPageData(values);

      // Delay updating the step to avoid triggering ResizeObserver issues
      setTimeout(() => {
        setCurrentStep(2);
      }, 0);

      return;
    }

    try {
      setLoading(true);
      const { experience, salary, ...restValues } = firstPageData;
      const finalValues = {
        ...restValues,
        ...Object.fromEntries(
          Object.entries({
            ...values,
            questions: values.questions || [],
            experienceFrom: experience?.from,
            experienceTo: experience?.to,
            experiencePerPeriod: experience?.period,
            salaryFrom: salary?.from,
            salaryTo: salary?.to,
            salaryPerPeriod: salary?.period,
            primaryEmployeerId: firstPageData.primaryEmployer,
          }).filter(([_, value]) => value !== undefined)
        ),
      };

      let response;
      if (type === "edit") {
        response = await updateJobPost(id, finalValues);
      } else {
        response = await createJobPost(finalValues);
      }

      if (response?.status === 200) {
        message.success(response?.message === "Success" ? "Job post created successfully" : response?.message);
      }

      setUserData((prev) => ({ ...prev, reload: !prev.reload }));
      form.resetFields();
      setCurrentStep(1);
      setFirstPageData(null);
      onClose();

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      console.error("API Error:", error);
      const errorMessage = error?.response?.data?.message || error?.message || "An unexpected error occurred";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrentStep(1);
    setFirstPageData(null);
    onClose();
  };

  const handleInputChange = (index, e) => {
    const newFields = [...fields];
    newFields[index].value = e.target.value;
    setFields(newFields);
  };

  const addField = () => {
    setFields([...fields, { value: "" }]);
  };

  const removeField = (index) => {
    if (fields.length > 1) {
      const newFields = fields.filter((_, i) => i !== index);
      setFields(newFields);
    }
  };

  const isAddDisabled = (index) => {
    return index !== fields.length - 1 || fields[index].value.trim() === "";
  };

  const isRemoveDisabled = (index) => {
    return fields.length === 1;
  };

  const disabledDate = (current) => {
    return current && current.isBefore(moment().startOf("day"));
  };

  const datePickerProps = {
    disabledDate,
    showToday: true,
    format: "YYYY-MM-DD",
    inputReadOnly: true,
  };

  const renderFirstStep = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <div className="form-field-container">
            <label>Designation</label>
            <Form.Item
              name="designation"
              rules={[
                { required: true, message: "Please enter designation" },
                { max: 50, message: "Designation cannot exceed 50 characters" },
              ]}
            // className="form-item-with-error"
            >
              <Input placeholder="Enter job title" maxLength={50} />
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div className="form-field-container">
            <label>Primary Employer</label>
            <Form.Item
              // className="form-item-with-error"
              name="primaryEmployer"
              rules={[
                { required: true, message: "Please select primary employer" },
              ]}
            >
              <Select
                placeholder="Select Primary Employer"
                loading={loading}
                disabled={loading || error}
              >
                {error && (
                  <Select.Option disabled value="error">
                    {error}
                  </Select.Option>
                )}
                {employers.map((employer) => (
                  <Select.Option key={employer.id} value={employer.id}>
                    {employer.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div className="form-field-container">
            <label>Description</label>
            <Form.Item
              // className="form-item-with-error"
              name="description"
              rules={[{ required: true, message: "Please enter description" }]}
             style={{paddingTop:"15px"}}
            >
              <Input.TextArea placeholder="Max 15 words" maxLength={100} style={{bottom:"15px"}} />
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div className="form-field-container">
            <label>Qualification</label>
            <Form.Item
              // className="form-item-with-error"
              name="qualification"
              rules={[
                { required: true, message: "Please select qualification" },
              ]}
            >
              <Select mode="multiple" placeholder="Select qualification" style={{ height: "0px !important" }}>
                <Select.Option value="SSC">SSC(10th)</Select.Option>
                <Select.Option value="HSC">HSC(12th)</Select.Option>
                <Select.Option value="Diploma">Diploma</Select.Option>
                <Select.Option value="Bachelors">Bachelors</Select.Option>
                <Select.Option value="Masters">Masters</Select.Option>
                <Select.Option value="Any">Any</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Row gutter={24} align="middle">
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "22px", margin: '0 0 0 12px' }}>
            <Col span={6}>
              <div style={{ display: "flex", flexDirection: "column", width: "250px" }}>
                <label>Experience Required</label>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", minHeight: "60px" }}>
                  <Form.Item
                    name={["experience", "from"]}
                    // rules={[{ required: true, message: "Required" },  { max: 2, message: "Maximum 2 digits allowed" }]}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value && value !== 0) {
                            return Promise.reject(new Error("Required"));
                          }
                          if (value > 99) {
                            return Promise.reject(new Error("Maximum 2 digits allowed"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <InputNumber min={0} placeholder="00" style={{ width: '100%' }} />
                  </Form.Item>
                  <span>to</span>
                  <Form.Item
                    name={["experience", "to"]}
                    dependencies={[["experience", "from"]]}
                    // rules={[
                    //   { required: true, message: "Required" }, { max: 2, message: "Maximum 2 digits allowed" },
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       const from = getFieldValue(["experience", "from"]);
                    //       if (!value || !from || value > from) {
                    //         return Promise.resolve();
                    //       }
                    //       return Promise.reject(
                    //         new Error("Don't enter the same value")
                    //       );
                    //     },
                    //   }),
                    // ]}
                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator: (_, value) => {
                    //       const fromValue = getFieldValue(["experience", "from"]);

                    //       if (value === undefined || value === null || value === "") {
                    //         return Promise.reject(new Error("Required"));
                    //       }
                    //       if (value > 99) {
                    //         return Promise.reject(new Error("Maximum 2 digits allowed"));
                    //       }
                    //       if (fromValue !== undefined && fromValue !== null && value <= fromValue) {
                    //         return Promise.reject(new Error("Don't enter the same value"));
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          const fromValue = getFieldValue(["experience", "from"]);

                          // Convert to numbers (to avoid incorrect string comparison)
                          const fromNum = Number(fromValue);
                          const toNum = Number(value);

                          if (value === undefined || value === null || value === "") {
                            return Promise.reject(new Error("Required"));
                          }
                          if (toNum > 99) {
                            return Promise.reject(new Error("Maximum 2 digits allowed"));
                          }
                          if (!isNaN(fromNum) && !isNaN(toNum) && toNum <= fromNum) {
                            return Promise.reject(new Error("Experience 'to' should be greater than 'from'"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}

                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <InputNumber min={0} placeholder="00" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name={["experience", "period"]}
                    rules={[{ required: true, message: "Required" }]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <Select placeholder="Period" style={{ width: "100%", height: "40px" }}>
                      <Select.Option value="month">Month</Select.Option>
                      <Select.Option value="year">Year</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: "20px", width: "160px" }}>
                <label>Start Date</label>
                <Form.Item
                  name="startDate"
                  rules={[{ required: true, message: "Please select a start date" }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    type="date"
                    placeholder="Select date"
                    style={{
                      width: "100%",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      border: "1px solid #d9d9d9",
                      fontSize: "14px",
                    }}
                  />
                  {/* <DatePicker
        placeholder="Select date"
        style={{
          width: "100%",
          padding: "4px 8px",
          borderRadius: "4px",
          border: "1px solid #d9d9d9",
          fontSize: "14px",
          height:"40px"
        }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode} // Fix dropdown positioning
      /> */}
                </Form.Item>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: "23px", width: "300px" }}>
                <label>Salary</label>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Form.Item
                    name={["salary", "from"]}
                    // rules={[{ required: true, message: "Required" }]}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value === undefined || value === null || value === "") {
                            return Promise.reject(new Error("Required"));
                          }
                          // if (value > 99) {
                          //   return Promise.reject(new Error("Maximum 2 digits allowed"));
                          // }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <InputNumber
                      min={0}
                      placeholder="₹ 00"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <span style={{ whiteSpace: "nowrap" }}>to</span>
                  <Form.Item
                    name={["salary", "to"]}
                    dependencies={[["salary", "from"]]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          const fromValue = getFieldValue(["salary", "from"]);

                          if (value === undefined || value === null || value === "") {
                            return Promise.reject(new Error("Required"));
                          }
                          // if (value > 99) {
                          //   return Promise.reject(new Error("Maximum 2 digits allowed"));
                          // }
                          if (fromValue !== undefined && fromValue !== null && value <= fromValue) {
                            return Promise.reject(new Error("Salary 'to' should be greater than 'from'"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    // rules={[
                    //   { required: true, message: "Required" },
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       const from = getFieldValue(["salary", "from"]);
                    //       if (!value || !from || value > from) {
                    //         return Promise.resolve();
                    //       }
                    //       return Promise.reject(
                    //         new Error("Don't enter the same value")
                    //       );
                    //     },
                    //   }),
                    // ]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <InputNumber
                      min={0}
                      placeholder="₹ 00"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={4}>
              {/* Salary Period */}
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: "0px", height: "63px", width: "113px" }}>
                <label>Period</label>
                <Form.Item
                  name={["salary", "period"]}
                  rules={[{ required: true, message: "Required" }]}
                  style={{ marginBottom: 0 }}
                >
                  <Select placeholder="Period" style={{ width: "100%" }}>
                    <Select.Option value="day">Per Day</Select.Option>
                    <Select.Option value="month">Per Month</Select.Option>
                    <Select.Option value="year">Per Year</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </div>
        </Row>


        <Col span={12}>
          <div className="form-field-container">
            <label>States</label>
            <Form.Item
              name="state"
              // className="form-item-with-error"
              rules={[{ required: true, message: "Please select state" }]}
            >
              <Select
                placeholder="Select State"
                onChange={handleStateChange}
                loading={loading}
              >
                {Object.keys(statesData).map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div className="form-field-container">
            <label>District</label>
            <Form.Item
              name="district"
              rules={[{ required: true, message: "Please select district" }]}
            >
              <Select
                placeholder="Select District"
                disabled={!districts.length}
              >
                {districts.map((district) => (
                  <Select.Option key={district} value={district}>
                    {district}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>


        <Col span={24}>
          <div className="form-field-container">
            <label>Joining Formalities</label>
            <Form.List name="joiningFormalities">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={index} style={{ marginBottom: "24px" }}>
                      <div style={{ display: "flex" }}>
                        {/* <div>{`${index + 1}.`}</div> */}
                        <div style={{ width: "100%" }}>
                          <Form.Item
                            name={[field.name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: `Enter detail`,
                              },
                            ]}
                            style={{ marginBottom: "8px" }}
                          >
                            <Input
                              placeholder="Enter details"
                              defaultValue={
                                type === "edit" &&
                                  data?.joiningFormalities &&
                                  data.joiningFormalities[index]
                                  ? data.joiningFormalities[index].value
                                  : ""
                              }
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Item>
                          <Space size="small" style={{ margin: "18px 0 0 0" }}>
                            <Button
                              type="primary"
                              onClick={() => {
                                addField();
                                add();
                              }}
                              disabled={isAddDisabled(index)}
                              style={{
                                width: "20px",
                                height: "20px",
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: isAddDisabled(index)
                                  ? "1px solid #d9d9d9"
                                  : "none",
                                backgroundColor: !isAddDisabled(index)
                                  ? "rgb(3 97 254)"
                                  : "",
                                color: !isAddDisabled(index)
                                  ? "white"
                                  : "rgb(183 183 183)",
                              }}
                            >
                              +
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                removeField(index);
                                remove(index);
                              }}
                              disabled={isRemoveDisabled(index)}
                              style={{
                                width: "20px",
                                height: "20px",
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </Button>
                          </Space>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </div>
        </Col>

      </Row>
    </>
  );

  const renderSecondStep = () => (
    <Form.List name="questions">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <div key={field.key} className="question-container">
              <Form.Item
                label="Custom question"
                required={false}
                className="question-form-item"
              >
                <Form.Item
                  {...field}
                  name={[field.name, "question"]}
                  validateTrigger={["onBlur"]}
                  rules={[
                    {
                      whitespace: true,
                      message: "Please input your question",
                    },
                  ]}
                  noStyle
                >
                  <Input.TextArea
                    placeholder="Enter your question"
                    className="question-input"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label="Answer Type" className="answer-type-form-item">
                <Form.Item
                  {...field}
                  name={[field.name, "answerType"]}
                  rules={[
                    {
                      message: "Please select answer type",
                    },
                  ]}
                  noStyle
                >
                  <Select placeholder="Select">
                    <Select.Option value="text">Text Box</Select.Option>
                    <Select.Option value="numeric">Numeric</Select.Option>
                    <Select.Option value="yesno">Yes/No</Select.Option>
                  </Select>
                </Form.Item>
              </Form.Item>

              <div className="question-actions" style={{ display: 'flex', gap: '10px' }}>
                <Button
                  type="default"
                  onClick={() => remove(field.name)}
                  className="remove-button"
                  style={{
                    width: "20px",
                    height: "20px",
                    padding: 0,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >-</Button>
                <Button
                  type="primary"
                  style={{
                    width: '20px',
                    height: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'inline-flex',
                    padding: 0

                  }}
                  onClick={() => add()}
                  className="add-button"
                >+</Button>
              </div>
            </div>
          ))}

          {fields.length === 0 && (
            <Form.Item>
              <Button
                type="primary"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="initial-add-button"
              >
                Add Question
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );

  return (
    <Modal
      className="jobpost-modal"
      bodyStyle={{
        overflowX: "hidden",
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)",
        paddingRight: "14px",
      }}
      title={
        <div style={{ textAlign: "center", fontSize: "25px" }}>
          {currentStep === 2 && (
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => setCurrentStep(1)}>
              <img
                src={GoBack}
                alt="go back"
                style={{ width: "20px", height: "20px", objectFit: "contain", marginRight: "8px" }}
              />
              <span
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Back
              </span>
            </div>
          )}
          {`${type === "edit" ? "Edit" : "New"} Job Post (${currentStep}/2)`}
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={[
        // currentStep === 2 && 
        // (
        //   <Button key="back" onClick={handleGoBack}>
        //     Go Back
        //   </Button>
        // ),
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {currentStep === 1 ? "Continue" : "Submit"}
        </Button>,
      ]}
      width={900}
    >
      <Form
        className="jobpost-modal-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark
        initialValues={{
          ...(type === "edit" && data
            ? {
              ...data,
              experience: {
                from: data.experienceFrom,
                to: data.experienceTo,
                period: data.experiencePerPeriod,
              },
              salary: {
                from: data.salaryFrom,
                to: data.salaryTo,
                period: data.salaryPerPeriod,
              },
              district: data.district,
              primaryEmployer: data.primaryEmployeerId,
              startDate: data.startDate ? moment(data.startDate) : moment(),
              questions: data.questions || [{ question: "", answerType: "" }],
              joiningFormalities: data.joiningFormalities || [""],
            }
            : {
              questions: [{ question: "", answerType: "" }],
              joiningFormalities: [""],
            }),
        }}
      >
        {currentStep === 1 ? renderFirstStep() : renderSecondStep()}
      </Form>
    </Modal>
  );
}
