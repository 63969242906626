import axiosClient from "../../axiosConfig";

const baseUrl = "https://urchin-app-fi4og.ondigitalocean.app/api/web";

// const baseUrl = "http://localhost:8000/api/web";

export const getNewDashboardData = async function (url) {
  try {
    const data = await axiosClient.get(`/dashboard/${url}`);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getNewDashboardDataForPE = async function (id) {
  try {
    const response = await axiosClient.post(
      `${baseUrl}/dashboard/newDashboardForPE`,
      { id }
    );
    return response;
  } catch (e) {
    console.error("Error fetching dashboard data for PE:", e);
    return null;
  }
};

export const getWhatsUpData = async function () {
  try {
    const data = await axiosClient.get(`${baseUrl}/whatsMessages/`);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const whatsAppMessageSender = async function (url, data, name, role) {
  try {
    const dataResponse = await axiosClient.post(`${baseUrl}/dashboard/${url}`, data);
    
    if (dataResponse.status === 200 || dataResponse.status === 201) {
      const { message, primaryContractorId, primaryEmployerId, status } = data;
      const postResponse = await axiosClient.post(
        `${baseUrl}/whatsMessages/whatsappMessageDetails`,
        {
          createdBy: name,
          role: role,
          message,
          contractors: primaryContractorId || [],
          primary_employers: primaryEmployerId || [],
          recipients: status,
        }
      );

      console.log("Post response:", postResponse.data);
      return postResponse.data;
    }
    return dataResponse;
  } catch (e) {
    console.error("Error in whatsAppMessageSender:", e.response?.data || e.message);
    throw e; // Rethrow for further handling
  }
};

export const getRecipientList = async function (recipients) {
  try {
    const response = await axiosClient.post(
      `${baseUrl}/whatsMessages/recipientLists`, // Keep the endpoint same
      { recipients } // Send recipients inside the request body
    );
    return response.data.data;
  } catch (e) {
    console.error("Error fetching recipient list:", e);
  }
};

export const fetchMenuOption = async function (url) {
  try {
    const fetchMenu = await axiosClient.get(url);
    return fetchMenu;
  } catch (e) {
    console.log(e);
  }
};

export const fetchAllRole = async function (url) {
  try {
    const fetchMenu = await axiosClient.get(url);
    return fetchMenu;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEachRole = async function (url) {
  try {
    const fetchMenu = await axiosClient.get(url);
    return fetchMenu;
  } catch (e) {
    console.log(e);
  }
};

export const newRoleCreate = async function (url, data) {
  try {
    const fetchMenu = await axiosClient.post(url, data);
    return fetchMenu;
  } catch (e) {
    console.log(e);
  }
};

export const updateRole = async function (url, data) {
  try {
    const fetchMenu = await axiosClient.put(url, data);
    return fetchMenu;
  } catch (e) {
    console.log(e);
  }
};

export const deleteRole = async function (url, data) {
  try {
    const deleteRole = await axiosClient.delete(url, data);
    return deleteRole;
  } catch (e) {
    console.log(e);
  }
};

export const createNewRolePermission = async function (url, data) {
  const res = axiosClient.put(`${baseUrl}${url}`, data);
};

export const fetchOperations = (url) => {
  const res = axiosClient.get(url);
  return res;
};
