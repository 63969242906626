import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import {
  Modal,
  Table,
  Button,
  Input,
  Space,
  Spin,
  Select,
  message,
} from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import ContractorForm from "./ContractorForm";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import {
  fetchContractorsList,
  deleteContractor,
} from "../../services/contractService";
import ResponsiveTable from "../panel/panelHead/ResponsiveTable";
import "./ContractorDetail.scss";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { dataContext } from "../../context/data";

const { Option } = Select;
const { Search } = Input;

const ContractorList = () => {
  const [contractors, setContractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingContractor, setEditingContractor] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const history = useHistory();
  const observer = useRef();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const { userData, setUserData } = useContext(dataContext);

  const showDeleteConfirm = (id) => {
    setSelectedContractorId(id);
    setDeleteConfirmVisible(true);
  };

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const loadContractors = useCallback(
    async (pageNum) => {
      if (!hasMore && pageNum > 1) return;

      if (pageNum === 1) setInitialLoading(true); 
      setLoading(true);
      try {
        const response = await fetchContractorsList(
          pageNum,
          10,
          searchField,
          searchTerm
        );
        const newContractors = response.data;
        const total = response.total || newContractors.length;

        setContractors((prevContractors) =>
          pageNum === 1
            ? newContractors
            : [...prevContractors, ...newContractors]
        );
        setTotalItems(total);
        setHasMore(newContractors.length === 10);
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading contractors:", error);
        message.error("Failed to load contractors");
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    },
    [searchField, searchTerm]
  );

  const debouncedSearch = useCallback(
    debounce(() => {
    //  setContractors([]);
      setPage(1);
      setHasMore(true);
      loadContractors(1);
    }, 300),
    [loadContractors]
  );

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchField, searchTerm, debouncedSearch]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      loadContractors(page + 1);
    }
  };

  const handleCreate = () => {
    setEditingContractor(null);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteContractor(id);
      message.success("Contractor deleted successfully");
      setContractors([]);
      setPage(1);
      setHasMore(true);
      loadContractors(1);
    } catch (error) {
      console.error("Error deleting contractor:", error);
      message.error("Failed to delete contractor");
    } finally {
      setDeleteConfirmVisible(false);
    }
  };

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });

  const columns = useMemo(() => {
    const baseColumns = [
      { title: "BusinessName", dataIndex: "name", key: "name", width: 200 },
      {
        title: "CompanyType",
        dataIndex: "companyTypeName",
        key: "companyTypeName",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        ellipsis: true,
      },
      { title: "PAN Number", dataIndex: "panNumber", key: "panNumber" },
      { title: "GSTIN Number", dataIndex: "gstinNumber", key: "gstinNumber" },
      {
        title: "ESIC REG Number",
        dataIndex: "esicRegNumber",
        key: "esicRegNumber",
      },
      {
        title: "EPF REG Number",
        dataIndex: "epfRegNumber",
        key: "epfRegNumber",
      },
      { title: "TAN Number", dataIndex: "tanNumber", key: "tanNumber" },
    ];

    if (permission?.Contractors?.delete) {
      baseColumns.push({
        title: "ACTIONS",
        key: "actions",
        render: (_, record) => (
          <Space>
            <Button
              type="text"
              icon={
                <svg
                  fill="#FA5252"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16px"
                  height="16px"
                >
                  <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
                </svg>
              }
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id);
              }}
              className="text-red-500 hover:text-red-600"
              style={{
                padding: "4px",
                fontSize: "12px",
              }}
              shape="circle"
            />
          </Space>
        ),
      });
    }

    return baseColumns;
  }, [permission?.Contractors.delete]);

  const handleRowClick = (record) => {
    history.push(`userPanel/contractorTabs/user/${record.id}`);
  };

  return (
    <div className="contractor-list-container h-screen flex flex-col">
      <div className="search-header" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 28px 0 10px" }}>
        {/* <Space size="middle" className="flex-grow mb-4">
          <Select
            style={{ width: 150 }}
            value={searchField}
            onChange={(value) => handleSearch(value, "field")}
          >
            <Option value="All">All</Option>
            <Option value="name">Business Name</Option>
            <Option value="companyType">Company Type</Option>
            <Option value="address">Address</Option>
            <Option value="panNumber">PAN Number</Option>
            <Option value="gstinNumber">GSTIN Number</Option>
            <Option value="epfRegNumber">EPF Reg. Number</Option>
            <Option value="esicRegNumber">ESIC Reg. Number</Option>
            <Option value="ptRegNumber">PT Reg. Number</Option>
            <Option value="tanNumber">TAN Number</Option>
          </Select>
          <Input
            placeholder="Search contractors..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value, "term")}
            style={{ width: 200 }}
          />
        </Space> */}
        <div className="searchwraper" style={{ display: "flex", alignItems: "center", width: "50%" }}>
        {userData.activeTabIndex !== "2" && (
                 <Select
                          defaultValue={"all"}
                          size="large"
                          className="searchSelect"
                          listHeight={200}
                          value={searchField}
                          dropdownClassName="searchOption"
                          dropdownMatchSelectWidth={false}
                          defaultActiveFirstOption={true}
                          onChange={(value) => handleSearch(value, "field")}
                          allowClear
                        >
                         
                          
                         <Option value="All">All</Option>
            <Option value="name">Business Name</Option>
            <Option value="companyType">Company Type</Option>
            <Option value="address">Address</Option>
            <Option value="panNumber">PAN Number</Option>
            <Option value="gstinNumber">GSTIN Number</Option>
            <Option value="epfRegNumber">EPF Reg. Number</Option>
            <Option value="esicRegNumber">ESIC Reg. Number</Option>
            <Option value="ptRegNumber">PT Reg. Number</Option>
            <Option value="tanNumber">TAN Number</Option>
                        </Select>
        )}
                <Search
                      placeholder="Search"
                      size="large"
                      allowClear
                     // value={filterText.value}
                      // id="searchTextInput"
                      style={{ marginRight: "20px", width: "15vw" }}
                      // className="searchBox"
                      onChange={debounce((e) => handleSearch(e.target.value, "term"), 300)}
                      //onSearch={(value) => handleSearchValueUpdate(value)}
                      enterButton
                    />
                    </div>
        {permission.Contractors.write && (
          <Button
            style={{ float: "right", height: "50px", width: "90px" }}
            type="primary"
            onClick={handleCreate}
            className="mb-4"
          >
            <span style={{ fontWeight: 700 }}> Add User</span>
          </Button>
        )}
      </div>
      {
        <div style={{ flexGrow: 1, overflowX: "auto", overflowY: "hidden" }}>
  {initialLoading ? (
    // Show a loading spinner while data is being fetched
    <div
      className="loading-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Spin size="large" />
    </div>
  ) : contractors.length > 0 ? (
    // Show table when data is available
    <Table
      className="responsiveTable3"
      rowClassName="table-row-light"
      columns={columns}
      dataSource={contractors}
      rowKey="id"
      onRow={
        permission?.["Contractors User"]?.read ||
        permission?.["Assign Contractors"]?.read ||
        permission?.["Contractors"]?.edit ||
        permission?.["Contractors"]?.delete
          ? (record, index) => ({
              onClick: () => handleRowClick(record),
              ref: index === contractors.length - 1 ? lastPostElementRef : null,
            })
          : undefined
      }
      pagination={false}
      scroll={{ x: "max-content", y: "calc(-169px + 100vh)" }}
    />
  ) : (
    // Show "No Data Found" only when loading is finished and no data exists
    <div className="noDataFound">
      <h3
        style={{
          display: "flex",
          justifyContent: "center",
          height: "80vh",
          fontSize: "24px",
          color: "#777",
          alignItems: "center",
        }}
      >
        No Data Found
      </h3>
    </div>
  )}
</div>

      }
      {/* <div
        style={{
          // overflowY: "hidden",
          // whiteSpace: "nowrap",
          // height: "calc(100vh - 250px)",
          // width: "100%",
          // boxSizing: "border-box",
          flexGrow: 1, overflowX: "auto", overflowY: "hidden"
        }}
      >
        <Table
          className="responsiveTable3"
          rowClassName="table-row-light"
          columns={columns}
          dataSource={contractors}
          rowKey="id"
          // loading={loading}

          onRow={
            permission?.["Contractors User"]?.read ||
            permission?.["Assign Contractors"]?.read ||
            permission?.["Contractors"]?.edit ||
            permission?.["Contractors"]?.delete
              ? (record, index) => ({
                  onClick: () => handleRowClick(record),
                  ref:
                    index === contractors.length - 1
                      ? lastPostElementRef
                      : null,
                })
              : undefined
          }
          pagination={false}
          scroll={{ x: "max-content", y: "calc(-169px + 100vh)" }}
        />
      </div> */}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button onClick={handleLoadMore} loading={loading}>
            Load More
          </Button>
        </div>
      )}
      {!hasMore && contractors.length > 0 && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          {/* Showing all {totalItems} contractors */}
        </div>
      )}
      <ContractorForm
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setEditingContractor(null);
        }}
        onSuccess={() => {
          setModalVisible(false);
          setEditingContractor(null);
          setContractors([]);
          setPage(1);
          setHasMore(true);
          loadContractors(1);
        }}
        initialValues={editingContractor}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteConfirmVisible}
        onOk={() => handleDelete(selectedContractorId)} // Pass the ID here
        onCancel={() => setDeleteConfirmVisible(false)}
        width={500}
        bodyStyle={{
          textAlign: "center",
          padding: "16px",
          marginBottom: "-50px",
        }}
        style={{ top: 100 }}
      >
        <p
          style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}
        >
          Are you sure you want to delete this contractor?
        </p>
      </Modal>
    </div>
  );
};

export default ContractorList;
