import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../context/auth";
import { useHistory } from "react-router";

export const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    if (!auth.isUserLoggedIn) {
      history.replace("/");
    } else {
      const unblock = history.block((location, action) => {
        if (action === "POP" && location.pathname === "/") {
          history.replace(history.location.pathname);
          return false;
        }
      });
      return () => unblock();
    }
  }, [auth.isUserLoggedIn, history]);

  return (
    <Route
      {...rest}
      render={(obj) => {
        // console.log(auth, obj);
        if (auth.isUserLoggedIn) {
          if (auth.isSpectra && obj.match.path === "/Talntworx")
            return children;
          else if (auth.isContract && obj.match.path === "/contract")
            return children;
          else if (auth.isPrimary && obj.match.path === "/primary")
            return children;
          else if (auth.isSpectra)
            return <Redirect to={{ pathname: "/Talntworx" }} />;
          else if (auth.isContract)
            return <Redirect to={{ pathname: "/contract" }} />;
          else if (auth.isPrimary)
            return <Redirect to={{ pathname: "/primary" }} />;
        } else {
          return <Redirect to={{ pathname: "/" }} />;
        }
      }}
    />
  );
};