import { createPost, fetchData, fetchDataEachRow, updatePost } from "../backendApi";

export const fetchJobPosts = async (page, pageSize, searchParams) => {
  try {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
      column: searchParams.searchField,
      search: searchParams.searchTerm,
    });

    const response = await fetchData((
      `/jobPost?${queryParams}`
    ))
    const data = response.data;
    return {
      data: data.data,
      total: data.data.totalCount,
    };
  } catch (error) {
    console.error("Error fetching job posts:", error);
    throw error;
  }
};

export const getJobPostById = async (id) => {
  try {
    const response = await fetchDataEachRow(`/jobPost/${id}`)
    return response.data.data;
  } catch (error) {
    console.error("Error creating job post:", error);
    throw error;
  }
};

export const createJobPost = async (jobPost) => {
  const response = await createPost(`/jobPost`, jobPost)
  return response.data;
};

export const updateJobPost = async (id, jobPost) => {
  try {
    const response = await updatePost(`/jobPost/${id}`, jobPost)
    return response.data;
  } catch (error) {
    console.error("Error updating job post:", error);
    throw error;
  }
};

export const deleteJobPost = async (jobPost) => {
  try {
    const response = await updatePost(`/jobPost/deactivate/${jobPost}`)
    return response.data;
  } catch (error) {
    console.error("Error creating job post:", error);
    throw error;
  }
};

export const fetchPrimaryEmployer = async () => {
  try {
    const response = await fetchData(`/primaryEmployeers/jobPost`)
    return response.data.data;
  } catch (error) {
    console.error("Error creating job post:", error);
    throw error;
  }
};

export const fetchStatesAndDistricts = async () => {
  try {
    const response = await fetchData(`/states`)
    console.log(response, "response222")
    return response.data.data;
  } catch (error) {
    console.error("Error fetching states", error);
    throw error;
  }
};

export const fetchAppliedJobs = async (page, pageSize, jobpostId) => {
  try {
    const response = await fetchData(`/appliedJob?page=${page}&pageSize=${pageSize}&jobpostId=${jobpostId}`)
    return response.data;
  } catch (error) {
    console.error("Error fetching applied jobs", error);
    throw error;
  }
};