import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const config = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID
// };

// DEVELOPMENT AND UAT Firebase Config.
const firebaseConfig = {
  apiKey: "AIzaSyATY58Ea6ubLCkzcVSZOTEpvu8aPz2o6CM",
  authDomain: "talntx.firebaseapp.com",
  projectId: "talntx",
  storageBucket: "talntx.firebasestorage.app",
  messagingSenderId: "485715650084",
  appId: "1:485715650084:web:c6d36c6b4b3faeaafe5ab1",
  measurementId: "G-Q4KPRYTYWH",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAvTw3qCvhrtHbaTICzxwNj0ta5Npr1o0U",
//   authDomain: "prism-b4236.firebaseapp.com",
//   projectId: "prism-b4236",
//   storageBucket: "prism-b4236.appspot.com",
//   messagingSenderId: "191370584741",
//   appId: "1:191370584741:web:b2624de7f5e1df4054634c",
//   measurementId: "G-L968HJFVCR"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCVSyBo4LAbfKbINPTFW7zOySSpMenWihc",
//   authDomain: "prism-b4236.firebaseapp.com",
//   projectId: "prism-b4236",
//   storageBucket: "prism-b4236.appspot.com",
//   messagingSenderId: "191370584741",
//   appId: "1:191370584741:web:3651cd722b3903d054634c",
//   measurementId: "G-VJC3B6QR7P"
// };

const app = initializeApp(firebaseConfig);

export default app;

export const firebaseAuth = getAuth(app);

