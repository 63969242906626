import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Select, Input, DatePicker, message } from "antd";
import { createPost, fetchData } from "../../../backendApi";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedTalent } from "../../login/loginSlice";

const { Option } = Select;

const TalentAssignmentModal = ({ isTalentAssignment, closeTalentHandler }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [talenAssignDrop, setTalenAssignDrop] = useState({
    primaryEmpList: [],
    jobPost: [],
    contractor: [],
    loginUserType: "",
  });

  const [asignTalent, setAsignTalent] = useState({
    primaryEmp: "",
    jobPost: "",
    supervisor: "",
    doj: "",
  });

  let talent = useSelector((state) => {
    return state?.user?.selectedTalent.talent;
  });

  useEffect(() => {
    if (!isTalentAssignment) {
      setTimeout(() => form.resetFields(), 300);
    }
  }, [isTalentAssignment]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { jobRole, primaryEmployerId, supervisor } = values;
        const assignedTalentId = [];
        const assignTalentData = talent.map((ele) => {
          assignedTalentId.push(ele.id);

          const utcDate = new Date(values.dobJoin);

          const istOffset = 5.5 * 60 * 60 * 1000;
          const istTimestamp = new Date(utcDate.getTime() + istOffset);

          console.log(
            istTimestamp.toISOString().slice(0, 19).replace("T", " ")
          );
          return {
            talntId: ele.id,
            jobRoleId: jobRole,
            primaryEmployeerId: primaryEmployerId,
            contractorUserId:
              talenAssignDrop?.loginUserType === "Contractor"
                ? talenAssignDrop.contractor[0].id
                : supervisor,
            dateOfJoin: istTimestamp,
            dateOfLeave: null,
          };
        });
        const res = await createPost("/talnt/assignTalnt", {
          assignTalentData,
          assignedTalentId,
        });
        if (res?.data?.status === 200) {
          const updatedTalent = JSON.parse(JSON.stringify(talent));
          updatedTalent.forEach((ele) => {
            ele.isChecked = false;
          });
          dispatch(
            updateSelectedTalent({
              findIsChecked: false,
              findSelectedTalents: updatedTalent,
              isSubmited: true,
            })
          );
          message.success("Form submitted successfully!");
          setAsignTalent((prev) => ({
            ...prev,
            primaryEmp: "",
            jobPost: "",
            supervisor: "",
            doj: "",
          }));
          closeTalentHandler();
        } else {
          setAsignTalent((prev) => ({
            ...prev,
            primaryEmp: "",
            jobPost: "",
            supervisor: "",
            doj: "",
          }));
          closeTalentHandler();
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
        message.error("Please fill in all required fields!");
      });
  };

  async function fetchAllPrimaryEmployeer() {
    try {
      const res = await fetchData("/primaryEmployeers");
      if (res.data.data.length > 0) {
        setTalenAssignDrop((prev) => ({
          ...prev,
          primaryEmpList: res.data.data,
        }));
      } else {
        setTalenAssignDrop((prev) => ({ ...prev, primaryEmpList: [] }));
      }
    } catch (error) {
      console.error("Error fetching employers:", error);
      message.error("Failed to fetch employers");
    }
  }

  useEffect(() => {
    fetchAllPrimaryEmployeer();
  }, []);

  const fetchJobPostDetail = async (value) => {
    try {
      if (value) {
        const res = await fetchData(`/jobRole/primaryEmployeer/${value}`);
        if (res?.data?.status === 200) {
          setTalenAssignDrop((prev) => ({
            ...prev,
            jobPost: res.data.data,
          }));
        } else {
          setTalenAssignDrop((prev) => ({
            ...prev,
            jobPost: [],
          }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchContractor = async (value) => {
    try {
      if (value) {
        const res = await fetchData(`/contractorUsers/${value}`);
        if (res.data.status === 200) {
          setTalenAssignDrop((prev) => ({
            ...prev,
            contractor: res.data.data,
            loginUserType: res.data.userType,
          }));
        } else {
          setTalenAssignDrop((prev) => ({
            ...prev,
            contractor: [],
            loginUserType: "",
          }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const employeerChangeHandler = async (value, name) => {
    if (name === "primaryEmp") {
      form.resetFields(["jobRole", "supervisor", "dobJoin"]);
      if (!value) {
        setAsignTalent({
          primaryEmp: "",
          jobPost: "",
          supervisor: "",
          doj: "",
        });

        setTalenAssignDrop((prev) => ({
          ...prev,
          jobPost: [],
          contractor: [],
        }));

        form.resetFields(["jobRole", "supervisor", "dobJoin"]);
        return;
      }
      await fetchJobPostDetail(value);
      await fetchContractor(value);

      setAsignTalent((prev) => ({ ...prev, primaryEmp: value }));
    } else {
      setAsignTalent((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <Modal
      onCancel={closeTalentHandler}
      title="Assign Talent"
      open={isTalentAssignment}
      centered
      footer={[
        <Button key="cancel" onClick={closeTalentHandler}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="primaryEmployerId"
          label="Select Principal Employer"
          rules={[{ required: true, message: "Please select an employer!" }]}
          style={{ marginBottom: "20px" }}
        >
          <Select
            placeholder="Choose an option"
            // mode="multiple"
            allowClear
            name="primaryEmp"
            style={{ width: "100%"}}
            onChange={(e) => employeerChangeHandler(e, "primaryEmp")}
          >
            {talenAssignDrop?.primaryEmpList.map((employer) => {
              return (
                <Option key={employer?.id} value={employer?.id}>
                  {employer?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="jobRole"
          label="Select Job Role"
          rules={[{ required: true, message: "Please select a job role!" }]}
          style={{ marginBottom: "12px" }}
        >
          <Select
            placeholder="Choose an option"
            disabled={!asignTalent?.primaryEmp}
            allowClear
            onChange={(e) => employeerChangeHandler(e, "jobPost")}
          >
            {talenAssignDrop.jobPost.map((ele) => {
              return (
                <Option id={ele.id} value={ele.id} key={ele.id}>
                  {ele?.designation}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {talenAssignDrop?.loginUserType === "Contractor" ? (
          <>
            <label>Supervisor</label>
            <Input
              type="text"
              defaultValue={talenAssignDrop.contractor[0].fullName}
              disabled
              style={{ marginBottom: "20px" }}
            />
          </>
        ) : (
          <Form.Item
            name="supervisor"
            label="Select Supervisor"
            rules={[{ required: true, message: "Please select a supervisor!" }]}
            style={{ marginBottom: "20px" }}
          >
            <Select
              placeholder="Choose an option"
              allowClear
              disabled={!asignTalent?.primaryEmp}
              onChange={(e) => employeerChangeHandler(e, "supervisor")}
            >
              {talenAssignDrop?.contractor.map((ele) => {
                return (
                  <Option id={ele.id} value={ele.id} key={ele.id}>
                    {ele?.fullName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="dobJoin"
          label="Select DOJ"
          rules={[{ required: true, message: "Please select a date!" }]}
          style={{ marginBottom: "20px" }}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Choose a date"
            disabled={
              asignTalent?.primaryEmp &&
              asignTalent?.jobPost &&
              (asignTalent?.supervisor ||
                talenAssignDrop?.contractor[0]?.fullName)
                ? false
                : true
            }
            // disabledDate={(current) =>
            //   current && current < moment().startOf("day")
            // }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TalentAssignmentModal;
