import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Button,
  Typography,
  Modal,
  Form,
  Input,
  Select,
  Divider,
  message,
  Table,
  Tag,
  Col,
  Row,
} from "antd";
import dayjs from "dayjs";
import { fetchData } from "../../backendApi";
import { getValuesForParams } from "../WhatsappCommonFunctions";
import {
  whatsAppMessageSender,
  getWhatsUpData,
  getRecipientList,
} from "../rolemanagement/api";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;
const { TextArea } = Input;

const TodaysSchedule = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageNew, setMessageNew] = useState("");
  const [form] = Form.useForm();
  const [whatsUpStoredData, setWhatsUpStoredData] = useState([]);

  // Independent state for each filter
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [primaryEmployerList, setPrimaryEmployerList] = useState([]);
  const [messageTrigger, setMessageTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  let permission = useSelector((state) => {
    return state.user.permission.getUserRole;
  });

  const employedOption = { label: "Employed", value: "employed" };
  const unemployedOptions = [
    { label: "Incomplete Profile", value: "incomplete_profile" },
    { label: "Completed Profile", value: "completed_profile" },
    { label: "Yet To Be Verified", value: "yet_to_be_verified" },
    { label: "Available", value: "available" },
  ];

  let temp = [
    "incomplete_profile",
    "completed_profile",
    "yet_to_be_verified",
    "employed",
    "available",
  ];

  const unemployedValues = unemployedOptions.map((opt) => opt.value);
  const allRecipientValues = ["employed", ...unemployedValues];

  const recipientOptions = [
    { label: "Incomplete Profile", value: "incomplete_profile" },
    { label: "Completed Profile", value: "completed_profile" },
    { label: "Yet To Be Verified", value: "yet_to_be_verified" },
    { label: "Employed", value: "employed" },
    { label: "Available", value: "available" },
  ];

  useEffect(() => {
    (async () => {
      const response = await getWhatsUpData();
      // console.log(response?.data?.data, "TELLER");
      setWhatsUpStoredData(response?.data?.data);
      setMessageTrigger(false);
    })();
  }, [messageTrigger]);

  useEffect(() => {
    (async () => {
      const responseContractor = await fetchData(
        `/dashboard/primaryContractor`
      );
      if (responseContractor?.data?.data?.length > 0) {
        setContractorList(responseContractor?.data?.data);
      }
      const responsePE = await fetchData("/dashboard/primaryEmployeer");
      if (responsePE?.data?.data?.length > 0) {
        setPrimaryEmployerList(responsePE?.data?.data);
      }
    })();
  }, []);

  // Update form values when states change
  useEffect(() => {
    form.setFieldValue("recipients", selectedRecipients);
    form.setFieldValue("contractor", selectedContractors);
    form.setFieldValue("primaryEmployer", selectedEmployers);
  }, [selectedRecipients, selectedContractors, selectedEmployers, form]);

  // Recipients handlers
  const handleRecipientsChange = (values) => {
    setSelectedRecipients(values);
  };

  const handleSelectAllRecipients = () => {
    const allSelected = allRecipientValues.every((value) =>
      selectedRecipients.includes(value)
    );
    setSelectedRecipients(allSelected ? [] : allRecipientValues);
  };

  const handleSelectAllUnemployed = () => {
    const allUnemployedSelected = unemployedValues.every((value) =>
      selectedRecipients.includes(value)
    );

    let newSelection;
    if (allUnemployedSelected) {
      // Remove only unemployed values, keep other selections
      newSelection = selectedRecipients.filter(
        (value) => !unemployedValues.includes(value)
      );
    } else {
      // Add all unemployed values while preserving other selections
      newSelection = [...new Set([...selectedRecipients, ...unemployedValues])];
    }
    setSelectedRecipients(newSelection);
  };

  // Contractors handlers
  const handleContractorsChange = (values) => {
    setSelectedContractors(values);
  };

  const handleSelectAllContractors = () => {
    const allSelected = contractorList.length === selectedContractors.length;
    setSelectedContractors(
      allSelected ? [] : contractorList.map((item) => item.id)
    );
  };

  // Employers handlers
  const handleEmployersChange = (values) => {
    setSelectedEmployers(values);
  };

  const handleSelectAllEmployers = () => {
    const allSelected = primaryEmployerList.length === selectedEmployers.length;
    setSelectedEmployers(
      allSelected ? [] : primaryEmployerList.map((item) => item.id)
    );
  };

  const handleSendMessage = async () => {
    setLoading(true);
    const response = await getRecipientList(selectedRecipients);
    if (messageNew === "") {
      message.error("Please Fill Message");
      setLoading(false);
    } else {
      if (response.length > 0) {
        try {
          const values = await form.validateFields();
          console.log("Submitted values:", values);

          const paramsSendingResponse = getValuesForParams(selectedRecipients);

          const response = await whatsAppMessageSender(
            "spectraMassMessage",
            {
              title: "mass_message_whatsapp_spectra_user",
              type: "condition_talent",
              query: paramsSendingResponse,
              message: messageNew,
              primaryContractorId:
                selectedEmployers?.length !== 0 ? selectedContractors : [],
              primaryEmployerId:
                selectedContractors?.length !== 0 ? selectedEmployers : [],
              status: selectedRecipients,
            },
            permission?.fullName,
            permission?.roleName
          );
          console.log("Dashboard data response:", response);
          if (response.status === 200) {
            setMessageTrigger(true);
            form.resetFields();
            setMessageNew("");
            setSelectedContractors([]);
            setSelectedEmployers([]);
            setSelectedRecipients([]);
            setIsModalOpen(false);

            message.success({
              content: "Message sent Successfully",
              key: "massMessage",
            });
          } else {
            message.destroy({
              content: response.message,
              key: "massMessage",
            });
          }
        } catch (error) {
          console.log("Validation failed:", error);
        } finally {
          setLoading(false); // Stop loading after completion
        }
      } else {
        message.error("No Talents Present Under Selected Recipients Status");
        setLoading(false);
      }
    }
  };

  // Modal Handlers
  const handleOpenModal = () => {
    form.resetFields();
    setMessageNew("");
    setSelectedContractors([]);
    setSelectedEmployers([]);
    setSelectedRecipients([]);
    setIsModalOpen(true);
  };

  const handleCloseModal = useCallback(() => {
    setTimeout(() => {
      setIsModalOpen(false);
    }, 50);
  }, []);

  return (
    <Card style={{ borderRadius: "16px", marginBottom: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
          Today Messages
        </Title>
        <Button type="primary" onClick={handleOpenModal}>
          Send WhatsApp Message
        </Button>
      </div>

      <MessageHistoryList whatsUpStoredData={whatsUpStoredData} />

      {/* WhatsApp Message Modal */}
      <Modal
        // title="Mass Message over WhatsApp"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
        width={700}
        destroyOnClose={true}
        style={{ top: 50 }} // Adjust modal positioning if needed
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }} // Scrollable modal body
      >
        <div className="">
          <h1 className="heading">
            Mass Message Over <span className="highlight">WhatsApp</span>
          </h1>
          <Form
            layout="vertical"
            form={form}
            shouldUpdate
            onFinish={handleSendMessage}
          >
            {/* Message Input */}
            <Form.Item
              name="message"
              label={<h4 className="subheading">Message</h4>}
              // rules={[{ required: true, message: "Enter your message" }]}
              style={{ marginBottom: "16px" }}
            >
              <TextArea
                value={messageNew}
                placeholder="Enter your message"
                rows={5}
                style={{ fontSize: "16px" }}
                onChange={(e) => setMessageNew(e.target.value)}
              />
            </Form.Item>

            {/* Recipient Selection */}
            <Form.Item
              name="recipients"
              label={<h4 className="subheading">Send Recipients</h4>}
              style={{ marginBottom: "16px" }}
              rules={[
                {
                  required: true,
                  validator: (_, value) =>
                    value && value.length > 0
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please select at least one recipient!")
                        ),
                },
              ]}
            >
              <Select
                placeholder="Select Recipients"
                mode="multiple"
                allowClear
                value={selectedRecipients}
                maxTagCount="responsive"
                onChange={handleRecipientsChange}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <div>
                    <Button
                      type="text"
                      onClick={handleSelectAllRecipients}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        padding: "8px 16px",
                        fontWeight: "bold",
                      }}
                    >
                      {allRecipientValues.every((value) =>
                        selectedRecipients.includes(value)
                      )
                        ? "Deselect All Recipients"
                        : "Select All Recipients"}
                    </Button>
                    <Divider style={{ margin: "4px 0" }} />
                    <Button
                      type="text"
                      onClick={handleSelectAllUnemployed}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        padding: "8px 16px",
                        fontWeight: "bold",
                      }}
                    >
                      {unemployedValues.every((value) =>
                        selectedRecipients.includes(value)
                      )
                        ? "Deselect All Unemployed"
                        : "Select All Unemployed"}
                    </Button>
                    <Divider style={{ margin: "4px 0" }} />
                    {menu}
                  </div>
                )}
                style={{ width: "100%" }}
              >
                {/* Employed Category */}
                <Select.OptGroup label="Employed">
                  <Select.Option
                    key={employedOption.value}
                    value={employedOption.value}
                  >
                    {employedOption.label}
                  </Select.Option>
                </Select.OptGroup>

                {/* Unemployed Category */}
                <Select.OptGroup label="Unemployed">
                  {unemployedOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>
            </Form.Item>

            {/* Filter Options */}
            {selectedRecipients?.length === 1 &&
              selectedRecipients[0] === "employed" && (
                <Form.Item
                  name="contractor"
                  label={<h4 className="subheading">Filter Contractor User</h4>}
                >
                  <Select
                    placeholder="Select Contractor"
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    value={selectedContractors}
                    onChange={handleContractorsChange}
                    disabled={selectedEmployers?.length !== 0}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <div>
                        <Button
                          type="text"
                          onClick={handleSelectAllContractors}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            padding: "8px 16px",
                            fontWeight: "bold",
                          }}
                        >
                          {contractorList.length === selectedContractors.length
                            ? "Deselect All Contractors"
                            : "Select All Contractors"}
                        </Button>
                        <Divider style={{ margin: "4px 0" }} />
                        {menu}
                      </div>
                    )}
                  >
                    {contractorList?.map((itm, i) => (
                      <Select.Option
                        key={itm.id}
                        value={itm.id}
                        label={`${itm.name} ${itm.CompanyType.companyType}`} // Important: Ensure label is defined here
                      >
                        {itm.name} {itm.CompanyType.companyType}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            {selectedRecipients?.length === 1 &&
              selectedRecipients[0] === "employed" && (
                <Form.Item
                  name="primaryEmployer"
                  label={
                    <h4 className="subheading">Filter Primary Employer</h4>
                  }
                >
                  <Select
                    placeholder="Select Employer"
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    value={selectedEmployers}
                    onChange={handleEmployersChange}
                    disabled={selectedContractors?.length !== 0}
                    filterOption={
                      (input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(input.toLowerCase()) // Updated to use option.label
                    }
                    dropdownRender={(menu) => (
                      <div>
                        <Button
                          type="text"
                          onClick={handleSelectAllEmployers}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            padding: "8px 16px",
                            fontWeight: "bold",
                          }}
                        >
                          {primaryEmployerList.length ===
                          selectedEmployers.length
                            ? "Deselect All Employers"
                            : "Select All Employers"}
                        </Button>
                        <Divider style={{ margin: "4px 0" }} />
                        {menu}
                      </div>
                    )}
                  >
                    {primaryEmployerList?.map((itm, i) => (
                      <Select.Option
                        key={itm.id}
                        value={itm.id}
                        label={`${itm.name} ${itm.CompanyType.companyType}`} // Important for filterOption
                      >
                        {itm.name} {itm.CompanyType.companyType}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            {/* Footer Buttons */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleCloseModal}
                style={{ marginRight: "1.5rem" }}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                {loading ? "Sending..." : "Send"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </Card>
  );
};

export default TodaysSchedule;

const MessageHistoryList = ({ whatsUpStoredData }) => {
  const [isRecipientModalVisible, setIsRecipientModalVisible] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const colorPatterns = ["#e6fffb", "#fff7e6", "#f9f0ff"];

  const mapTodaysData = whatsUpStoredData?.filter(
    (fil) =>
      new Date(fil?.createdAt).toISOString().split("T")[0] ===
      new Date().toISOString().split("T")[0]
  );

  const showRecipientDetails = (recipients_list) => {
    setSelectedRecipients(recipients_list);
    setIsRecipientModalVisible(true);
  };

  const recipientCall = async (recipients_list) => {
    showRecipientDetails(recipients_list);
  };

  const showFullMessage = (message) => {
    setSelectedMessage(message);
    setIsMessageModalVisible(true);
  };

  return (
    <div className="history-card">
      {/* <div
        className="history-content"
        style={{
          maxHeight: "400px",
          overflowY: "auto",
          paddingRight: "10px",
        }}
      >
        {mapTodaysData?.length > 0 ? (
          <Row gutter={[16, 16]}>
            {mapTodaysData.map((item, index) => (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <Card
                  size="small"
                  style={{
                    background: colorPatterns[index % colorPatterns.length],
                    borderRadius: 6,
                    padding: "12px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>
                      {item.createdBy}
                    </Text>
                    <Text
                      style={{ color: "#52c41a", cursor: "pointer" }}
                      onClick={() => recipientCall(item.recipients_list)}
                    >
                      View Recipients
                    </Text>
                  </div>

                  <div style={{ marginTop: "8px" }}>
                    <Text
                      style={{ color: "#1890ff", cursor: "pointer" }}
                      onClick={() => showFullMessage(item.message)}
                    >
                      {item.message.length > 50
                        ? item.message.substring(0, 50) + "..."
                        : item.message}
                    </Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Text>No Messages Available Today</Text>
        )}
      </div> */}
      <div
        className="history-content"
        style={{
          display: "flex",
          overflowX: "auto",
          padding: "10px",
          gap: "16px", // More spacing for a spacious layout
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {mapTodaysData?.length > 0 ? (
          mapTodaysData?.map((item, index) => (
            <Card
              key={index}
              size="small"
              style={{
                background: `${colorPatterns[index % colorPatterns.length]}`,
                borderRadius: 8,
                padding: "16px",
                minWidth: "350px", // Increased width for better content visibility
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    {item.createdBy}
                  </Text>
                </div>
                <Text
                  style={{ color: "#52c41a", cursor: "pointer" }}
                  onClick={() => recipientCall(item.recipients_list)}
                >
                  View Recipients
                </Text>
              </div>

              <div style={{ marginTop: "8px" }}>
                <Text
                  style={{ color: "#1890ff", cursor: "pointer" }}
                  onClick={() => showFullMessage(item.message)}
                >
                  {item.message.length > 50
                    ? item.message.substring(0, 50) + "..."
                    : item.message}
                </Text>
              </div>
            </Card>
          ))
        ) : (
          <Text>No Messages Available Today</Text>
        )}
      </div>

      {/* Modal for Full Message */}
      <Modal
        title="Full Message"
        visible={isMessageModalVisible}
        onCancel={() => setIsMessageModalVisible(false)}
        footer={null}
      >
        <Text style={{ fontSize: "16px", color: "grey" }}>
          {selectedMessage}
        </Text>
      </Modal>

      {/* Modal for Recipients */}
      <Modal
        title="Recipient Details"
        open={isRecipientModalVisible}
        onCancel={() => setIsRecipientModalVisible(false)}
        footer={null}
        width={700}
        // bodyStyle={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <Table
          dataSource={selectedRecipients.map((recipient, index) => ({
            key: index,
            serial_number: `${index + 1}`,
            name: recipient?.personal?.full_name,
            number: recipient?.mobileNumber,
            code: recipient?.talntCode,
          }))}
          columns={[
            {
              title: "No",
              dataIndex: "serial_number",
              key: "serial_number",
              width: 70,
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Mobile Number",
              dataIndex: "number",
              key: "number",
            },
            {
              title: "Code",
              dataIndex: "code",
              key: "code",
              render: (text) => (
                <Tag color={text === "Delivered" ? "green" : "orange"}>
                  {text}
                </Tag>
              ),
            },
          ]}
          pagination={false}
          bordered
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};
