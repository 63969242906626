import React, { useContext, useEffect, useState } from "react";
import "../../../styles/Panel.scss";
import { Tabs, Input, Select } from "antd";
import UserList from "../panelHead/UserList";
import { dataContext } from "../../../context/data";
import EmployeesTabs from "./EmployeesTabs";
import ComplianceTabs from "./ComplianceTabs";
import JobRolesTabs from "./JobRolesTabs";
import UsersTabs from "./UsersTabs";
import CompanyTabs from "./CompanyTabs";
import GoBack from "../../../assets/icons/leftArrow.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import UserPanelSearch from "../panelHead/userPanelSearch";
import JobRoleList from "../../jobRole/JobRoleList";
import { useSelector } from "react-redux";
import { fetchDataEachRow } from "../../../backendApi";

import PreviouseEmployee from "./PreviouseEmployee";
import debounce from "lodash/debounce";
const { Search } = Input;
const { Option } = Select;


function PrimaryUsersDetailsTabs({ userType }) {
  const { userData, setUserData } = useContext(dataContext);
  const { searchState, setSearchState } = useContext(dataContext);
  const [userName, setUserName] = useState("");
  const { id } = useLocation().state;

  const { TabPane } = Tabs;
  const history = useHistory();

  const tabStyle = {
    fontSize: "1.3vw !important",
    fontWeight: "600",
  };

  const contractTabs = [
    "Employed",
    "Available",
    "Incomplete profile",
    "Yet to be verified",
  ];

  const handleAddUserClick = () => {
    setUserData((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
    setUserData((prev) => ({
      ...prev,
      editUser: null,
    }));
    console.log("Add User button clicked!");
  };

  const handleANewJobRoleClick = () => {
    setUserData((prev) => ({
      ...prev,
      isJobRoleModalVisible: true,
    }));
  };

  const isAddUserEnabled =
    (userType === "spectra" && userData.activeTabIndex === "5") ||
    (userType === "primary" && userData.activePrimaryTabIndex === "1");


  const handleSearchFieldChange = (value) => {
    setSearchState((prev) => ({
      ...prev,
      searchField: value,
    }));
  };

  const handleSearchTermChange = (value) => {
    setSearchState((prev) => ({
      ...prev,
      searchTerm: value,
    }));
  };

  useEffect(() => {
    if (userType === "spectra") {
      setUserData((pre) => ({
        ...pre,
        activeTabIndex: "1",
      }));
    } else if (userType === "contract") {
      setUserData((pre) => ({
        ...pre,
        activeContractTabIndex: "1",
      }));
    } else if (userType === "primary") {
      setUserData((pre) => ({
        ...pre,
        activePrimaryTabIndex: "1",
      }));
    }
  }, [userType, setUserData]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await fetchDataEachRow(
          `https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers/${id}`
        );

        if (response?.data?.status === 200) {
          const data = response?.data?.data;
          if (Array.isArray(data)) {
            const user = data.find((user) => user.id === id);
            setUserName(user?.name || "Unknown User");
          } else if (typeof data === "object" && data !== null) {
            setUserName(data?.name || "Unknown User");
          } else {
            console.error("Unexpected data format:", data);
          }
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (id) {
      fetchUserName();
    }
  }, [id]);

  const [primaryEmployee, setPrimaryEmployee] = useState({
    talentEmployee: false,
    primaryUserList: false,
    peUser: false,
    complience: false,
    jobRole: false,
    company: false,
  });

  let { permission } = useSelector(
    (state) => state?.user?.permission?.getUserRole
  );

  const updatePrimaryTab = (permission) => {
    if (
      permission["PE User"]?.read ||
      permission["PE User"]?.write ||
      permission["PE User"]?.edit ||
      permission["PE User"]?.delete
    ) {
      setPrimaryEmployee((prev) => ({
        ...prev,
        peUser: true,
      }));
    } else {
      setPrimaryEmployee((prev) => ({
        ...prev,
        peUser: false,
      }));
    }
    if (
      permission["Compliance"]?.read ||
      permission["Compliance"]?.write ||
      permission["Compliance"]?.edit ||
      permission["Compliance"]?.delete
    ) {
      setPrimaryEmployee((prev) => ({
        ...prev,
        complience: true,
      }));
    } else {
      setPrimaryEmployee((prev) => ({
        ...prev,
        complience: false,
      }));
    }
    if (
      permission["Job Role"]?.read ||
      permission["Job Role"]?.write ||
      permission["Job Role"]?.edit ||
      permission["Job Role"]?.delete
    ) {
      setPrimaryEmployee((prev) => ({
        ...prev,
        jobRole: true,
      }));
    } else {
      setPrimaryEmployee((prev) => ({
        ...prev,
        jobRole: false,
      }));
    }
    if (
      permission["Principle Employer"]?.write ||
      permission["Principle Employer"]?.edit ||
      permission["Principle Employer"]?.delete
    ) {
      setPrimaryEmployee((prev) => ({
        ...prev,
        company: true,
      }));
    } else {
      setPrimaryEmployee((prev) => ({
        ...prev,
        company: false,
      }));
    }

    if (permission["Principle Employer"].read) {
      setPrimaryEmployee((prev) => ({
        ...prev,
        primaryUserList: true,
      }));
    } else {
      setPrimaryEmployee((prev) => ({
        ...prev,
        primaryUserList: false,
      }));
    }
  };

  useEffect(() => {
    if (permission) {
      updatePrimaryTab(permission);
    }
  }, [permission]);

  console.log(`permission["PE User"]?.write`, permission["PE User"]?.write);

  return (
    <>

      <div
        className="header-row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="leftArw"
            onClick={() => {
              setUserData((prev) => ({
                ...prev,
                activeTabIndex: "3",
              }));
              history.goBack();
            }}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <img
              src={GoBack}
              alt="go back"
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
          </span>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {userName}
            </span>
            <div style={{ marginLeft: "2rem" }}>
              {/* Conditionally render the search box near the name */}{
                userType === "spectra" && userData.activeTabIndex === "4" && (
                  <div className="searchwraper" style={{ width: "375px" }}>
                    <Select
                      defaultValue={"all"}
                      size="large"
                      className="searchSelect"
                      listHeight={200}
                      value={searchState.searchField}
                      dropdownClassName="searchOption"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={true}
                      onChange={handleSearchFieldChange}
                      allowClear
                    >


                      <Option value="All">All</Option>
                      <Option value="department">Department</Option>
                      <Option value="designation">Designation</Option>
                    </Select>
                    <Search
                      placeholder="Search"
                      size="large"
                      allowClear
                      // value={filterText.value}
                      // id="searchTextInput"
                      style={{ marginRight: "20px", width: "15vw" }}
                      // className="searchBox"
                      onChange={debounce((e) => handleSearchTermChange(e.target.value), 300)}
                      //onSearch={(value) => handleSearchValueUpdate(value)}
                      enterButton
                    />
                  </div>
                )
              }
              {userType === "spectra" && userData.activeTabIndex === "5" && (
                <UserPanelSearch
                  talents={searchState.talents}
                  contractor={searchState.contractor}
                  userType={searchState.userType}
                  columnTalent={searchState.columnTalent}
                  columns={searchState.columns}
                  contractorColumns={searchState.contractorColumns}
                  selectedColumn={searchState.selectedColumn}
                //style={{ marginLeft: "2rem" }} // Add margin for spacing between name and search box
                />
              )}
            </div>
          </div>
        </div>

        {userType === "spectra" && userData.activeTabIndex === "4" && permission["Job Role"]?.write && (
          <div style={{ paddingRight: "1rem" }}>
            <button
              className="btn btn-primary tabBtn"
              onClick={handleANewJobRoleClick}
              style={{ borderRadius: "3px" }}
            >
              New Job Role
            </button>
          </div>
        )}

        {/* Right Section: Add User Button */}
        {isAddUserEnabled && permission["PE User"]?.write && (
          <div style={{ paddingRight: "1rem" }}>
            <button
              className="btn btn-primary tabBtn"
              onClick={handleAddUserClick}
              style={{ borderRadius: "3px" }}
            >
              Add User
            </button>
          </div>
        )}
      </div>

      {userType === "spectra" && (
        <div className="tabs">
          <Tabs
            // defaultActiveKey="1"
            // activeKey={userData.activeTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => ({
                ...pre,
                activeTabIndex: tabidx,
                filterValue: [],
                filterQuery: {},
                page: 0,
              }))
            }
            className="tabSlider"
            size="large"
          >
            {permission?.["Talent Employee"]?.read && (
              <TabPane
                tabKey="1"
                className="tabSlider"
                style={tabStyle}
                tab="Associates"
                key="1"
              >
                <EmployeesTabs />
              </TabPane>
            )}

            {primaryEmployee.peUser && (
              <TabPane
                tabKey="2"
                className="tabSlider"
                style={tabStyle}
                tab={`Exited Associates`}
                key="2"
              >
                <PreviouseEmployee neme={{pageName:"exited"}}/>
              </TabPane>
            )}

            {primaryEmployee.complience && (
              <TabPane
                tabKey="3"
                className="tabSlider"
                style={tabStyle}
                tab="Compliance"
                key="3"
              >
                <ComplianceTabs />
              </TabPane>
            )}
            {primaryEmployee.jobRole && (
              <TabPane
                tabKey="4"
                className="tabSlider"
                style={tabStyle}
                tab="Job Roles"
                key="4"

              >
                <JobRoleList primaryEmployerId={id} />
              </TabPane>
            )}
            {primaryEmployee.peUser && (
              <TabPane
                tabKey="5"
                className="tabSlider"
                style={tabStyle}
                tab="Users"
                key="5"
              >
                <UsersTabs />
              </TabPane>
            )}

            {permission?.["Principle Employer"]?.read && (
              <TabPane
                tabKey="6"
                className="tabSlider"
                style={tabStyle}
                tab="Company"
                key="6"

              >
                <CompanyTabs />
              </TabPane>
            )}
          </Tabs>
        </div>
      )}
      {userType === "contract" && (
        <div className="tabs">
          <div className="tabSlider tab-container-vmm">
            {contractTabs.map((name, i) => {
              return (
                <h1
                  className={
                    userData.activeContractTabIndex === `${i + 1}`
                      ? "tabs active"
                      : "tabs"
                  }
                  key={`${i + 1}`}
                  onClick={() =>
                    setUserData((pre) => ({
                      ...pre,
                      activeContractTabIndex: `${i + 1}`,
                      selectedRows: [],
                      filterValue: [],
                      filterQuery: {},
                      page: 0,
                    }))
                  }
                >
                  {name}
                </h1>
              );
            })}
          </div>
          <UserList talents={true} userType="contract" />
        </div>
      )}
      {userType === "primary" && (
        <div className="tabs ">
          <Tabs
            defaultActiveKey="1"
            activeKey={userData.activePrimaryTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => ({
                ...pre,
                activePrimaryTabIndex: tabidx,
                filterValue: [],
                filterQuery: {},
                page: 0,
              }))
            }
            className="tabSlider"
            size="large"
          >
            <TabPane
              className="tabSlider"
              style={tabStyle}
              tab="Talents"
              key="1"
            >
              <UserList talents={true} userType={userType} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </>
  );
}

export default PrimaryUsersDetailsTabs;