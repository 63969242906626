import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { createJobRole, updateJobRole } from "../../services/jobRoleService";
import { fetchPrimaryEmployer } from "../../services/jobPostService";
import "../../../src/styles/JobHeadBar.scss";
import moment from "moment";
import "./JobRoleList.css";
import { dataContext } from "../../context/data";

const { Option } = Select;
const DEDUCTION_OPTIONS = [
  "PF Employee Contribution",
  "PF Employer Contribution",
  "ESI Employee Contribution",
  "ESI Employer Contribution",
  "Admin Charges",
  "Others",
];
const EARNING_OPTIONS = [
  "Shift Allowance",
  "Conveyance Allowance",
  "Medical Allowance",
  "Overtime-pay",
  "Bonus",
  "Incentives",
  "Gratuity",
  "Leave Encashment",
  "Performance Bonus",
  "Others",
];
const SHIFT_OPTIONS = ["General", "First Shift", "Second Shift", "Night Shift"];
const OPERATION_OPTIONS = [
  "Uniform Deduction",
  "Safety PPE Deduction",
  "Food Deduction",
  "Room Deduction",
  "Advance Deduction",
];

const JobRoleForm = ({
  primaryEmployerId,
  visible,
  onClose,
  onSuccess,
  data,
  type,
  id,
}) => {
  const [form] = Form.useForm();
  const [availableDeductions, setAvailableDeductions] = useState([
    ...DEDUCTION_OPTIONS,
  ]);
  const [employers, setEmployers] = useState([]);
  const [employersLoading, setEmployersLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [availableEarnings, setAvailableEarnings] = useState([
    ...EARNING_OPTIONS,
  ]);
  const [showCustomTaxSlab, setShowCustomTaxSlab] = useState(false);
  const { userData, setUserData } = useContext(dataContext);
  const [completedDeductions, setCompletedDeductions] = useState([]);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  console.log(selectedValue.toString(), "selectedValue");
  useEffect(() => {
    if (visible) {
      setAvailableEarnings([...EARNING_OPTIONS]);
      setAvailableDeductions([...DEDUCTION_OPTIONS]);

      if (type === "edit" && data) {
        const isCustomTaxSlab = !["0", "100", "150", "200"].includes(String(data.taxSlab));
        form.setFieldsValue({
          designation: data.designation,
          department: data.department,
          description: data.description,
          primaryEmployer: data.primaryEmployeerId,
          primaryEmployerId: primaryEmployerId,
          // dateOfJoining: data.dateOfJoining,
          shift: data.shift,
          dateOfJoining: data.dateOfJoining
            ? moment(data.dateOfJoining).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          operationalDeduction: data.operationalDeduction,
          professionalTaxSlab: data.taxSlab,
          basicPay: data.basicPay,
          da: data.da,
          hra: data.hra,
          specialAllowance: data.specialAllowance,
          salary: {
            gross: data.grossSalary,
            period: data.salaryPerPeriod,
          },
          deductions: data.deductions || [],
          earnings: data.earnings || [],
          professionalTaxSlab: isCustomTaxSlab ? "Others" : String(data.taxSlab),
          customProfessionalTaxSlab: isCustomTaxSlab ? data.taxSlab : undefined,
        });
        setShowCustomTaxSlab(isCustomTaxSlab);
        const selectedDeductions =
          data.deductions?.map((e) =>
            e.type === "Others" ? e.title : e.type
          ) || [];
        setAvailableDeductions(
          DEDUCTION_OPTIONS.filter(
            (option) => !selectedDeductions.includes(option)
          )
        );
        const selectedEarnings =
          data.earnings?.map((e) => (e.type === "Others" ? e.title : e.type)) ||
          [];
        setAvailableEarnings(
          EARNING_OPTIONS.filter((option) => !selectedEarnings.includes(option))
        );
        const selectedOperations =
          data.operationalDeduction?.map((e) => e.type) || [];
        setSelectedOperations(
          OPERATION_OPTIONS.filter(
            (option) => !selectedOperations.includes(option)
          )
        );
      } else {
        form.resetFields();
        setAvailableDeductions([...DEDUCTION_OPTIONS]);
        setAvailableEarnings([...EARNING_OPTIONS]);
        setSelectedOperations([...OPERATION_OPTIONS]);
      }
    }
  }, [visible, type, data, form]);




  useEffect(() => {
    form.getFieldsValue();
  }, [form]);

  useEffect(() => {
    const loadEmployers = async () => {
      try {
        setEmployersLoading(true);
        const data = await fetchPrimaryEmployer();
        setEmployers(data);
      } catch (error) {
        console.error("Error loading employers:", error);
        message.error("Failed to load employers");
      } finally {
        setEmployersLoading(false);
      }
    };

    loadEmployers();
  }, []);

  // const handleOperationTypeChange = (value, index) => {
  //   const operationsField = form.getFieldValue("operationalDeduction") || [];
  //   const updatedOperations = [...operationsField];
  //   const oldType = updatedOperations[index]?.type;
  //   updatedOperations[index] = { ...updatedOperations[index], type: value };
  //   form.setFieldsValue({ operationalDeduction: updatedOperations });

  //   setSelectedOperations((prev) => {
  //     let newAvailable = [...prev];
  //     if (oldType && oldType !== "Others" && !newAvailable.includes(oldType)) {
  //       newAvailable.push(oldType);
  //     }
  //     if (value !== "Others") {
  //       newAvailable = newAvailable.filter((option) => option !== value);
  //     }
  //     return newAvailable.sort();
  //   });
  // };

  const handleOperationTypeChange = (value, index) => {
    const operationsField = form.getFieldValue("operationalDeduction") || [];
    const updatedOperations = [...operationsField];

    // Store old type before changing
    const oldType = updatedOperations[index]?.type;
    updatedOperations[index] = { ...updatedOperations[index], type: value };

    form.setFieldsValue({ operationalDeduction: updatedOperations });

    // Get all selected types excluding "Others"
    const selectedTypes = updatedOperations
      .map((op) => op?.type)
      .filter((type) => type && type !== "Others");

    // Dynamically filter available operations
    setSelectedOperations(OPERATION_OPTIONS.filter((option) => !selectedTypes.includes(option)));
  };


  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      message.destroy();
      await form.validateFields();

      const taxSlabValue =
        values.professionalTaxSlab === "Others"
          ? Number(values.customProfessionalTaxSlab || 0)
          : Number(values.professionalTaxSlab);


      const roleData = {
        id: type === "edit" ? id : `ROLE${Math.floor(Math.random() * 1000)}`,
        designation: values.designation,
        department: values.department,
        description: values.description,
        primaryEmployeerId: primaryEmployerId.primaryEmployerId,
        shift: values.shift,
        operationalDeduction: values.operationalDeduction,
        taxSlab: taxSlabValue,
        grossSalary: values.salary.gross,
        salaryPerPeriod: values.salary.period,
        basicPay: values.basicPay,
        dateOfJoining: values.dateOfJoining,
        da: values.da,
        hra: values.hra,
        specialAllowance: values.specialAllowance,
        earnings: values.earnings,
        deductions: values.deductions,
      };
      console.log(roleData, "roleData123");
      validateTotalEarnings(roleData);

      let response;
      if (type === "edit") {
        response = await updateJobRole(id, roleData);
        // message.success("Job role updated successfully1");
      } else {
        response = await createJobRole(roleData);
        // message.success("Job role created successfully");
      }
      // Ensure response is valid before checking its properties

      if (response?.status === 200) {
        message.success(response?.message);
      } else {
        message.error(response?.message);
      }

      onSuccess(response);
      form.resetFields();
      setAvailableEarnings([...EARNING_OPTIONS]);
      setAvailableDeductions([...DEDUCTION_OPTIONS]);
      setCompletedDeductions([]);
    } catch (error) {
      console.error("Error submitting job role:", error);
      message.error(
        error.message ||
        `Failed to ${type === "edit" ? "update" : "create"} job role`
      );
    } finally {
      setLoading(false);
    }
  };

  const validateTotalEarnings = (roleData) => {
    console.log(roleData, "roleData")
    const totalFixedEarnings =
      Number(roleData.basicPay) +
      Number(roleData.da) +
      Number(roleData.hra) +
      Number(roleData.specialAllowance);
    const totalAdditionalEarnings = roleData.earnings?.reduce(
      (sum, earning) => sum + Number(earning.amount || 0),
      0
    );
    const totalDeductions = roleData.deductions?.reduce(
      (sum, deduction) => sum + Number(deduction.amount || 0),
      0
    );
    const totalOperationalDeductions = roleData.operationalDeduction?.reduce(
      (sum, deduction) => sum + Number(deduction.amount || 0),
      0
    );

    const totalProfessionalTaxSlab = Number(roleData.taxSlab);

    const totalCalculated =
      totalFixedEarnings +
      totalAdditionalEarnings +
      totalDeductions + totalOperationalDeductions + totalProfessionalTaxSlab;

    if (
      Number(totalCalculated.toFixed(2)) !==
      Number(roleData.grossSalary)
    ) {
      throw new Error(
        // `Gross Salary must tally all fields. Expected: ${
        //   roleData.grossSalary
        // }, Calculated: ${totalCalculated.toFixed(2)}`
        `The salary breakdown does not match with the stated gross salary ${roleData.grossSalary
        }.`
      );
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedOperations([]);
    setAvailableEarnings([...EARNING_OPTIONS]);
    setAvailableDeductions([...DEDUCTION_OPTIONS]);
    setCompletedDeductions([]);
    onClose();
    setUserData((prev) => ({
      ...prev,
      isJobRoleModalVisible: false,
    }));
  };

  const handleEarningTypeChange = (value, index) => {
    const earningsField =
      form.getFieldValue(["salary", "breakup", "additionalEarnings"]) || [];
    const updatedEarnings = [...earningsField];
    const oldType = updatedEarnings[index]?.type;
    updatedEarnings[index] = { ...updatedEarnings[index], type: value };
    form.setFieldsValue({
      salary: { breakup: { additionalEarnings: updatedEarnings } },
    });

    setAvailableEarnings((prev) => {
      let newAvailable = [...prev];
      if (oldType && oldType !== "Others" && !newAvailable.includes(oldType)) {
        newAvailable.push(oldType);
      }
      if (value !== "Others") {
        newAvailable = newAvailable.filter((option) => option !== value);
      }
      return newAvailable.sort();
    });
  };

  // const handleDeductionTypeChange = (value, index) => {
  //   const deductionsField = form.getFieldValue("deductions") || [];
  //   const updatedDeductions = [...deductionsField];
  //   const oldType = updatedDeductions[index]?.type;
  //   updatedDeductions[index] = { ...updatedDeductions[index], type: value };
  //   form.setFieldsValue({ deductions: updatedDeductions });

  //   setAvailableDeductions((prev) => {
  //     let newAvailable = [...prev];
  //     if (oldType && oldType !== "Others" && !newAvailable.includes(oldType)) {
  //       newAvailable.push(oldType);
  //     }
  //     if (value !== "Others") {
  //       newAvailable = newAvailable.filter((option) => option !== value);
  //     }
  //     return newAvailable.sort();
  //   });
  // };

  const handleDeductionTypeChange = (value, index) => {
    const deductionsField = form.getFieldValue("deductions") || [];
    const updatedDeductions = [...deductionsField];

    // Store old type before changing
    const oldType = updatedDeductions[index]?.type;
    updatedDeductions[index] = { ...updatedDeductions[index], type: value };

    form.setFieldsValue({ deductions: updatedDeductions });

    // Get all selected types excluding "Others"
    const selectedTypes = updatedDeductions
      .map((deduction) => deduction?.type)
      .filter((type) => type && type !== "Others");

    // Derive available deductions dynamically
    setAvailableDeductions(
      DEDUCTION_OPTIONS.filter((option) => !selectedTypes.includes(option))
    );
  };


  return (
    <Modal
      // style={{ top: 40, maxHeight: "90vh" }} // Adjust height here
      // bodyStyle={{
      //   overflowX: "hidden",
      //   overflowY: "auto",
      //   maxHeight: "calc(100vh - 350px)",
      //   paddingRight: "14px", // Adjust padding to shift the scroll bar slightly
      // }}

      style={{
        top: 40, // Decrease top slightly to center it more
        maxHeight: "85vh", // Slightly increase the modal height
      }}
      bodyStyle={{
        overflowX: "hidden",
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)", // Reduce the subtraction to increase modal height slightly
        paddingRight: "14px", // Maintain the padding for scrollbar adjustment
        paddingBottom: "20px", // Add some padding at the bottom
      }}

      title={
        <div style={{ textAlign: "center", marginBottom: "0" }}>
          <h2 style={{ margin: "0", height: '23px' }}>
            {type === "edit" ? "Edit Job Role" : "New Job Role"}
          </h2>
        </div>
      }
      closable={true}
      visible={visible}
      onCancel={handleCancel}
      onOk={async () => {
        try {
          await form.validateFields();
          form.submit();
        } catch (error) { }
      }}
      confirmLoading={loading}
      width={750}
    >
      <Form
        form={form}
        layout="vertical"
        className="role-form"
        onValuesChange={(changedValues, allValues) => {
          console.log(allValues, "allValues12222")
          const basicPay = allValues?.basicPay || 0;
          const da = allValues?.da || 0;
          const grossSalary = allValues?.salary?.gross || 0;
          console.log(grossSalary, "grossSalary")
          let pfEmployeeContribution, adminCharges, esiEmployee, esiEmployeer;
          if ((basicPay && da) || grossSalary) {
            //pfEmployeeContribution = basicPay * 0.12 + Number(da);
            //adminCharges = basicPay * 0.01 + Number(da);
            pfEmployeeContribution = (Number(basicPay) + Number(da)) * 0.12;
            adminCharges = (Number(basicPay) + Number(da)) * 0.01;
            esiEmployee = grossSalary * 0.0075;
            esiEmployeer = grossSalary * 0.0325;
          }
          const deductions = allValues.deductions || [];
          const updatedDeductions = deductions.map((deduction) => {
            if (
              deduction?.type === "PF Employee Contribution" ||
              deduction?.type === "PF Employer Contribution"
            ) {
              return { ...deduction, amount: pfEmployeeContribution };
            } else if (deduction?.type === "ESI Employee Contribution") {
              return { ...deduction, amount: esiEmployee };
            } else if (deduction?.type === "ESI Employer Contribution") {
              return { ...deduction, amount: esiEmployeer };
            } else if (deduction?.type === "Admin Charges") {
              return { ...deduction, amount: adminCharges };
            } else if (deduction?.type === "Others") {
              return deduction;
            }
            return deduction;
          });

          form.setFieldsValue({ deductions: updatedDeductions });
        }}
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col span={12}>
            <div className="form-field-container">
              <label>Job Role Name</label>
              <Form.Item
                name="designation"
                rules={[
                  { required: true, message: "Please enter designation" },
                  {
                    max: 50,
                    message: "Designation cannot exceed 50 characters",
                  },
                ]}
              // className="form-item-with-error"
              >
                <Input placeholder="Enter job title" maxLength={50} />
              </Form.Item>
            </div>{" "}
            <div className="form-field-container">
              <label>Department</label>
              <Form.Item
                name="department"
                // className="form-item-with-error"
                rules={[
                  { required: true, message: "Please enter department" },
                  {
                    max: 50,
                    message: "Department cannot exceed 50 characters",
                  },
                ]}
              >
                <Input placeholder="Enter Department" maxLength={50} />
              </Form.Item>
            </div>
            <Row gutter={24} style={{ padding: "35px 0px 0px 0px" }}>
              <Col span={12}>
                <div className="form-field-container">
                  <label>Gross Salary</label>
                  <Form.Item required className="mb-8">
                    <Form.Item
                      // className="form-item-with-error"
                      name={["salary", "gross"]}
                      rules={[
                        { required: true, message: "Gross salary is required" },
                      ]}
                    // validateTrigger={["onBlur", "onSubmit"]}
                    >
                      <Input
                        min={0}
                        placeholder="00"
                        prefix={<span>₹</span>}
                        style={{ width: 200 }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault(); // Prevent non-numeric characters
                          }
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
              </Col>

              <Col span={12}>
                <div className="form-field-container" style={{ margin: '21px 0 0 25px' }}>
                  <label></label>
                  <Form.Item
                    // className="form-item-with-error"
                    name={["salary", "period"]}
                    rules={[{ required: true, message: "Period is required" }]}
                  >
                    <Select style={{ width: 120 }} getPopupContainer={(triggerNode) => triggerNode.parentElement}>
                      <Option value="per day">per day</Option>
                      <Option value="per month">per month</Option>
                      <Option value="per year">per year</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <div className="form-field-container">
              <label>Description</label>
              <Form.Item
                // className="form-item-with-error"
                name="description"
                rules={[
                  { required: true, message: "Please enter description" },
                  {
                    max: 250,
                    message: "Description cannot exceed 250 characters",
                  },
                ]}
              >
                <Input.TextArea
                  rows={5}
                  placeholder="Enter details"
                  maxLength={250}
                />
              </Form.Item>
            </div>

            <div
              className="form-field-container"
              style={{
                padding: "15px 0 0 0",
                width: "180px",
                height: "50px",
              }}
            >
              <label>Shift</label>
              <Form.Item
                name="shift"
                // label="Shift"
                rules={[{ required: true, message: "Please select shift" }]}
              >
                <Select placeholder="Select shift" getPopupContainer={(triggerNode) => triggerNode.parentElement}>
                  {SHIFT_OPTIONS.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col span={8}></Col>
          <Col span={8}></Col>
          {/* <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.professionalTaxSlab !==
                currentValues.professionalTaxSlab
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("professionalTaxSlab") === "Others" ? (
                  <Form.Item
                    name="customProfessionalTaxSlab"
                    label="Custom Tax Slab"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter custom professional tax slab amount",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="₹ 00"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24} style={{ padding: "15px 0px 0px 0px" }}>
          <Col span={12}>
            <div className="form-field-container">
              <label>Date Of Joining</label>
              <Form.Item
                name="dateOfJoining"
                rules={[{ required: true, message: "Please select a start date" }]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  type="date"
                  placeholder="Select date"
                  min={new Date().toISOString().split("T")[0]}
                  style={{
                    width: "100%",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    border: "1px solid #d9d9d9",
                    fontSize: "14px",
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <h3
          className="mb-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Salary Splitup
        </h3>
        <Row gutter={24}>
          <Col span={12}>
            <div className="salary-splitup">
              <div className="form-field-container">
                <label>Earning title - 1</label>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Input
                    value="Basic Pay"
                    disabled
                    style={{
                      width: "150px",
                      height: "30px",
                      pointerEvents: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                  />
                  <Form.Item
                    name="basicPay"
                    // className="form-item-with-error"
                    rules={[
                      { required: true, message: "Please enter the amount" },
                    ]}
                  >
                    <Input
                      placeholder="00"
                      maxLength={50}
                      style={{ width: "185px" }}
                      prefix={<span>₹</span>}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-field-container">
                <label>Earning Title - 2</label>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Input
                    value="D A"
                    disabled
                    style={{
                      width: "150px",
                      height: "30px",
                      pointerEvents: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                  />

                  <Form.Item
                    name="da"
                    // className="form-item-with-error"
                    rules={[
                      { required: true, message: "Please enter the amount" },
                    ]}
                  >
                    <Input
                      placeholder="00"
                      prefix={<span>₹</span>}
                      maxLength={50}
                      style={{ width: "185px" }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-field-container">
                <label>Earning Title - 3</label>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Input
                    value="HRA"
                    disabled
                    style={{
                      width: "150px",
                      height: "30px",
                      pointerEvents: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                  />

                  <Form.Item
                    name="hra"
                    // className="form-item-with-error"
                    rules={[
                      { required: true, message: "Please enter the amount" },
                    ]}
                  >
                    <Input
                      placeholder="00"
                      prefix={<span>₹</span>}
                      maxLength={50}
                      style={{ width: "185px" }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-field-container">
                <label>Earning Title - 4</label>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Input
                    value="Special Allowance"
                    disabled
                    style={{
                      width: "150px",
                      pointerEvents: "none",
                      height: "30px",
                      backgroundColor: "#f5f5f5",
                    }}
                  />

                  <Form.Item
                    name="specialAllowance"
                    // className="form-item-with-error"
                    rules={[
                      { required: true, message: "Please enter the amount" },
                    ]}
                  >
                    <Input
                      placeholder="00"
                      prefix={<span>₹</span>}
                      maxLength={50}
                      style={{ width: "185px" }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.List name="earnings" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key} style={{ marginBottom: "16px" }}>
                        {/* Earning Title Label */}
                        <label
                          style={{
                            display: "block",
                            marginBottom: "8px",
                          }}
                        >
                          Earning Title - {index + 5}
                        </label>

                        {/* Inputs Row */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          {/* Earning Type (Left) */}
                          <Form.Item
                            {...field}
                            name={[field.name, "type"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select earning type",
                              },
                            ]}
                            style={{ flex: 1, marginBottom: 0 }}
                          >
                            <Select
                              placeholder="Select earning type"
                              onChange={(value) =>
                                handleEarningTypeChange(value, index)
                              }
                              getPopupContainer={(trigger) => trigger.parentElement}
                              dropdownMatchSelectWidth={false}
                            >
                              {availableEarnings.map((option) => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {form.getFieldValue([
                            "earnings",
                            field.name,
                            "type",
                          ]) === "Others" && (
                              <Form.Item
                                {...field}
                                name={[field.name, "title"]}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter a title for Others",
                                  },
                                ]}
                                style={{ flex: 1, marginBottom: 0 }}
                              >
                                <Input
                                  placeholder="Enter title"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            )}

                          <Form.Item
                            {...field}
                            name={[field.name, "amount"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter the amount",
                              },
                            ]}
                            style={{ flex: 1, marginBottom: 0 }}
                          >
                            <Input
                              min={0}
                              placeholder="00"
                              style={{ width: "100%" }}
                              prefix={<span>₹</span>}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => {
                              const currentEarnings =
                                form.getFieldValue("earnings") || [];
                              const removedType =
                                currentEarnings[field.name]?.type;
                              remove(field.name);
                              if (removedType && removedType !== "Others") {
                                setAvailableEarnings((prev) =>
                                  [...prev, removedType].sort()
                                );
                              }
                            }}
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        onClick={() => add()}
                        type="primary"
                        size="small"
                        className="input"
                        disabled={availableEarnings.length === 0}
                      >
                        +
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </Col>
          <Col span={12}>
            <div className="deduction-splitup">
              <Form.List name="deductions" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key}
                        style={{
                          // marginBottom: "0px",
                          marginBottom: "12px",
                          padding: "2px",
                        }}
                      >
                        <div style={{ fontWeight: "600", marginBottom: "8px" }}>
                          Deduction title - {index + 1}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "type"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select deduction",
                              },
                            ]}
                            style={{ flex: 1, marginBottom: 0, width: "140px" }}
                          >
                            <Select
                              placeholder="Select deduction type"
                              onChange={(value) =>
                                handleDeductionTypeChange(value, index)
                              }
                              getPopupContainer={(trigger) => trigger.parentElement}
                              dropdownMatchSelectWidth={false}
                            >
                              {availableDeductions.map((option) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          {/* Title Input for "Others" */}
                          {form.getFieldValue(["deductions", index, "type"]) ===
                            "Others" && (
                              <Form.Item
                                // {...field}
                                name={[field.name, "title"]}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input deduction title",
                                  },
                                ]}
                                style={{ flex: 1, marginBottom: 0 }}
                              >
                                <Input placeholder="Enter title" />
                              </Form.Item>
                            )}

                          {/* Deduction Amount */}
                          <Form.Item
                            {...field}
                            name={[field.name, "amount"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input amount",
                              },
                            ]}
                            style={{ flex: 1, marginBottom: 0 }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="00"
                              prefix={<span>₹</span>}
                              disabled={
                                form.getFieldValue([
                                  "deductions",
                                  field.name,
                                  "type",
                                ]) !== "Others"
                              }
                              style={{ width: "100%" }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => {
                              const currentDeductions =
                                form.getFieldValue("deductions") || [];
                              if (currentDeductions.length > 1) {
                                const removedType =
                                  currentDeductions[field.name]?.type;
                                remove(field.name);
                                if (removedType && removedType !== "Others") {
                                  setAvailableDeductions((prev) =>
                                    [...prev, removedType].sort()
                                  );
                                }
                              }
                            }}
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              marginTop: "8px",
                            }}
                          />
                        </div>
                      </div>
                    ))}

                    {/* Add Deduction Button */}
                    <Form.Item>
                      <Button
                        type="primary"
                        size="small"
                        className="input"
                        onClick={() => add()}
                      >
                        +
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className="operational-deduction-splitup">
              <h3 className="mb-4">Operational Deductions</h3>
              <Form.List name="operationalDeduction" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key}
                        style={{
                          // marginBottom: "-25px",
                          marginBottom: "12px",
                          padding: "1px",
                        }}
                      >
                        <label
                          style={{
                            display: "block",
                            marginBottom: "8px",
                          }}
                        >
                          Operational Deduction - {index + 1}
                        </label>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "16px",
                          }}
                        >
                          {/* Select Type Field */}
                          <Form.Item
                            {...field}
                            name={[field.name, "type"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select type",
                              },
                            ]}
                            style={{ width: "2%", flex: 1, marginBottom: 0 }}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select type"
                              onChange={(value) =>
                                handleOperationTypeChange(value, index)
                              }
                              getPopupContainer={(trigger) => trigger.parentElement}
                              dropdownMatchSelectWidth={false}
                            >
                              {selectedOperations.map((option) => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          {/* Amount Field */}
                          <Form.Item
                            {...field}
                            name={[field.name, "amount"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input amount",
                              },
                            ]}
                            style={{ flex: 1, marginBottom: 0 }}
                          >
                            <Input
                              min={0}
                              placeholder="00"
                              prefix={<span>₹</span>}
                              style={{ width: "100%" }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => {
                              // remove(field.name);
                              // setSelectedOperations((prev) => {
                              //   const newSelected = [...prev];
                              //   newSelected.splice(index, 1);
                              //   return newSelected;
                              // });
                              const currentOperations = form.getFieldValue("operationalDeduction") || [];

                              if (currentOperations.length > 1) {
                                const removedType = currentOperations[field.name]?.type;
                                remove(field.name);

                                // Restore removed type dynamically
                                if (removedType && removedType !== "Others") {
                                  setSelectedOperations((prev) =>
                                    [...prev, removedType].filter((option) => OPERATION_OPTIONS.includes(option)).sort()
                                  );
                                }
                              }
                            }}
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              marginTop: "6px",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    {/* Add Button */}
                    <Form.Item>
                      <Button
                        onClick={() => add()}
                        disabled={selectedOperations.length === 0}
                        type="primary"
                        size="small"
                        className="input"
                      >
                        +
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div
              className="form-field-container"
              style={{ width: "180px", height: "80px" }}
            >
              <label>Professional Tax Slab</label>
              <Form.Item
                name="professionalTaxSlab"
                // className="form-item-with-error"
                rules={[
                  {
                    required: true,
                    message: "Please select Professional Tax Slab",
                  },
                ]}
              >
                <Select
                  placeholder="Select Professional Tax Slab"
                  //onChange={(value) => setShowCustomTaxSlab(value === "Others")}
                  prefix={selectedValue && selectedValue !== "Others" ? <span>₹</span> : null}
                  onChange={(value) => {
                    setShowCustomTaxSlab(value === "Others");
                    setSelectedValue(value);
                    if (value !== "Others") {
                      form.setFieldsValue({ customProfessionalTaxSlab: undefined }); // Clear custom value if not "Others"
                    }
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement} // Ensures dropdown is within the modal
                  dropdownMatchSelectWidth={false} // Prevents dropdown from stretching awkwardly
                  style={{ width: "100%" }}
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                  <Select.Option value="150">150</Select.Option>
                  <Select.Option value="200">200</Select.Option>
                  <Select.Option value="Others">Others</Select.Option>
                </Select>
              </Form.Item>

              {showCustomTaxSlab && (
                <Form.Item
                  name="customProfessionalTaxSlab"
                  // className="form-item-with-error"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter custom Professional Tax Slab amount",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    placeholder="00"
                    style={{ width: "100%" }}
                    prefix={<span>₹</span>}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault(); // Prevent non-numeric characters
                      }
                    }}
                  />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default JobRoleForm;
