import { getAuth } from "@firebase/auth";
import { createContext, useEffect, useState } from "react";
import { useAuthContext } from "./auth";
import { onAuthStateChanged } from "@firebase/auth";

export const dataContext = createContext([]);

// const [data, setContextData] = useState('hello');

// export {setContextData};

const DataProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    activeTabIndex: "1",
    activeContractTabIndex: "1",
    activePrimaryTabIndex: "1",
    filterMode: false,
    page: 0,
    confirmPage: 0,
    scrollable: true,
    filterValue: [],
    filterQuery: {},
    sortColumn: undefined,
    isSort: true,
    empTalSort: undefined,
    isEmpTalSort: false,
    filterQueryValue: undefined,
    isModalVisible: false,
    isJobRoleModalVisible: false, 
    isContractorUserModalVisible: false, 
    editUser: {  // Ensure 'editUser' is part of the 'userData'
      key: "",
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      logo: "",
      linkedPrimaryEmployers: [],
    } 
  });
  const [dashboardData, setDashboardData] = useState({
    activeTabIndex: "1",
    activeContractTabIndex: "1",
    activePrimaryTabIndex: "1",
    filterMode: false,
    page: 0,
    confirmPage: 0,
    scrollable: true,
    filterValue: [],
    totalTalentFilterValue: [],
    totalTalentPage: 0,
    filterQuery: {},
    sortColumn: undefined,
    isSort: true,
    filterQueryValue: undefined,
  });
  const [jobData, setJobData] = useState({
    activeTabIndex: "1",
    activeContractTabIndex: "1",
    activePrimaryTabIndex: "1",
    postSuccess: true,
    postDelete: true,
    filterMode: false,
    page: 0,
    rolesPage: 0,
    confirmPage: 0,
    scrollable: true,
    postEditSuccess: true,
    filterValue: [],
    responsePage: 0,
    editTrigger: true,
    roleAddSuccess: true,
    sortColumn: undefined,
    isSort: true,
  });
  const [requestTabData, setRequestTabData] = useState({
    activeTabIndex: "1",
    sortColumn: undefined,
    isSort: true,
    page: 0,
    detachTablepage: 0,
    lastFetch: undefined,
    detachLastFetch: undefined,
  });
  const [navList, setNavList] = useState({
    dashBoard: true,
    userList: false,
    jobPost: false,
    requests: false,
    role:false,
    whatsapp: false
  });
  const [searchState, setSearchState] = useState({
    talents: [],
    contractor: null,
    userType: "",
    columnTalent: [],
    columns: [],
    contractorColumns: [],
    selectedColumn: null,
    searchField: "All",  // Add this
    searchTerm: ""    // Add this
    //navList: { dashBoard: true, userList: false, jobPost: false, requests: false },
});
  const [moreOptionVisible, setMoreOptionVisible] = useState(false);
  const [testData, setTestData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [isRefreshRole, setIsRefreshRole] = useState(false);
  const authContext = useAuthContext();
  const auth = getAuth();
  const user = auth.currentUser;

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      authContext.signOut();
    }
  });

  return (
    <dataContext.Provider
      value={{
        userData,
        setUserData,
        dashboardData,
        setDashboardData,
        navList,
        setNavList,
        jobData,
        setJobData,
        moreOptionVisible,
        setMoreOptionVisible,
        requestTabData,
        setRequestTabData,
        testData,
        setTestData,
        roleData,
        setRoleData,
        isRefreshRole,
        setIsRefreshRole,
        searchState,
        setSearchState
      }}
    >
      {children}
    </dataContext.Provider>
  );
};

export default DataProvider;
