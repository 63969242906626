import React, { useState } from "react";
import { Modal, Form, Button, DatePicker, Input, message } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createPost, updatePost } from "../../../backendApi";
import dayjs from "dayjs";
const { TextArea } = Input;

const TalentRemoveModal = ({
  isDetached,
  closeTalentHandler,
  detachTalents,
  updateTalentEmployeeData,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const detachedTalent = async (detachTalentId, detachTalentData) => {
    const res = await createPost("/talnt/detachedTalnt", {
      detachTalentId,
      detachTalentData,
    });
    if (res.status === 200) {
      let update = updateTalentEmployeeData(res.data.data);
      message.success({
        content: "Detach talent successfully",
        key: "detached",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();
      let talent = detachTalents.filter((talent) => talent.isChecked);

      const detachTalentId = [];
      const detachTalentData = talent.map((ele) => {
        detachTalentId.push(ele.id);
        return {
          talntId: ele.id,
          dateOfLeave: new Date(values.dobJoin),
          reason: values.detachedReason,
        };
      });
      form.resetFields();
      detachedTalent(detachTalentId, detachTalentData);
      message.success("Talent removed successfully!");
      closeTalentHandler();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Modal
      title="Remove Talent"
      open={isDetached}
      centered
      onCancel={() => {
        form.resetFields();
        closeTalentHandler();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            closeTalentHandler();
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="dobJoin"
          label="Select DOE"
          rules={[{ required: true, message: "Please select a date!" }]}
          style={{ marginBottom: "5%" }}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Choose a date"
            disabledDate={(current) => {
              if (!detachTalents[0]?.dateOfJoin) return true;

              const joinDate = dayjs(
                detachTalents[0].dateOfJoin,
                "D/M/YYYY"
              ).startOf("day");
              const today = dayjs().startOf("day");

              return (
                current.isBefore(joinDate, "day") ||
                current.isAfter(today, "day")
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name="detachedReason"
          label="Talent Detached Reason"
          rules={[
            {
              required: true,
              message: (
                <p style={{ marginTop: "10%" }}>Please enter a reason!</p>
              ),
            },
            {
              min: 3,
              message: (
                <p style={{ marginTop: "10%" }}>
                  Reason must be at least 3 characters!
                </p>
              ),
            },
          ]}
          className="custom-form-item"
        >
          <TextArea placeholder="Enter reason for detaching talent" rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TalentRemoveModal;
