import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./context/auth";
import DataProvider from "./context/data";
// import Contract from "./route/Contract";
import Login from "./route/Login";
import Primary from "./route/Primary";
import { PrivateRoute } from "./route/PrivateRoute";
import SpectrawiseAdmin from "./route/SpectrawiseAdmin";
import "./styles/Base.scss";
import "./firebase";
import "./styles/custom-antd.css";
import { useEffect, useState } from "react";
// import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { fetchUserInfo, fetchPermissions } from "./component/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchData } from "./backendApi";

function App() {
  const dispatch = useDispatch();
  const [useInformation, setUserInformation] = useState({});
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch(fetchUserInfo(userInfo));
  }, []);

  const userDetail = useSelector(({ user: { userInfo } }) => {
    if (userInfo && Object.keys(userInfo).length > 0) {
      return userInfo;
    }
  });

  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length > 1) {
      setUserInformation(userDetail);
      fetchUserRole(userDetail);
    }
  }, [userDetail]);

  async function fetchUserRole(userDetail) {
    try {
      console.log(userDetail);
      const res = await fetchData(
        `/permission/rolePermissions/${userDetail.id}?mobile=${userDetail.mobileNumber}&userType=${userDetail.userType}`
      );
      console.log(res.data.data)
      localStorage.setItem("roleName", res?.data?.data?.getUserRole?.roleName);
      dispatch(fetchPermissions(res.data.data));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <DataProvider>
            <PrivateRoute path="/Talntworx">
              <Route path="/Talntworx">
                <SpectrawiseAdmin />
              </Route>
            </PrivateRoute>
            {/* <PrivateRoute path="/contract">
              <Route path="/contract">
                <Contract />
              </Route>
            </PrivateRoute> */}
            {/* <PrivateRoute path="/primary">
              <Route path="/primary">
                <Primary />
              </Route>
            </PrivateRoute> */}
          </DataProvider>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
