import { useState, useEffect } from "react";

const useDeviceType = () => {
  const [device, setDevice] = useState("Desktop");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024 && width <= 1280) {
        setDevice("Laptop");
      } else if (width >= 1281 && width <= 1400) {
        setDevice("maxLaptop");
      } else if (width >= 1401 && width <= 1600) {  // Covering all screens above 1400px
        setDevice("Desktop");
      }
      else if (width >= 1601 && width <= 1921) {  // Covering all screens above 1400px
        setDevice("maxDesktop");
      } else {
        setDevice("Mobile/Tablet");
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return device;
};

export default useDeviceType;
