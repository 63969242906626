import React, { useEffect, useState } from "react";
import TopBar from "../component/TopBar";
import { Route, Switch } from "react-router-dom";
import DashBoard from "../component/DashBoard";
import JobList from "../component/JobList";
import "../styles/navBar.scss";
import UserPanel from "../component/UserPanel";
import UserDetailsBase from "../component/panel/UserDetails/UserDetailsBase";
import "../firebase";
// import TotalTalents from '../component/dashboard/TotalTalents';
import TotalTalentsView from "../component/dashboard/TotalTalentsView";
import Requests from "../component/Requests";
import RoleDetailBase from "../component/joblist/RoleBase";
import JobPostDetails from "../component/jobPost/JobPostDetails";
import JobRoleDetails from "../component/jobRole/JobRoleDetails";
import RoleManagement from "../component/rolemanagement/role";
import { useSelector } from "react-redux";
import DashboardUI from "../component/DashboardUI/DashboardUI";
import Whatsapp from "../component/Whatsapp";

function SpectrawiseAdmin(props) {
  const userType = "spectra";
  const userPath = "Talntworx";

  let permission = useSelector(
    (state) => state?.user?.permission?.getUserRole?.permission
  );
  const userDetail = useSelector(({ user: { userInfo } }) => {
    if (userInfo && Object.keys(userInfo).length > 0) {
      return userInfo;
    }
  });

  let { userStatus } = useSelector((state) => state?.user.permission);

  let [userMenu, setUserMenu] = useState({
    user: false,
    job: false,
    role: false,
  });

  let findMainMenuPermission = (permission) => {
    const hasUserAccess = [
      "Talent",
      "Bizops",
      "Contractors",
      "Principle Employer",
    ].some((key) =>
      ["read", "write", "edit", "delete"].some(
        (perm) => permission[key]?.[perm]
      )
    );

    const hasJobAccess = ["read", "write", "edit", "delete"].some(
      (perm) => permission["Job Post"]?.[perm]
    );

    const hasRoleAccess = ["read", "write", "edit", "delete"].some(
      (perm) => permission["Role Management"]?.[perm]
    );

    setUserMenu((prev) => {
      return {
        ...prev,
        user: hasUserAccess,
        job: hasJobAccess,
        role: hasRoleAccess,
      };
    });
  };

  useEffect(() => {
    if (permission && Object.keys(permission).length) {
      findMainMenuPermission(permission);
    }
  }, [permission]);

  return (
    <div className="body">
      <TopBar userPath={userPath} />

      <div className="content">
        <Switch>
          <Route path="/Talntworx" exact>
            {userDetail?.userType === "Bizops" ? (
              <DashboardUI userPath={userPath} />
            ) : (
              <DashBoard userType={userType} userPath={userPath} />
            )}
          </Route>
          {userMenu?.user && userStatus !== "In Active" ? (
            <Route path="/Talntworx/userPanel">
              <UserPanel userType={userType} userPath={userPath} />
            </Route>
          ) : null}
          {userMenu?.job && userStatus !== "In Active" && (
            <Route path="/Talntworx/jobpost">
              <JobList userType={userType} userPath={userPath} />
            </Route>
          )}
          <Route path="/Talntworx/requests">
            <Requests userType={userType} userPath={userPath} />
          </Route>
          {userStatus !== "In Active" && (
            <Route path="/Talntworx/whatsapp">
              <Whatsapp userType={userType} userPath={userPath} />
            </Route>
          )}
          <Route path={"/" + userPath + "/user"}>
            <UserDetailsBase userType={userType} userPath={userPath} />
          </Route>
          {userStatus !== "In Active" && (
            <Route path={"/" + userPath + "/totalTalents"}>
              <TotalTalentsView userPath={userPath} />
            </Route>
          )}

          <Route path="/Talntworx/job-roles/:id">
            <JobRoleDetails />
          </Route>
          {userStatus !== "In Active" && (
            <Route path="/Talntworx/role">
              <RoleManagement userType={userType} userPath={userPath} />
            </Route>
          )}

          {userStatus !== "In Active" && (
            <Route path="/Talntworx/job-posts/:id">
              <JobPostDetails />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
}

export default SpectrawiseAdmin;
