import React, { useState, useEffect, useCallback, useContext } from "react";
import { Table, Button, Input, Space, Select, message, Modal, Spin } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import {
  createContractUser,
  deleteContractUser,
  fetchContractUsersList,
  updateContractUser,
} from "../../services/contractUserService";
import ContractUserForm from "./ContractUserForm";
import { createContractor } from "../../services/contractService";
import ResponsiveTable from "../panel/panelHead/ResponsiveTable";
import { useSelector } from "react-redux";
import { dataContext } from "../../context/data";

const { Option } = Select;
const { Search } = Input;

const ContractUserList = ({ contract_id, primaryCname }) => {
  const [contractUsers, setContractUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const { userData, setUserData } = useContext(dataContext);
  const history = useHistory();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const { searchState, setSearchState } = useContext(dataContext);
  const primaryContractorId = contract_id;

  let permission = useSelector((state) => {
    return state?.user?.permission?.getUserRole?.permission;
  });
  console.log(searchState, "searchState")
  // useEffect(() => {
  //   if (permission) {
  //     ;
  //   }
  // }, [permission]);

  const loadContractUsers = useCallback(
    async (pageNum) => {
      if (!hasMore && pageNum > 1) return;
      if (pageNum === 1) setInitialLoading(true);
      setLoading(true);
    
      try {
        const searchParams = {
          // searchField,
          // searchTerm,
          searchField: searchState.searchField,
          searchTerm: searchState.searchTerm,
          primaryContractorId,
        };
        const response = await fetchContractUsersList(
          pageNum,
          10,
          primaryContractorId,
          searchParams
        );
        const newUsers = response.data;
        console.log(newUsers, "newUsers")
        const total = response.totalCount || newUsers.length;
        setContractUsers((prevUsers) =>
          pageNum === 1 ? newUsers : [...prevUsers, ...newUsers]
        );
        setTotalItems(total);
        setHasMore(newUsers.length === 10);
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading contract users:", error);
        message.error("Failed to load contract users");
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    },
    [searchState.searchField, searchState.searchTerm, primaryContractorId]
  );

  // useEffect(() => {
  //   // Reset search state when the component mounts
  //   setSearchState({ searchField: "All", searchTerm: "" });
  
  //   return () => {
  //     // Optional: Reset again when component unmounts (if needed)
  //     setSearchState({ searchField: "All", searchTerm: "" });
  //   };
  // }, []);

  const debouncedSearch = useCallback(
    debounce(() => {
      setContractUsers([]);
      setPage(1);
      setHasMore(true);
      loadContractUsers(1);
    }, 300),
    [loadContractUsers]
  );

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchState.searchField, searchState.searchTerm, debouncedSearch]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      loadContractUsers(page + 1);
    }
  };

  const handleCreate = () => {
    setEditingUser(null);
    setModalVisible(true);
  };

  useEffect(() => {
    if (userData.isContractorUserModalVisible) {
      setEditingUser(null);
      setModalVisible(true);
      setUserData((pre) => {
        return { ...pre, reload: !pre.reload };
      });
      //setType("create");
    }
  }, [userData.isContractorUserModalVisible]);

  const handleEdit = (user) => {
    setEditingUser(user);
    setModalVisible(true);
    setUserData((pre) => {
      return { ...pre, reload: !pre.reload };
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteContractUser(id);
      message.success("Contract user deleted successfully");
      setContractUsers([]);
      setPage(1);
      setHasMore(true);
      loadContractUsers(1);
      setDeleteConfirmVisible(false);
    } catch (error) {
      console.error("Error deleting contract user:", error);
      message.error("Failed to delete contract user");
    }
  };

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      if (editingUser) {
        const res = await updateContractUser(editingUser.id, values);
        if (res.status === 200) {
          message.success("Contract user created successfully");
        } else {
          message.error(res?.message);
        }
      } else {
        const res = await createContractUser(values);
        if (res.status === 200) {
          message.success("Contract user created successfully");
        } else {
          message.error(res?.message);
        }
      }
      setModalVisible(false);
      setUserData((prev) => ({
        ...prev,
        isContractorUserModalVisible: false, // Example: Show a modal or perform an action
      }));
      setEditingUser(null);
      loadContractUsers(1);
    } catch (error) {
      console.error("Error updating contract user:", error);
      message.error("Failed to update contract user");
    }
  };

  const columns = [
    { title: "NAME", dataIndex: "fullName", key: "fullName" },
    { title: "Business Name", dataIndex: "businessName", key: "businessName" },
    { title: "Mobile Number", dataIndex: "mobileNumber", key: "mobileNumber" },
    {
      title: "Role Name",
      dataIndex: "Rolemanagement",
      key: "roleName",
      render: (role) => role?.roleName || "N/A"
    }
  ];

  if (
    permission["Contractors User"].edit ||
    permission["Contractors User"].delete
  ) {
    columns.push({
      title: "ACTIONS",
      key: "actions",
      render: (_, record) => (
        <Space size="small">
          {permission["Contractors User"].edit && (
            <Button
              type="text"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14px"
                  height="14px"
                >
                  <path d="M 18 2 L 15.585938 4.4140625 L 19.585938 8.4140625 L 22 6 L 18 2 z M 14.076172 5.9238281 L 3 17 L 3 21 L 7 21 L 18.076172 9.9238281 L 14.076172 5.9238281 z" />
                </svg>
              }
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record);
              }}
              className="text-blue-500 hover:text-blue-600"
              style={{
                padding: "2px",
                fontSize: "10px",
              }}
              shape="circle"
            />
          )}
          {permission["Contractors User"].delete && (
            <Button
              type="text"
              icon={
                <svg
                  fill="#FA5252"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14px"
                  height="14px"
                >
                  <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
                </svg>
              }
              // onClick={(e) => {
              //   e.stopPropagation();
              //   handleDelete(record.id);
              // }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedContractorId(record.id); // Store ID
                setDeleteConfirmVisible(true); // Show modal
              }}
              className="text-red-500 hover:text-red-600"
              style={{
                padding: "2px",
                fontSize: "10px",
              }}
              shape="circle"
            />
          )}
        </Space>
      ),
    });
  }

  return (
    <div className="contract-user-list">
      <div className="search-header" style={{
        //margin: "20px 0 0 10px" 
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 10px 0",
      }}
      >
        {/* <Space size="middle" className="flex-grow mb-4">
          <Select
            style={{ width: 150 }}
            value={searchField}
            onChange={(value) => handleSearch(value, "field")}
          >
            <Option value="All">All</Option>
            <Option value="fullName">Name</Option>
            <Option value="businessName">Business Name</Option>
            <Option value="mobileNumber">Mobile Number</Option>
            <Option value="roleName">Role Name</Option>
          </Select>
          <Input
            placeholder="Search contract users..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value, "term")}
            style={{ width: 200 }}
          />
        </Space> */}
        {/* <div className="searchwraper" style={{width:"375px"}}>
         <Select
                  defaultValue={"all"}
                  size="large"
                  className="searchSelect"
                  listHeight={200}
                  value={searchField}
                  dropdownClassName="searchOption"
                  dropdownMatchSelectWidth={false}
                  defaultActiveFirstOption={true}
                  onChange={(value) => setSearchField(value)}
                  allowClear
                >
                 
                  
                 <Option value="All">All</Option>
            <Option value="fullName">Name</Option>
            <Option value="businessName">Business Name</Option>
            <Option value="mobileNumber">Mobile Number</Option>
            <Option value="roleName">Role Name</Option>
                </Select>
        <Search
              placeholder="Search"
              size="large"
              allowClear
             // value={filterText.value}
              // id="searchTextInput"
              style={{ marginRight: "20px", width: "15vw" }}
              // className="searchBox"
              onChange={debounce((e) => setSearchTerm(e.target.value), 300)}
              //onSearch={(value) => handleSearchValueUpdate(value)}
              enterButton
            />
            </div> */}

        {/* {permission["Contractors User"].write && (
          <Button
            style={{ float: "right", right: "0px" }}
            type="primary"
            onClick={handleCreate}
            className="mb-4"
          >
            New Contract User
          </Button>
        )} */}
      </div>
      {
        initialLoading ? (
          // Show a loading spinner while data is being fetched
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) :
          contractUsers.length > 0 ? (
            <Table
              rowClassName="table-row-light"
              columns={columns}
              dataSource={contractUsers}
              rowKey="id"
              loading={loading}
              pagination={false}
              scroll={{ y: "75vh" }}
              scrollToFirstRowOnChange={false}
            />
          ) : (
            // Show "No Data Found" only when loading is finished and no data exists
            <div className="noDataFound">
              <h3
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "80vh",
                  fontSize: "24px",
                  color: "#777",
                  alignItems: "center",
                }}
              >
                No Data Found
              </h3>
            </div>
          )
      }
      {/* <Table
        rowClassName="table-row-light"
        columns={columns}
        dataSource={contractUsers}
        rowKey="id"
        loading={loading}
        pagination={false}
        scroll={{y: "75vh" }} 
        scrollToFirstRowOnChange={false}
      /> */}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button onClick={handleLoadMore} loading={loading}>
            Load More
          </Button>
        </div>
      )}
      {!hasMore && contractUsers.length > 0 && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          {/* Showing all {totalItems} contract users */}
        </div>
      )}
      <ContractUserForm
        primaryCname={primaryCname}
        contractorUser={contractUsers}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setEditingUser(null);
          setUserData((prev) => ({
        ...prev,
        isContractorUserModalVisible: false, // Example: Show a modal or perform an action
      }));
        }}
        onSubmit={handleFormSubmit}
        initialValues={editingUser}
        primaryContractorId={primaryContractorId}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteConfirmVisible}
        onOk={() => handleDelete(selectedContractorId)} // Call delete function
        onCancel={() => setDeleteConfirmVisible(false)} // Close modal
        width={500}
        bodyStyle={{
          textAlign: "center",
          padding: "16px",
          marginBottom: "-50px",
        }}
        style={{ top: 100 }}
      >
        <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
          Are you sure you want to delete this user?
        </p>
      </Modal>
    </div>
  );
};

export default ContractUserList;
