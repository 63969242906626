import { Timestamp } from "@firebase/firestore";
import isUrl from "is-url";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import cardImg from "../../../assets/imgs/card.png";
import { Input, DatePicker, Form } from "antd";
import { FaPencilAlt } from "react-icons/fa";

import { uploadImageAndUpdateFirestore } from "./imageUpload/uploadImage";

function Tab1({ ImgPopup, data, isEdit, setUserData }) {
  let [uploadImgFiledName, setUploadImgFiledName] = useState(null);
  const [dobPan, setPanDob] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    aadhaar: {
      name: "",
      aadhaar_number: "",
      address_line_2: "",
      address_line_1: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      country: "",
    },
    pan: {
      pan_number: "",
      name: "",
    },
    bank: {
      account_name: "",
      account_number: "",
      bank_name: "",
      ifsc_code: "",
    },
  });
  const { id } = useLocation().state;
  const check = data;
  const convert = check?.bank?.account_number;
  const str = convert || "";

  const r1 = str?.replace(/.(?=.{4,}$)/g, "X");

  const initialDOB = data?.identity?.aadhaar?.dob;

  const initialPanDOB = data?.identity?.pan;

  const [dob2, setDob2] = useState(null);
  const [panDob, setpanDob] = useState(null);

  useEffect(() => {
    if (data?.identity?.aadhaar?.dob) {
      setDob2(data.identity.aadhaar.dob);
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (data?.identity?.pan?.dob) {
      setpanDob(data?.identity?.pan?.dob);
    }
  }, [data]);

  const triggerFileSelect = (filed) => {
    setUploadImgFiledName(filed);
    document.getElementById("fileInput").click();
  };

  const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname"); // Access extra field
    console.log("data", data);
    if (fieldName.startsWith("Pan")) {
      setUserData((prev) => ({
        ...prev,
        identity: {
          ...prev.identity,
          pan: {
            ...prev.identity.pan,
            [name]: value,
          },
        },
      }));

      // PAN card validation
      if (!value) {
        setErrorMessage((prev) => ({
          ...prev,
          pan: {
            ...prev.pan,
            [name]: `Please Enter ${fieldName}`,
          },
        }));
      } else if (!panCardRegex.test(value) && name !== "name") {
        setErrorMessage((prev) => ({
          ...prev,
          pan: {
            ...prev.pan,
            [name]: "Invalid PAN card number format",
          },
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          pan: {
            ...prev.pan,
            [name]: "",
          },
        }));
      }
    } else if (fieldName.startsWith("Bank")) {
      setUserData((prev) => ({
        ...prev,
        bank: {
          ...prev.bank,
          [name]: value,
        },
      }));
      setErrorMessage((prev) => ({
        ...prev,
        bank: {
          ...prev.bank,
          [name]: value ? "" : `Please Enter ${fieldName}`,
        },
      }));
    } else if (fieldName.startsWith("Aadhaar")) {
      // Aadhaar validation logic
      if (!value) {
        setErrorMessage((prev) => ({
          ...prev,
          aadhaar: {
            ...prev.aadhaar,
            [name]: `Please Enter ${fieldName}`,
          },
        }));
      } else if (!/^\d{12}$/.test(value)) {
        setErrorMessage((prev) => ({
          ...prev,
          aadhaar: {
            ...prev.aadhaar,
            [name]: "Aadhaar number must be 12 digits",
          },
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          aadhaar: {
            ...prev.aadhaar,
            [name]: "",
          },
        }));
      }
      setUserData((prev) => ({
        ...prev,
        identity: {
          ...prev.identity,
          aadhaar: {
            ...prev.identity.aadhaar,
            [name]: value,
          },
        },
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        identity: {
          ...prev.identity,
          aadhaar: {
            ...prev.identity.aadhaar,
            [name]: value,
          },
        },
      }));
      setErrorMessage((prev) => ({
        ...prev,
        aadhaar: {
          ...prev.aadhaar,
          [name]: value ? "" : `Please Enter ${fieldName}`,
        },
      }));
    }
  };

  useEffect(() => {
    if (data?.identity?.aadhaar?.url) {
    }
  }, [data?.identity?.aadhaar?.url]);
  const [imageSrc, setImageSrc] = useState(cardImg);

  const [panImgSrc, setPanImgSrc] = useState(cardImg);

  useEffect(() => {
    if (data.identity?.aadhaar?.url) {
      setImageSrc(data?.identity?.aadhaar?.url);
    }
  }, [data?.identity?.aadhaar?.url, uploadImgFiledName === "aadhaar"]);

  useEffect(() => {
    if (data?.identity?.pan?.url) {
      setPanImgSrc(data?.identity?.pan?.url);
    }
  }, [data?.identity?.pan?.url, uploadImgFiledName === "pan"]);

  const handleImageEdit = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log("data", data);
        if (uploadImgFiledName === "aadhaar") {
          setImageSrc(event.target.result);
        } else {
          setPanImgSrc(event.target.result);
        }
        uploadImageAndUpdateFirestore(
          event.target.result,
          id,
          setUserData,
          uploadImgFiledName,
          "0"
        );
        e.target.value = "";
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDobChange = (date) => {
    const formattedDate = date
      ? moment(date.toDate()).format("YYYY-MM-DD")
      : null;
    const formattedDate1 = date ? date.format("DD/MM/YYYY") : null;
    setDob2(date);
    setUserData((prev) => ({
      ...prev,
      identity: {
        ...prev.identity,
        aadhaar: {
          ...prev.identity.aadhaar,
          dob: formattedDate,
        },
      },
    }));
  };

  const handlePanDobChange = (date) => {
    const formattedDate = date
      ? moment(date.toDate()).format("YYYY-MM-DD")
      : null;
    setpanDob(date);
    setUserData((prev) => ({
      ...prev,
      identity: {
        ...prev.identity,
        pan: {
          ...prev.identity.pan,
          dob: formattedDate,
        },
      },
    }));
  };

  console.log("aaaaaaaaaaaaaaaaaaaa", data?.identity?.aadhaar);

  return (
    <>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Aadhaar Card</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Card Image</div>
            <div>
              <div
                className="fieldDetail imgPopup"
                onClick={(e) => ImgPopup(imageSrc || cardImg)}
              >
                <img
                  width="102px"
                  height="60px"
                  className=""
                  src={imageSrc || cardImg}
                  alt="card 1img"
                />
                <div
                  className="parent-container"
                  style={{ position: "relative" }}
                >
                  <div
                    className="edit-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      triggerFileSelect("aadhaar");
                    }}
                    style={{
                      position: "absolute",
                      // right: "5px",
                      right: "55%",
                      // top: "13%",
                      bottom: "20%",
                      cursor: "pointer",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                  >
                    {isEdit && <FaPencilAlt size={14} color="#000" />}
                  </div>
                </div>
              </div>

              <input
                id="fileInput"
                type="file"
                name="aadhar"
                onChange={handleImageEdit}
                accept="image/*"
                style={{ display: "none" }} // Hide the file input
              />
            </div>
          </div>
          <div className="fieldData">
            <div className="labelField">Name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.identity?.aadhaar?.name
                  ? data?.identity?.aadhaar?.name
                  : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter mobile number"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="name"
                  data-fieldname="name"
                  value={data.identity?.aadhaar?.name}
                />
                {errorMessage?.aadhaar?.name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.aadhaar?.name}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div
              className="labelField"
              style={{
                color: !data?.identity?.aadhaar?.aadhaar_number
                  ? "orange"
                  : "inherit",
              }}
            >
              Card Number
            </div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.identity?.aadhaar?.aadhaar_number
                  ? data?.identity?.aadhaar?.aadhaar_number
                  : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter mobile number"
                  className="phoneNumberInput"
                  value={data.identity?.aadhaar?.aadhaar_number || ""}
                  onChange={handleChange}
                  name="aadhaar_number"
                  data-fieldname="Aadhaar"
                />
                {errorMessage?.aadhaar?.aadhaar_number && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.aadhaar?.aadhaar_number}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Birth</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {dob2 ? moment(dob2).format("YYYY-MM-DD") : null}
              </div>
            ) : (
              <Form
                initialValues={{
                  dateOfBirth: dob2 ?? initialDOB, // Set initial value for DatePicker here
                }}
              >
                <Form.Item
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: "Please select your date of birth!",
                    },
                    {
                      validator: (_, value) =>
                        value && value > moment().endOf("day")
                          ? Promise.reject(
                              new Error("Future dates are not allowed!")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  {/* <DatePicker
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    value={dob2} // Make DatePicker a controlled component
                    onChange={handleDobChange}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    } // Disable future dates
                  /> */}
                  <DatePicker
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    value={moment(data?.identity?.aadhaar?.dob) || null}
                    onChange={handleDobChange}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                  />
                  ;
                </Form.Item>
              </Form>
            )}
          </div>
          {!isEdit ? (
            <div className="fieldData">
              <div className="labelField">Address</div>
              {/* <div className="fieldDetail">
                {(data?.identity?.aadhaar?.address_line_1
                  ? data?.identity?.aadhaar?.address_line_1
                  : null) ||
                (data?.identity?.aadhaar?.address_line_2
                  ? data?.identity?.aadhaar?.address_line_2
                  : null ||
                data?.identity?.aadhaar?.city ||
                data?.identity?.aadhaar?.state ||
                data?.identity?.aadhaar?.pincode
                  ? `${
                      data?.identity?.aadhaar?.address_line_1 &&
                      `${data?.identity?.aadhaar?.address_line_1},`
                    } ${
                      data?.identity?.aadhaar?.address_line_2 &&
                      `${data?.identity?.aadhaar?.address_line_2},`
                    }
                ${
                  data?.identity?.aadhaar?.city &&
                  `${data?.identity?.aadhaar?.city},`
                } ${
                      data?.identity?.aadhaar?.pincode &&
                      `${data?.identity?.aadhaar?.pincode},`
                    } ${
                      data?.identity?.aadhaar?.state &&
                      `${data?.identity?.aadhaar?.state},`
                    } ${
                      data?.identity?.aadhaar?.country &&
                      `${data?.identity?.aadhaar?.country}`
                    }`
                  : "N/A"}
              </div> */}
              <div className="fieldDetail">
                {data?.identity?.aadhaar
                  ? [
                      data.identity.aadhaar.address_line_1,
                      data.identity.aadhaar.address_line_2,
                      data.identity.aadhaar.city,
                      data.identity.aadhaar.pincode,
                      data.identity.aadhaar.state,
                      data.identity.aadhaar.country,
                    ]
                      .filter(Boolean)
                      .join(", ")
                  : "N/A"}
              </div>
            </div>
          ) : (
            <div className="firstDetailContainner">
              <div className="firstDetail">
                <div className="fieldData">
                  <div className="labelField">Address Line1</div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {data?.identity?.aadhaar?.aadhaar_number
                        ? data?.identity?.aadhaar?.aadhaar_number
                        : "N/A"}
                    </div>
                  ) : (
                    <div>
                      <Input
                        style={{ width: "90%" }}
                        placeholder="Enter Address Line 1"
                        className="phoneNumberInput"
                        value={data?.identity?.aadhaar?.address_line_1}
                        onChange={handleChange}
                        name="address_line_1"
                        data-fieldname="Address Line 1"
                      />
                      {errorMessage?.aadhaar?.address_line_1 && (
                        <div style={{ color: "red", marginTop: "8px" }}>
                          {errorMessage.aadhaar?.address_line_1}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isEdit && (
            <>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Address Line2</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Address Line2"
                          className="phoneNumberInput"
                          value={data.identity?.aadhaar?.address_line_2}
                          onChange={handleChange}
                          name="address_line_2"
                          data-fieldname="Address Line 2"
                        />
                        {errorMessage?.aadhaar?.address_line_2 && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.aadhaar?.address_line_2}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">City</div>
                    {isEdit && (
                      <div>
                        {" "}
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter City"
                          className="phoneNumberInput"
                          value={data.identity?.aadhaar?.city}
                          onChange={handleChange}
                          name="city"
                          data-fieldname="city"
                        />
                        {errorMessage?.aadhaar?.city && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.aadhaar?.city}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">State</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter State"
                          className="phoneNumberInput"
                          value={data.identity?.aadhaar?.state}
                          onChange={handleChange}
                          name="state"
                          data-fieldname="state"
                        />
                        {errorMessage?.aadhaar?.state && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.aadhaar?.state}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Pincode</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Pincode"
                          className="phoneNumberInput"
                          value={data.identity?.aadhaar?.pincode}
                          onChange={handleChange}
                          name="pincode"
                          data-fieldname="pincode"
                        />
                        {errorMessage?.aadhaar?.pincode && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.aadhaar?.pincode}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Country</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Country"
                          className="phoneNumberInput"
                          value={data?.identity?.aadhaar?.country}
                          onChange={handleChange}
                          name="country"
                          data-fieldname="country"
                        />
                        {errorMessage.aadhaar?.country && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.aadhaar?.country}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="detailsContainer">
        <h4 className="cardDetailsHead">PAN</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Card image</div>
            <div
              className="fieldDetail imgPopup"
              onClick={(e) => ImgPopup(panImgSrc)}
            >
              <embed
                width="102px"
                height="60px"
                className=""
                src={panImgSrc}
                alt="card 1img"
              />
              <div
                className="parent-container" // Ensure this is the parent container
                style={{ position: "relative" }} // Add relative position to the parent
              >
                <div
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerFileSelect("pan");
                  }}
                  style={{
                    position: "absolute",
                    // right: "5px",
                    right: "55%",
                    // top: "13%",
                    bottom: "20%",
                    cursor: "pointer",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  {isEdit && <FaPencilAlt size={14} color="#000" />}
                </div>
              </div>
            </div>
            <input
              id="fileInput"
              type="file"
              name="pan"
              onChange={handleImageEdit}
              accept="image/*"
              style={{ display: "none" }} // Hide the file input
            />
          </div>
          <div className="fieldData">
            <div className="labelField">Card Number</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.identity?.pan?.pan_number
                  ? data.identity?.pan?.pan_number
                  : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Card Number"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="pan_number"
                  data-fieldname="Pan Number"
                  value={(data?.identity?.pan?.pan_number || "").toUpperCase()}
                />
                {errorMessage.pan?.pan_number && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.pan?.pan_number}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.identity?.pan?.name ? data?.identity?.pan?.name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Name"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="name"
                  data-fieldname="Pan Name"
                  value={data?.identity?.pan?.name || ""}
                />
                {errorMessage.pan?.name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.pan?.name}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Birth</div>
            {!isEdit ? (
              <div className="fieldDetail">{panDob || "N/A"}</div>
            ) : (
              <Form
                initialValues={{
                  dateOfBirth: panDob || initialPanDOB, // Set initial value for DatePicker here
                }}
              >
                <Form.Item
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: "Please select your date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    value={
                      data?.identity?.pan?.dob
                        ? moment(data?.identity.pan.dob, "YYYY-MM-DD")
                        : null
                    }
                    onChange={handlePanDobChange}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                  />
                  ;
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Bank Account</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Holder name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.bank?.account_name ? data?.bank?.account_name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Holder name"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="account_name"
                  data-fieldname="Bank Number"
                  value={data?.bank?.account_name}
                />
                {errorMessage.bank?.account_name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.bank?.account_name}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">A/C number</div>
            {!isEdit ? (
              <div className="fieldDetail">{r1 ? r1 : "N/A"}</div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Holder name"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="account_number"
                  data-fieldname="Bank Holder name"
                  value={r1}
                />
                {errorMessage?.bank?.account_number && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.bank?.account_number}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Bank name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.bank?.bank_name ? data?.bank?.bank_name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Bank name"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="bank_name"
                  data-fieldname="Bank Name"
                  value={data?.bank?.bank_name}
                />
                {errorMessage.bank?.bank_name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.bank?.bank_name}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="fieldData">
            <div className="labelField">Branch name</div>
            <div className="fieldDetail">
              {data.bank?.branch_name ? data.bank?.branch_name : "N/A"}
            </div>
          </div> */}
          <div className="fieldData">
            <div className="labelField">IFS Code</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data?.bank?.ifsc_code ? data?.bank?.ifsc_code : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="IFS Code"
                  className="phoneNumberInput"
                  onChange={handleChange}
                  name="ifsc_code"
                  data-fieldname="Bank ifsc code"
                  value={data?.identity?.bank?.ifsc_code}
                />
                {errorMessage.bank?.ifsc_code && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.bank?.ifsc_code}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tab1;
