import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
  permission: {},
  selectedTalent: {
    talent: [],
    isTalent: false,
    isSubmited:false
  },

};

export const loginSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    fetchUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    fetchPermissions: (state, action) => {
      console.log(JSON.parse(JSON.stringify(state.permission)));
      state.permission = action.payload;
    },
    updatePermissions: (state, action) => {
      if (state?.permission.getUserRole.roleId === action.payload.roleId) {
        const updatedPermissions = JSON.parse(action.payload.permission);
        state.permission.getUserRole = {
          ...state.permission.getUserRole,
          permission: updatedPermissions,
        };
      }
    },
    updateSelectedTalent: (state, action) => {
      state.selectedTalent.isTalent = action.payload.findIsChecked;
      state.selectedTalent.talent = action.payload.findSelectedTalents;
      state.selectedTalent.isSubmited = action.payload.isSubmited;
    },
  },
});

export const {
  fetchUserInfo,
  fetchPermissions,
  updatePermissions,
  updateSelectedTalent,
} = loginSlice.actions;

export default loginSlice.reducer;
