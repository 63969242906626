import {
  createPost,
  deletePost,
  fetchData,
  fetchDataEachRow,
  updatePost,
} from "../backendApi/index.js";

export const fetchContractUsersList = async (
  page,
  pageSize,
  primaryContractorId,
  searchParams
) => {
  try {
    const response = await fetchData(
      `/contractUser?page=${page}&pageSize=${pageSize}&primaryContractorId=${primaryContractorId}&search=${searchParams.searchTerm}&column=${searchParams.searchField}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching contract users:", error);
    throw error;
  }
};

export const deleteContractUser = async (id) => {
  try {
    const response = await deletePost(`/contractUser/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting contract user:", error);
    throw error;
  }
};

export const createContractUser = async (data) => {
  try {
    const response = await createPost(`/contractUser`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating contract user:", error);
    throw error;
  }
};

export const updateContractUser = async (id, data) => {
  try {
    const response = await updatePost(`/contractUser/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating contract user:", error);
    throw error;
  }
};

export const getConUserByID = async (id) => {
  try {
    const response = await fetchDataEachRow(`/contractUser/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error updating contract user:", error);
    throw error;
  }
};

export const fetchPrimaryEmployeersList = async () => {
  try {
    const response = await fetchData(`/primaryEmployeers`)
    return response.data;
  } catch (error) {
    console.error("Error deleting contractor:", error);
    throw error;
  }
};
