import { Modal, Form, Checkbox, message } from "antd";
import React, { useState, useEffect } from "react";
import { fetchOperations } from "./api";
import { CaretDownOutlined } from "@ant-design/icons";
import Item from "antd/es/list/Item";

const PermissionsPopup = ({
  isVisible,
  roleId,
  onSave,
  onCancel,
  closePopup,
  form,
}) => {
  const permissions = ["All", "read", "write", "edit", "delete"];

  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState({});
  const [rolePermission, setRolePermission] = useState([]);
  const [expandedRoles, setExpandedRoles] = useState(new Set());

  useEffect(() => {
    if (rolePermission?.length > 0) {
      if (!roleId) {
        setRoles(Object.keys(rolePermission[0]));
        let menuPermission = {};
        Object.keys(rolePermission[0]).forEach((ele) => {
          menuPermission[ele] = {
            All: false,
            read: false,
            write: false,
            edit: false,
            delete: false,
          };
        });
        setUserRole(menuPermission);
      } else {
        setRoles(Object.keys(rolePermission[0]));
      }
    }
  }, [rolePermission?.length]);

  async function fetchAllOperations() {
    if (roleId) {
      const res = await fetchOperations(`/role/rolePermision/${roleId}`);
      if (res.data.data) {
        let f = [JSON.parse(res.data.data.fetchRole.permission)];
        setRolePermission([res.data.data.permission]);
        setUserRole(f[0]);
      }
    } else {
      const res = await fetchOperations("/permission");
      if (res.data.data.permission) {
        setRolePermission([res.data.data.permission]);
      }
    }
  }

  useEffect(() => {
    fetchAllOperations();
  }, []);

  const toggleExpandRole = (role) => {
    setExpandedRoles((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(role)) {
        newExpanded.delete(role);
      } else {
        newExpanded.add(role);
      }
      return newExpanded;
    });
  };

  const handlePermissionChange = (selectRole, permission, isChecked, role) => {
    setUserRole((prev) => {
      let updatedRole = {
        ...prev,
        [selectRole]: {
          ...prev[selectRole],
          [permission]: isChecked,
        },
      };

      if (permission === "All") {
        updatedRole[selectRole] = {
          All: isChecked,
          read: isChecked,
          write: isChecked,
          edit: isChecked,
          delete: isChecked,
        };

        if (isChecked) {
          setExpandedRoles((prev) => new Set([...prev, selectRole]));
        }
      }
      // console.log(selectRole, permission, isChecked, role);
      // if (
      //   ["read", "write", "edit", "delete"].includes(permission) &&
      //   rolePermission[0][role].includes(selectRole)
      // ) {
      //   let d = (rolePermission[0][role] = rolePermission[0][role].filter(
      //     (item) => item !== selectRole
      //   ));
      //   d.forEach((subRole) => {
      //     if (!prev[subRole]) return;

      //     if (isChecked && selectRole === subRole) {
      //       updatedRole[subRole] = {
      //         ...prev[subRole],
      //         [permission]: isChecked,
      //       };
      //     } else if (!isChecked) {
      //       updatedRole[subRole] = {
      //         ...prev[subRole],
      //         [permission]: isChecked,
      //       };
      //     }

      //     // if (!isChecked) {
      //     //   updatedRole[subRole] = {
      //     //     All: false,
      //     //     read: false,
      //     //     write: false,
      //     //     edit: false,
      //     //     delete: false,
      //     //   };
      //     // }
      //   });
      // }

      console.log(selectRole, permission, isChecked, role);
      // If a subrole's permission is being unchecked, remove it from the userRole state
      // Remove only extra roles (roles NOT in predefined list)
      if (["All"].includes(permission) && !isChecked) {
        Object.keys(updatedRole).forEach((role) => {
          if (
            !roles.includes(role) &&
            Object.values(updatedRole[role]).every((val) => val === false)
          ) {
            delete updatedRole[role];
          }
        });
      } else if (
        ["read", "write", "edit", "delete"].includes(permission) &&
        !isChecked &&
        rolePermission[0]?.[selectRole] === undefined
      ) {
        updatedRole[selectRole]["All"] = false;
        updatedRole[selectRole][permission] = false;

        if (
          Object.values(updatedRole[selectRole]).every((val) => val === false)
        ) {
          delete updatedRole[selectRole];
        }
      }

      return updatedRole;
    });

    // childen or sub role

    console.log(selectRole, permission, isChecked, role);

    if (Array.isArray(rolePermission?.[0]?.[selectRole])) {
      if (rolePermission?.[0]?.[selectRole]?.length > 1) {
        let arr = rolePermission?.[0]?.[selectRole];
        let updateUserRole = { ...userRole };
        if (permission === "read" && !isChecked) {
          if (arr && !isChecked) {
            arr.forEach((ele) => {
              if (!updateUserRole[ele]) {
                updateUserRole[ele] = {};
              }
              updateUserRole[ele]["All"] = false;
              updateUserRole[ele]["read"] = false;
              updateUserRole[ele]["write"] = false;
              updateUserRole[ele]["edit"] = false;
              updateUserRole[ele]["delete"] = false;
            });

            setUserRole(updateUserRole);
          }
        } else {
          arr.forEach((ele) => {
            if (!updateUserRole[ele]) {
              updateUserRole[ele] = {};
            }
            if (permission === "All" && !isChecked) {
              if (ele === selectRole) {
                updateUserRole[ele][permission] = false;
                updateUserRole[ele]["All"] = false;
              } else {
                updateUserRole[ele]["All"] = false;
                updateUserRole[ele]["read"] = false;
                updateUserRole[ele]["write"] = false;
                updateUserRole[ele]["edit"] = false;
                updateUserRole[ele]["delete"] = false;
              }
            }
          });
        }
      }

      if (rolePermission?.[0]?.[selectRole]) {
        delete userRole[selectRole]?.["All"];
        let filtered = Object.values(userRole?.[selectRole]).filter(Boolean);
        if (filtered.length === 3 && isChecked) {
          setUserRole((prev) => ({
            ...prev,
            [selectRole]: {
              ...prev[selectRole],
              ["All"]: isChecked,
            },
          }));
        }
      }
    } else {
      if (userRole[selectRole]) {
        if (userRole[selectRole] && permission === "read" && !isChecked) {
          delete userRole[selectRole]?.["All"];

          setUserRole((prev) => ({
            ...prev,
            [selectRole]: {
              ...prev[selectRole],
              ["All"]: false,
              read: false,
              write: false,
              edit: false,
              delete: false,
            },
          }));
        } else {
          delete userRole[selectRole]?.["All"];
          let filtered = Object.values(userRole[selectRole]).filter(Boolean);
          if (filtered.length === 3 && isChecked) {
            setUserRole((prev) => ({
              ...prev,
              [selectRole]: {
                ...prev[selectRole],
                ["All"]: isChecked,
              },
            }));
          }
        }
      }
    }
  };

  const isAnyPermissionSelected = (roles) => {
    return Object.values(roles).some((role) =>
      Object.values(role).includes(true)
    );
  };

  const availableRoles = roles.filter((role) => userRole[role]);

  return (
    <Modal
      visible={isVisible}
      title={roleId ? "Update Permissions" : "Create Permissions"}
      okText={roleId ? "Update" : "Create"}
      cancelText="Cancel"
      width={800}
      onCancel={() => {
        form.resetFields();
        closePopup();
        if (onCancel) onCancel();
      }}
      onOk={() => {
        form.validateFields().then(() => {
          form.resetFields();
          if (isAnyPermissionSelected(userRole)) {
            if (onSave) onSave(userRole);
            closePopup();
          } else {
            message.error("Please select at least one permission.");
          }
        });
      }}
    >
      <Form form={form} layout="vertical" name="permissions_form">
        <div
          className="contractor-list-container flex flex-col overflow-auto"
          style={{ height: "40%" }}
        >
          <div
            className="search-header"
            style={{ margin: "20px 28px 0px 10px" }}
          >
            <div style={{ marginTop: "3%", marginLeft: "2%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7%",
                  paddingLeft: "202px",
                }}
              >
                {permissions.map((perm) => (
                  <h4 key={perm} style={{ margin: "0 22px" }}>
                    {perm.charAt(0).toUpperCase() + perm.slice(1)}
                  </h4>
                ))}
              </div>

              {availableRoles.length > 0 &&
                availableRoles.map((role) => (
                  <React.Fragment key={role}>
                    <div style={{ marginTop: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12%",
                        }}
                      >
                        <h4
                          style={{
                            margin: 0,
                            flexBasis: "20%",
                            display: "flex",
                            gap: "5%",
                            alignItems: "center",
                          }}
                        >
                          {role}
                          {rolePermission[0]?.[role]?.length > 1 && (
                            <CaretDownOutlined
                              onClick={() => toggleExpandRole(role)}
                            />
                          )}
                        </h4>

                        {permissions.map((perm) => {
                          return (
                            <Checkbox
                              key={`${role}-${perm}`}
                              checked={
                                perm === "All"
                                  ? Object.values(userRole[role] || {}).every(
                                      Boolean
                                    ) // If all are true, check "All"
                                  : userRole[role]?.[perm] || false
                              }
                              disabled={
                                perm === "read" || perm === "All"
                                  ? false
                                  : !userRole[role]["read"]
                              }
                              onChange={(event) =>
                                handlePermissionChange(
                                  role,
                                  perm,
                                  event.target.checked
                                )
                              }
                            />
                          );
                        })}
                      </div>
                    </div>

                    {expandedRoles.has(role) &&
                      rolePermission[0]?.[role]?.map((subRole) => {
                        if (role !== subRole) {
                          return (
                            <div
                              key={subRole}
                              style={{ marginLeft: "30px", marginTop: "10px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12%",
                                }}
                              >
                                <h4 style={{ margin: 0, flexBasis: "20%" }}>
                                  {subRole}
                                </h4>

                                {permissions.map((perm) => {
                                  console.log(userRole?.[subRole]?.read);
                                  let isDisabled = !!userRole?.[subRole]?.read; 
                                  return (
                                    <Checkbox
                                      key={`${subRole}-${perm}`}
                                      checked={
                                        userRole[subRole]?.[perm]  || false
                                      }
                                      disabled={
                                        perm === "read" || perm === "All"
                                          ? !userRole[role]?.['read']
                                          : isDisabled
                                          ? false
                                          : true
                                      }
                                      onChange={(event) =>
                                        handlePermissionChange(
                                          subRole,
                                          perm,
                                          event.target.checked,
                                          (role = "")
                                        )
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PermissionsPopup;
