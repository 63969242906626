import React from "react";
import { Modal, Row, Col, Descriptions } from "antd";

const JobRoleViewDetails = ({ visible, onClose, data }) => {
  console.log(data, "data")
  return (
    <Modal
      title="View Job Role"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      style={{textAlign:'center'}}
    >
      <Descriptions bordered column={1} size="middle">
        <Descriptions.Item label="Designation">{data?.designation}</Descriptions.Item>
        <Descriptions.Item label="Department">{data?.department}</Descriptions.Item>
        <Descriptions.Item label="Description">{data?.description}</Descriptions.Item>
        <Descriptions.Item label="Shift">{data?.shift}</Descriptions.Item>
        <Descriptions.Item label="Date of Joining">{data?.dateOfJoining}</Descriptions.Item>
        <Descriptions.Item label="Gross Salary">₹{data?.grossSalary}</Descriptions.Item>
        <Descriptions.Item label="Salary Period">{data?.salaryPerPeriod}</Descriptions.Item>
        <Descriptions.Item label="Personal Tax Slab">{data?.taxSlab}</Descriptions.Item>
      </Descriptions>

      <h3 style={{ marginTop: "20px" }}>Earnings</h3>
      <Row gutter={24}>
        {data?.earnings?.map((earning, index) => (
          <Col span={12} key={index}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={earning.type}>₹{earning.amount}</Descriptions.Item>
            </Descriptions>
          </Col>
        ))}
      </Row>

      <h3 style={{ marginTop: "20px" }}>Deductions</h3>
      <Row gutter={24}>
        {data?.deductions?.map((deduction, index) => (
          <Col span={12} key={index}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={deduction.type}>₹{deduction.amount}</Descriptions.Item>
            </Descriptions>
          </Col>
        ))}
      </Row>

      <h3 style={{ marginTop: "20px" }}>Operational Deductions</h3>
      <Row gutter={24}>
        {data?.operationalDeduction?.map((deduction, index) => (
          <Col span={12} key={index}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={deduction.type}>₹{deduction.amount}</Descriptions.Item>
            </Descriptions>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default JobRoleViewDetails;
