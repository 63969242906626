import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

let baseURL = "https://urchin-app-fi4og.ondigitalocean.app/api/web";

// let baseURL = "http://localhost:8000/api/web";

// export let baseURL = "http://localhost:7000/api/web";

// https://urchin-app-fi4og.ondigitalocean.app/api/web

// https://monkfish-app-iacmv.ondigitalocean.app/api/web

const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

function someFunctionToCalculateSing(data) {
  return data?.checkHeader || null;
}

const auth = getAuth();

// Request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("OTP_Token_v2");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }

    const checkHeader = someFunctionToCalculateSing(config.data);
    if (checkHeader) {
      config.headers["Sign"] = checkHeader;
      delete config.data.checkHeader;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor

axiosClient.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const newToken = await user.getIdToken(true);
          localStorage.setItem("OTP_Token_v2", newToken);

          error.config.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosClient.request(error.config);
        } catch (tokenError) {
          console.error("Failed to refresh token:", tokenError);
        }
      } else {
        console.log("User is signed out.");
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
