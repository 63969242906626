import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Divider, message } from "antd";
import "./Whatsapp.css";
import { fetchData } from "../backendApi";
import { useContext } from "react";
import { dataContext } from "../context/data";
import {
  MessageHistoryTable,
  getValuesForParams,
} from "./WhatsappCommonFunctions";
import {
  whatsAppMessageSender,
  getWhatsUpData,
  getRecipientList,
} from "./rolemanagement/api";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const Whatsapp = ({ userType, userPath }) => {
  const [messageNew, setMessageNew] = useState("");
  const [form] = Form.useForm();
  const { setNavList } = useContext(dataContext);
  const [whatsUpStoredData, setWhatsUpStoredData] = useState([]);

  // Independent state for each filter
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [primaryEmployerList, setPrimaryEmployerList] = useState([]);
  const [messageTrigger, setMessageTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  let permission = useSelector((state) => {
    return state.user.permission.getUserRole;
  });

  const employedOption = { label: "Employed", value: "employed" };
  const unemployedOptions = [
    { label: "Incomplete Profile", value: "incomplete_profile" },
    { label: "Completed Profile", value: "completed_profile" },
    { label: "Yet To Be Verified", value: "yet_to_be_verified" },
    { label: "Available", value: "available" },
  ];

  let temp = [
    "incomplete_profile",
    "completed_profile",
    "yet_to_be_verified",
    "employed",
    "available",
  ];

  const unemployedValues = unemployedOptions.map((opt) => opt.value);
  const allRecipientValues = ["employed", ...unemployedValues];

  useEffect(() => {
    setNavList((pre) => {
      return {
        ...pre,
        dashBoard: false,
        userList: false,
        jobPost: false,
        requests: false,
        whatsapp: true,
      };
    });
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWhatsUpData();
      console.log(response?.data?.data, "TELLER");
      setWhatsUpStoredData(response?.data?.data);
      setMessageTrigger(false);
    })();
  }, [messageTrigger]);

  useEffect(() => {
    (async () => {
      //   message.loading({ content: "Loading", key: "Whatsapp", duration: 0 });
      const responseContractor = await fetchData(
        `/dashboard/primaryContractor`
      );
      if (responseContractor?.data?.data?.length > 0) {
        setContractorList(responseContractor?.data?.data);
      }
      const responsePE = await fetchData("/dashboard/primaryEmployeer");
      if (responsePE?.data?.data?.length > 0) {
        setPrimaryEmployerList(responsePE?.data?.data);
        console.log(responsePE?.data?.data, "123");
      }
    })();
  }, []);

  // Update form values when states change
  useEffect(() => {
    form.setFieldValue("recipients", selectedRecipients);
    form.setFieldValue("contractor", selectedContractors);
    form.setFieldValue("primaryEmployer", selectedEmployers);
  }, [selectedRecipients, selectedContractors, selectedEmployers, form]);

  // Recipients handlers
  const handleRecipientsChange = (values) => {
    setSelectedRecipients(values);
  };

  const handleSelectAllRecipients = () => {
    const allSelected = allRecipientValues.every((value) =>
      selectedRecipients.includes(value)
    );
    setSelectedRecipients(allSelected ? [] : allRecipientValues);
  };

  const handleSelectAllUnemployed = () => {
    const allUnemployedSelected = unemployedValues.every((value) =>
      selectedRecipients.includes(value)
    );

    let newSelection;
    if (allUnemployedSelected) {
      // Remove only unemployed values, keep other selections
      newSelection = selectedRecipients.filter(
        (value) => !unemployedValues.includes(value)
      );
    } else {
      // Add all unemployed values while preserving other selections
      newSelection = [...new Set([...selectedRecipients, ...unemployedValues])];
    }
    setSelectedRecipients(newSelection);
  };

  // Contractors handlers
  const handleContractorsChange = (values) => {
    setSelectedContractors(values);
  };

  const handleSelectAllContractors = () => {
    const allSelected = contractorList.length === selectedContractors.length;
    setSelectedContractors(
      allSelected ? [] : contractorList.map((item) => item.id)
    );
  };

  // Employers handlers
  const handleEmployersChange = (values) => {
    setSelectedEmployers(values);
  };

  const handleSelectAllEmployers = () => {
    const allSelected = primaryEmployerList.length === selectedEmployers.length;
    setSelectedEmployers(
      allSelected ? [] : primaryEmployerList.map((item) => item.id)
    );
  };

  const handleSendMessage = async () => {
    setLoading(true);
    const response = await getRecipientList(selectedRecipients);
    // console.log(response, "123");
    if (messageNew === "") {
      message.error("Please Fill Message");
      setLoading(false);
    } else {
      if (response.length > 0) {
        try {
          const values = await form.validateFields();
          console.log("Submitted values:", values);

          const paramsSendingResponse = getValuesForParams(selectedRecipients);

          const response = await whatsAppMessageSender(
            "spectraMassMessage",
            {
              title: "mass_message_whatsapp_spectra_user",
              type: "condition_talent",
              query: paramsSendingResponse,
              message: messageNew,
              primaryContractorId:
                selectedEmployers?.length !== 0 ? selectedContractors : [],
              primaryEmployerId:
                selectedContractors?.length !== 0 ? selectedEmployers : [],
              status: selectedRecipients,
            },
            permission.fullName,
            permission?.roleName
          );
          console.log("Dashboard data response:", response);
          if (response.status === 200) {
            setMessageTrigger(true);
            form.resetFields();
            setMessageNew("");
            setSelectedContractors([]);
            setSelectedEmployers([]);
            setSelectedRecipients([]);

            message.success({
              content: "Message sent Successfully",
              key: "massMessage",
            });
          } else {
            message.destroy({
              content: response.message,
              key: "massMessage",
            });
          }
        } catch (error) {
          console.log("Validation failed:", error);
        } finally {
          setLoading(false); // Stop loading after completion
        }
      } else {
        message.error("No Talents Present Under Selected Recipients Status");
        setLoading(false);
      }
    }
  };

  return (
    <div className="container">
      {/* Left Card - Message Form */}
      <div className="card message-card">
        <h1 className="heading">
          Mass Message Over <span className="highlight">WhatsApp</span>
        </h1>
        <Form layout="vertical" form={form} onFinish={handleSendMessage}>
          {/* Message Input */}
          <Form.Item
            name="message"
            label={<h4 className="subheading">Message</h4>}
            // rules={[{ required: true, message: "Enter your message" }]}
            style={{ marginBottom: "16px" }}
          >
            <TextArea
              value={message}
              placeholder="Enter your message"
              rows={5}
              style={{ fontSize: "16px" }}
              onChange={(e) => setMessageNew(e.target.value)}
            />
          </Form.Item>

          {/* Recipient Selection */}
          <Form.Item
            name="recipients"
            label={<h4 className="subheading">Send Recipients</h4>}
            style={{ marginBottom: "16px" }}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value && value.length > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Please select at least one recipient!")
                      ),
              },
            ]}
          >
            <Select
              placeholder="Select Recipients"
              mode="multiple"
              allowClear
              value={selectedRecipients}
              maxTagCount="responsive"
              onChange={handleRecipientsChange}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <div>
                  <Button
                    type="text"
                    onClick={handleSelectAllRecipients}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      padding: "8px 16px",
                      fontWeight: "bold",
                    }}
                  >
                    {allRecipientValues.every((value) =>
                      selectedRecipients.includes(value)
                    )
                      ? "Deselect All Recipients"
                      : "Select All Recipients"}
                  </Button>
                  <Divider style={{ margin: "4px 0" }} />
                  <Button
                    type="text"
                    onClick={handleSelectAllUnemployed}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      padding: "8px 16px",
                      fontWeight: "bold",
                    }}
                  >
                    {unemployedValues.every((value) =>
                      selectedRecipients.includes(value)
                    )
                      ? "Deselect All Unemployed"
                      : "Select All Unemployed"}
                  </Button>
                  <Divider style={{ margin: "4px 0" }} />
                  {menu}
                </div>
              )}
              style={{ width: "100%" }}
            >
              {/* Employed Category */}
              <Select.OptGroup label="Employed">
                <Select.Option
                  key={employedOption.value}
                  value={employedOption.value}
                >
                  {employedOption.label}
                </Select.Option>
              </Select.OptGroup>

              {/* Unemployed Category */}
              <Select.OptGroup label="Unemployed">
                {unemployedOptions.map((opt) => (
                  <Select.Option key={opt.value} value={opt.value}>
                    {opt.label}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>
          </Form.Item>

          {/* Filter Options */}
          {selectedRecipients?.length === 1 &&
            selectedRecipients[0] === "employed" && (
              <Form.Item
                name="contractor"
                label={<h4 className="subheading">Filter Contractor User</h4>}
              >
                <Select
                  placeholder="Select Contractor"
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  value={selectedContractors}
                  onChange={handleContractorsChange}
                  disabled={selectedEmployers?.length !== 0}
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <div>
                      <Button
                        type="text"
                        onClick={handleSelectAllContractors}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          padding: "8px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        {contractorList.length === selectedContractors.length
                          ? "Deselect All Contractors"
                          : "Select All Contractors"}
                      </Button>
                      <Divider style={{ margin: "4px 0" }} />
                      {menu}
                    </div>
                  )}
                >
                  {contractorList?.map((itm, i) => (
                    <Select.Option
                      key={itm.id}
                      value={itm.id}
                      label={`${itm.name} ${itm.CompanyType.companyType}`} // Important: Ensure label is defined here
                    >
                      {itm.name} {itm.CompanyType.companyType}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

          {selectedRecipients?.length === 1 &&
            selectedRecipients[0] === "employed" && (
              <Form.Item
                name="primaryEmployer"
                label={<h4 className="subheading">Filter Primary Employer</h4>}
              >
                <Select
                  placeholder="Select Employer"
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  value={selectedEmployers}
                  onChange={handleEmployersChange}
                  disabled={selectedContractors?.length !== 0}
                  filterOption={
                    (input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) // Updated to use option.label
                  }
                  dropdownRender={(menu) => (
                    <div>
                      <Button
                        type="text"
                        onClick={handleSelectAllEmployers}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          padding: "8px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        {primaryEmployerList.length === selectedEmployers.length
                          ? "Deselect All Employers"
                          : "Select All Employers"}
                      </Button>
                      <Divider style={{ margin: "4px 0" }} />
                      {menu}
                    </div>
                  )}
                >
                  {primaryEmployerList?.map((itm, i) => (
                    <Select.Option
                      key={itm.id}
                      value={itm.id}
                      label={`${itm.name} ${itm.CompanyType.companyType}`} // Important for filterOption
                    >
                      {itm.name} {itm.CompanyType.companyType}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

          {/* Footer Buttons */}
          <div className="button-container">
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Sending..." : "Send"}
            </Button>
          </div>
        </Form>
      </div>

      {/* Right Card - History */}
      <div className="card history-card">
        <h1 className="heading">
          Message <span className="highlight">History</span>
        </h1>
        <div className="history-content">
          <MessageHistoryTable whatsUpStoredData={whatsUpStoredData} />
        </div>
      </div>
    </div>
  );
};

export default Whatsapp;
