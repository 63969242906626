import React, { useContext } from "react";
import { Row, Col, Typography, Card, Tooltip } from "antd";
import { dataContext } from "../../context/data";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Stats = ({ data, user }) => {
  const history = useHistory();
  const { userData, setUserData } = useContext(dataContext);

  const handleManagePEUser = () => {
    history.push({
      pathname: "/Talntworx/userPanel/primaryTabs/user/",
      state: { id: user?.id }, // Passing ID using state
    });
    // setUserData("5");
    setUserData((prev) => ({
      ...prev,
      activeTabIndex: "5",
      isModalVisible: true
    }));
    setUserData((prev) => ({
      ...prev,
      editUser: null, // Example: Show a modal or perform an action
    }));
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      {/* Unverified Talent / No of Associates Assigned */}
      <Col xs={24} sm={12} md={8}>
        <Card
          style={{
            background: "linear-gradient(135deg, #1CB5E0 0%, #000046 100%)",
            color: "white",
            borderRadius: "16px",
          }}
        >
          <Text style={{ color: "white", opacity: 0.8 }}>
            {user === "Employeer"
              ? "No of Associates Assigned"
              : "Unverified Talent"}
          </Text>
          <Title level={2} style={{ color: "white", margin: "8px 0 0" }}>
            {(user === "Employeer"
              ? data?.data?.no_of_associates_assigned
              : data?.data?.unverifiedCount
            )
              ?.toString()
              .padStart(2, "0")}
          </Title>
        </Card>
      </Col>

      {/* Verified Talent */}
      <Col xs={24} sm={12} md={8}>
        <Card
          style={{
            background: "linear-gradient(135deg, #1890ff 0%, #722ed1 100%)",
            color: "white",
            borderRadius: "16px",
          }}
        >
          <Text style={{ color: "white", opacity: 0.8 }}>Verified Talent</Text>
          <Title level={2} style={{ color: "white", margin: "8px 0 0" }}>
            {data?.data?.verifiedCount?.toString().padStart(2, "0")}
          </Title>
        </Card>
      </Col>

      {/* Manage PE User / PE User Associations */}
      <Col xs={24} sm={12} md={8}>
        <Card
          style={{
            background: "linear-gradient(135deg, #13c2c2 0%, #52c41a 100%)",
            color: "white",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Text style={{ color: "white", opacity: 0.8 }}>
            {user === "Employeer" ? "Manage PE User" : "PE User Associations"}
          </Text>

          {/* Bottom Section */}
          <div
            style={{
              marginTop: "auto",
              display: "flex",
              justifyContent: user !== "Employeer" ? "flex-start" : "flex-end",
            }}
          >
            {user === "Employeer" ? (
              <Tooltip title="Edit Profile">
                <EditOutlined
                  style={{
                    fontSize: "24px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={handleManagePEUser}
                />
              </Tooltip>
            ) : (
              <Title level={2} style={{ color: "white", margin: "8px 0 0" }}>
                {data?.data?.employeeUserCount?.toString().padStart(2, "0")}
              </Title>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Stats;
