import React from "react";
import { Card, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const EscalationMessage = () => {
  return (
    <Card
      style={{
        textAlign: "center",
        borderRadius: "16px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
          Escalation Message History
        </Title>
      </div>
      <ClockCircleOutlined style={{ fontSize: "64px", color: "#1890ff" }} />
      <Title level={3} style={{ marginTop: "16px" }}>
        Coming Soon
      </Title>
      <Text type="secondary">We're working!</Text>
    </Card>
  );
};

export default EscalationMessage;
